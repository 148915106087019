import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function PaymentPlanIcon(props: any) {
    const color = props.htmlColor || '#3766D9';
    return (
        <SvgIcon {...props} fill="none" viewBox="0 0 24 16">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.5 15.5H7.5C6.67157 15.5 6 14.8284 6 14V2C6 1.17157 6.67157 0.5 7.5 0.5H16.5C17.3284 0.5 18 1.17157 18 2V14C18 14.8284 17.3284 15.5 16.5 15.5ZM7.5 2V14H16.5V2H7.5ZM3 14H0V12.5H3V3.5H0V2H3C3.82843 2 4.5 2.67157 4.5 3.5V12.5C4.5 13.3284 3.82843 14 3 14ZM24 14H21C20.1716 14 19.5 13.3284 19.5 12.5V3.5C19.5 2.67157 20.1716 2 21 2H24V3.5H21V12.5H24V14Z" fill={color} />
        </SvgIcon>
    );
}
