import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'flex-end',
        maxWidth: '400px',
        minHeight: '217px',
        borderRadius: '8px',
        backgroundColor: '#F5F7FD',
        padding: theme.spacing(4, 3),
        margin: theme.spacing(0, 'auto'),
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    title: {
        fontSize: 18,
        fontWeight: 400,
        lineHeight: '100%',
    },
    cardDecoration:{
        textDecoration: 'none',
    },
}));


export default function BgImageCard(props: any) {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Link to={props.link} className={classes.cardDecoration}>
            <Paper className={classes.root} elevation={0} style={{ backgroundImage: `url('${props.image}')` }}>
                <Typography className={classes.title} display="inline" color="textSecondary">{t(props.title)}</Typography>
            </Paper>
        </Link >
    );
}