import axios from 'axios';
import { getServerError } from './error-service';
import { SignUpForTestBody } from './order-service.types';

// Get a list of cities where there are test laboratories
export const getListCitiesToTakeTest = (passTypes: Array<number>, lang: string) =>
    axios.get(`/api/pass-test/city`, { params: { 'passTypes[]': passTypes, lang } })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

// Get a list of organizations in the selected city where you can take the test
export const getListOrganizationToTakeTest = (cityId: number, passTypes: Array<number>, organizationId?: number, testTypeId?: number, optionId?: number) => {
    return axios.get(`/api/pass-test/city/${cityId}/organization`, {
        params: {
            'passTypes[]': passTypes,
            org: organizationId,
            test: testTypeId,
            option: optionId
        }
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });
};

// Get test variants, price and options
export const getTestVariantsAndPriceAndOptions = (cityId: string, orgId: number, passTypes: Array<number>) => {
    return axios.get(`/api/pass-test/city/${cityId}/organization/${orgId}/test`, { params: { 'passTypes[]': passTypes } })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });
};

// Details informaton of the selected organization
export const getFullInformationAboutLaboratory = (orgId: number, labId: number) =>
    axios.get(`/api/organization/${orgId}/unit/${labId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

// Registration for the test
export const signUpForTest = (testId: number, body: SignUpForTestBody) =>
    axios.post(`/api/pass-test/${testId}/reservation`, body)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error, 'OrderTestLab');
        });

// Order information by secret code 
export const getOrderInformationBySecretCode = (secret: string) =>
    axios.get(`/api/integration/cmd/reservation`, {
        headers: {
            Authorization: 'apikey a6d0a8e1-4246-46d3-8f6b-8d7b91be000a'
        },
        params: { secret }
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

// Saving Order ID from Laboratory
export const setOrderIDFromLaboratory = (secretCode: string, guid: string) => {
    var postData = {
        secret: secretCode,
        guid: guid
    };

    let axiosConfig = {
        headers: {
            Authorization: 'apikey a6d0a8e1-4246-46d3-8f6b-8d7b91be000a'
        },
    };
    return axios.put(`/api/integration/cmd/reservation`, postData, axiosConfig)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });
};

// Getting the current record for a test
export const getGetCurrentRecordForTest = (groupId: number) =>
    axios.get(`/api/pass-group/${groupId}/pass-test/reservation`, {
        headers: {
            Authorization: 'apikey a6d0a8e1-4246-46d3-8f6b-8d7b91be000a'
        }
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

// Set the test status manually
export const setTestStatus = (groupId: number, testStatus: boolean) =>
    axios.put(`/api/pass-group/${groupId}/status`, { passed: testStatus })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });