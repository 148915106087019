import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, CircularProgress, Grid, Typography, Box } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ServerError } from '../../api/error-service';
import { Alert, AlertTitle, Autocomplete, createFilterOptions } from '@material-ui/lab';
import * as userService from '../../api/user-service';
import * as flightService from '../../api/flight-service';
import { Person } from '../../api/user-service.types';
import { FlightShortInfo } from '../../api/flight-service.types';
import { getCurrentLanguageCode } from '../../i18n';
import * as infoService from '../../api/info-service';
import * as datetimeHelper from '../../helpers/datetimeHelper';
import { useSession } from '../auth/SessionProvider';
import CustomDatePicker from '../mui-extensions/CustomDatePicker';
import CustomNumberFormat from '../mui-extensions/CustomNumberFormat';
import { disableManualDateEntry } from '../../helpers/disableManualDateEntry';
import Form from '../form/Form';

const useStyles = makeStyles((theme) => ({
    root: {},
    documentTitle: {
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(2.5, 0, 0),
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    noOptions: {
        color: theme.palette.secondary.dark,
    },
}));

export interface EditFellowProps {
    flight: FlightShortInfo;
    flightFellows: Person[];
    editedFellow?: Person;
    onCompleted?: (fellow: Person, isNew: boolean) => void;
};

// Get current data and + 10 years from validation
function checkPassportExpiration(addYears: number = 0) {
    var today = new Date();
    return (
        today.getFullYear() + addYears + '-'
        + ("0" + (today.getMonth() + 1)).slice(-2) + '-'
        + ("0" + today.getDate()).slice(-2)
    );
}

export default function EditFellow({ flight, editedFellow, flightFellows, onCompleted, ...props }: EditFellowProps) {
    const classes = useStyles();
    const REGEXP_NAME = /^[0-9a-zA-Z-\s]+$/; //only Latin / Space / '-' / Number characters

    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`EditFellow.${key}`, options);
    const tt_EditPerson = (key: string, options: any = undefined) => t(`EditPerson.${key}`, options);
    const tt_EditDocument = (key: string, options: any = undefined) => t(`EditDocument.${key}`, options);

    const [serverError, setServerError] = React.useState<ServerError>();
    const [validationEnabled, setValidationEnabled] = React.useState(false);

    const [countryList, setCountryList] = React.useState([]);
    const [fellowList, setFellowList] = React.useState<Person[]>([]);
    const [fellowDocument, setFellowDocument] = React.useState<any>();
    const [selectedFellow, setSelectedFellow] = React.useState<Person>();
    const [editedFellowId, setEditedFellowId] = React.useState(editedFellow?.id);

    const { values, touched, errors, setFieldValue, ...formik } = useFormik({
        validateOnChange: validationEnabled,
        validateOnBlur: validationEnabled,
        initialValues: {
            firstName: editedFellow?.firstName || '',
            lastName: editedFellow?.lastName || '',
            middleName: editedFellow?.middleName || '',
            birthDate: editedFellow?.birthDate || '',
            email: editedFellow?.email || '',
            phone: (editedFellow?.phone || '').replace('+7', ''),

            documentNumber: '',
            documentExpiredAt: '',
            documentCountryId: '',
            documentSelectedCountryName: '',
            documentCountryName: '',
        },
        validationSchema: Yup.object({
            firstName: Yup.string()
                .required(tt_EditPerson('reuiredFieldError'))
                .max(255, t('fieldIsTooLongError', { max: 255 }))
                .matches(REGEXP_NAME, tt_EditPerson('nameLabelLatinError')),
            lastName: Yup.string()
                .required(tt_EditPerson('reuiredFieldError'))
                .max(255, t('fieldIsTooLongError', { max: 255 }))
                .matches(REGEXP_NAME, tt_EditPerson('nameLabelLatinError')),
            middleName: Yup.string()
                .max(255, t('fieldIsTooLongError', { max: 255 }))
                .matches(REGEXP_NAME, tt_EditPerson('nameLabelLatinError')),
            birthDate: Yup.date()
                .max(new Date(), tt_EditPerson('dataError')),
            email: Yup.string()
                .email(tt('emailValidationMessage')),
            phone: Yup.string()
                .transform(value => value.replace(/[^\d]/g, ''))
                .min(11, tt('phoneValidationMessage')),


            documentNumber: Yup.string()
                .max(128, tt_EditDocument('passportIsTooLong')),

            documentExpiredAt: Yup.date()
                .min(checkPassportExpiration(), tt_EditDocument('passportDataMin'))
                .max(checkPassportExpiration(10), tt_EditDocument('passportDataMax'))
                .when('documentNumber', {
                    is: (documentNumber: string) => !!documentNumber,
                    then: Yup.date().required(tt_EditDocument('reuiredFieldValidationMessage', { fieldName: tt_EditDocument('passportDataLabel') })),
                }),
            documentCountryId: Yup.number()
                .when('documentNumber', {
                    is: (documentNumber: string) => !!documentNumber,
                    then: Yup.number().required(tt_EditDocument('reuiredFieldValidationMessage', { fieldName: tt_EditDocument('countryIssueLabel') })),
                }),
            documentCountryName: Yup.string()
                .when('documentSelectedCountryName', {
                    is: () => true,
                    then: Yup.string().oneOf(
                        [Yup.ref('documentSelectedCountryName')],
                        tt_EditDocument('selectCountryFromListErrorMessage')
                    )
                }),
        }),
        onSubmit: async (values, actions) => {
            const phoneValue = values.phone.replace(/[^\d]/g, '');
            const personProps = {
                firstName: values.firstName,
                lastName: values.lastName,
                middleName: values.middleName || undefined,
                email: values.email || undefined,
                birthDate: values.birthDate || undefined,
                phone: phoneValue ? '+' + phoneValue : undefined,
            };

            const documentProps = {
                isDefault: true,
                type: 'international-passport',
                number: values.documentNumber,
                expiredAt: values.documentExpiredAt,
                countryId: parseInt(values.documentCountryId, 10),
            };

            try {
                let fellowId = editedFellowId;
                if (editedFellowId) {
                    await userService.editPerson(personProps, editedFellowId);
                }
                else {
                    if (selectedFellow
                        && selectedFellow.id
                        && selectedFellow.lastName === personProps.lastName
                        && selectedFellow.firstName === personProps.firstName
                        && selectedFellow.middleName === personProps.middleName) {

                        fellowId = await flightService.addFellowById(flight.id, selectedFellow.id)
                            .then(({ id }) => id);

                    } else {
                        fellowId = await flightService.addFellow(flight.id, personProps)
                            .then(({ id }) => id);
                    }


                    if (fellowId) {
                        setEditedFellowId(fellowId);
                    }
                }

                if (fellowId && documentProps.number
                    && (fellowDocument?.number !== documentProps.number
                        || fellowDocument?.countryId !== documentProps.countryId
                        || datetimeHelper.formatTo_yyyy_MM_dd(fellowDocument?.expiredAt) !== documentProps.expiredAt)
                ) {
                    await userService.createPersonDocument(fellowId, documentProps);
                }

                if (fellowId) {
                    onCompleted && onCompleted({ id: fellowId, ...personProps }, !editedFellow);
                }
            } catch (err) {
                const error = err as ServerError;
                if (error) {
                    setServerError(error);
                }
            }
        },
    });

    const handleSubmit = () => {
        setValidationEnabled(true);
        formik.submitForm();
    };

    const loadFellowDocument = React.useCallback(async (fellowId: number, countries: any[]) => {
        return userService.getPersonDocumentList(fellowId)
            .then((items) => {
                const internationalPassport = items.filter((d: any) => d.type === 'international-passport' && d.isDefault)[0];
                if (internationalPassport) {
                    setFellowDocument(internationalPassport);
                    setFieldValue('documentNumber', internationalPassport.number);
                    setFieldValue('documentExpiredAt', datetimeHelper.formatTo_yyyy_MM_dd(internationalPassport.expiredAt)); //TODO: Fix on server
                    const documentCountry = countries.filter(c => c.id === internationalPassport.countryId)[0];
                    if (documentCountry) {
                        setFieldValue('documentCountryId', internationalPassport.countryId);
                        setFieldValue('documentSelectedCountryName', documentCountry.name);
                        setFieldValue('documentCountryName', documentCountry.name);
                    }
                }
            })
            .catch(() => { });
    }, [setFieldValue]);


    // Get country List
    useEffect(() => {
        const language = getCurrentLanguageCode();
        infoService.getCountryList(language)
            .then((items) => {
                setCountryList(items);
                if (editedFellow)
                    return loadFellowDocument(editedFellow.id, items);
                return null;
            })
            .catch(() => { });
    }, [editedFellow, loadFellowDocument]);

    const { user } = useSession();

    // Get person list
    useEffect(() => {
        if (editedFellow)
            return;

        const skipIds = (flightFellows || []).map(f => f.id);

        userService.getFellowList()
            .then((fellows) => {
                setFellowList(fellows.filter(f => f.id !== user?.person?.id && skipIds.indexOf(f.id) === -1).sort(userService.compareByFullNameAsc));
            })
            .catch(() => { });
    }, [user?.person?.id, editedFellow, flightFellows]);

    return (
        <Form>
            {/* Exists fellow */}
            <Autocomplete
                freeSolo
                filterOptions={createFilterOptions({ matchFrom: 'start', trim: true })}
                openOnFocus
                disableClearable
                options={fellowList}
                inputValue={values.lastName}
                autoHighlight
                getOptionLabel={(option: Person) => option.lastName}
                renderOption={(option: Person) => (
                    <div>
                        <Typography variant="body2">{userService.getFullName(option)}</Typography>
                        { option.birthDate && (
                            <Typography variant="body1">{datetimeHelper.formatBirthDate(option.birthDate)}</Typography>
                        )}
                    </div>
                )}
                onChange={(e: any, value: Person | string) => {
                    let personValue = value as Person;
                    if (!personValue)
                        return;

                    setSelectedFellow(personValue);

                    formik.resetForm({
                        values: {
                            lastName: personValue.lastName || '',
                            firstName: personValue.firstName || '',
                            middleName: personValue.middleName || '',
                            birthDate: personValue.birthDate || '',
                            email: personValue.email || '',
                            phone: personValue.phone || ''.replace('+7', ''),
                            documentNumber: '',
                            documentExpiredAt: '',
                            documentCountryId: '',
                            documentSelectedCountryName: '',
                            documentCountryName: '',
                        }
                    });

                    loadFellowDocument(personValue.id, countryList);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        margin="normal"
                        label={tt_EditPerson('lastNameLabel')}
                        name="lastName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off',
                            "aria-autocomplete": 'none',
                        }}
                        value={values.lastName}
                        error={touched.lastName && Boolean(errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                        required
                    />
                )}
            />

            {/* First name edit */}
            <TextField
                margin="normal"
                required
                fullWidth
                label={tt_EditPerson('firstNameLabel')}
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={values.firstName}
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName}
            />

            {/* Middle name edit */}
            <TextField
                margin="normal"
                fullWidth
                label={tt_EditPerson('middleNameLabel')}
                name="middleName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={values.middleName}
                error={touched.middleName && Boolean(errors.middleName)}
                helperText={touched.middleName && errors.middleName}
            />

            {/* Birth Date */}
            <CustomDatePicker
                margin="normal"
                fullWidth
                label={tt_EditPerson('birthDateLabel')}
                name="birthDate"
                type="date"
                onKeyDown={disableManualDateEntry}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    max: "9999-12-31"
                }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={values.birthDate}
                error={touched.birthDate && Boolean(errors.birthDate)}
                helperText={touched.birthDate && errors.birthDate}
            />



            {/* Email */}
            <TextField
                margin="normal"
                fullWidth
                label={tt('fellowEmail')}
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={values.email}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
            />


            {/* Phone */}

            <TextField
                margin="normal"
                fullWidth
                label={tt('fellowPhone')}
                name="phone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={values.phone}
                error={touched.phone && Boolean(errors.phone)}
                helperText={touched.phone && errors.phone}
                InputProps={{
                    inputComponent: CustomNumberFormat,
                }}
                inputProps={{
                    inputMode: 'tel',
                    format: '+7 (###) ###-##-##'
                }}
            />

            <Typography variant="h4" className={classes.documentTitle}>{tt('documentTitle')}</Typography>

            <Grid container item justify="space-between" spacing={1}>

                {/* Field number document */}
                <Grid sm={6} xs={12} item>
                    <TextField
                        margin="normal"
                        fullWidth
                        label={tt_EditDocument('passportLabel')}
                        name="documentNumber"
                        autoComplete='off'
                        inputProps={{ inputMode: 'numeric' }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={values.documentNumber}
                        error={touched.documentNumber && Boolean(errors.documentNumber)}
                        helperText={touched.documentNumber && errors.documentNumber}
                    />
                </Grid>

                {/* Field data document */}
                <Grid sm={6} xs={12} item>
                    <CustomDatePicker
                        margin="normal"
                        fullWidth
                        label={tt_EditDocument('passportDataLabel')}
                        name="documentExpiredAt"
                        type="date"
                        onKeyDown={disableManualDateEntry}
                        autoComplete='off'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            max: "9999-12-31"
                        }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={values.documentExpiredAt}
                        error={touched.documentExpiredAt && Boolean(errors.documentExpiredAt)}
                        helperText={touched.documentExpiredAt && errors.documentExpiredAt}
                    />
                </Grid>

            </Grid>

            {/* Field country list */}
            <Autocomplete
                noOptionsText={tt_EditDocument('noOptions')}
                filterOptions={createFilterOptions({ matchFrom: 'start', trim: true })}
                autoSelect={values.documentCountryName.length >= 3}
                disableClearable={!values.documentCountryName}
                value={values.documentCountryId}
                inputValue={values.documentCountryName}
                options={countryList}
                onChange={(e: any, value: any) => {
                    setFieldValue('documentCountryId', value?.id || '');
                    setFieldValue('documentSelectedCountryName', value?.name || '');
                    setFieldValue('documentCountryName', value?.name || '');

                    setTimeout(() => { formik.validateField('documentCountryName'); }, 1); //Не нашел другого способа очистить валидатор сразу после выбора страны
                }}
                classes={{
                    option: classes.option,
                    noOptions: classes.noOptions
                }}
                autoHighlight
                getOptionLabel={(option: any) => (option.name ? option.name : "")}
                getOptionSelected={(option: any, value: any) => option.key === value.id}
                renderOption={(option: any) => (
                    <React.Fragment>
                        {option.name}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        margin="normal"
                        label={tt_EditDocument('countryIssueLabel')}
                        value={values.documentCountryName}
                        name="documentCountryName"
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                        error={touched.documentCountryName && Boolean(errors.documentCountryName || errors.documentCountryId)}
                        helperText={touched.documentCountryName && (errors.documentCountryName || errors.documentCountryId)}
                    />
                )}
            />


            <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit}
                startIcon={formik.isSubmitting && <CircularProgress size={14} />}
                disabled={formik.isSubmitting}
            >
                {tt('submitBtn')}
            </Button>
            {
                serverError &&
                <Box mt={2}>
                    <Alert severity="error">
                        <AlertTitle>{t('error')}</AlertTitle>
                        {serverError.displayMessage}
                    </Alert>
                </Box>
            }
        </Form>
    );
}