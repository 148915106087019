import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitle, DialogContent, Typography, Backdrop, CircularProgress } from '@material-ui/core';
import * as userService from '../../api/user-service';
import { useHistory, useParams } from "react-router-dom";
import { ServerError } from '../../api/error-service';
import CustomDialog from '../../components/mui-extensions/CustomDialog';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';


const useStyles = makeStyles(() => ({
    titleOk: {
        color: "#4FC685"
    },
    titleError: {
        color: "#E93131"
    },
}));


export default function WelcomeSignUp() {
    const classes = useStyles();
    const { code } = useParams<{ code: string }>();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`WelcomeSignUp.${key}`, options);
    const history = useHistory();
    const [open, setOpen] = React.useState(true);

    const [isLoading, setIsLoading] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState<string>('');

    useEffect(() => {
        userService.verifyWelcomeCode(code)
            .then(() => {
                setIsLoading(false);
            })
            .catch((err: ServerError) => {
                setErrorMessage(err.reason);
                setIsLoading(false);
            });
    }, [code]);

    const handleClose = () => {
        setOpen(false);
        history.push('/');
    };

    const setMessageDialog = (errorMessage: string) => {
        switch (errorMessage) {
            case 'account_already_exists': case 'email_is_already_used':
                return 'accountAlreadyExistsMessage';
            case 'confirmation_code_not_found': case 'validation_error':
                return 'invalidCodeMessage';
            case 'confirmation_code_expired':
                return 'codeIsExpiredMessage';
            case '': //OK
                return 'successEmailVerifiedMessageText';
            default:
                return 'unknownError';
        }
    };

    return (
        <React.Fragment>
            <CustomDialog
                maxWidth="xs"
                onClose={handleClose}
                open={open && !isLoading}
            >
                <DialogTitle className={clsx(`${errorMessage === '' ? classes.titleOk : classes.titleError}`)}>{tt('emailVerifiedMessageTitle')}</DialogTitle>
                <DialogContent>
                    <Typography>{tt(setMessageDialog(errorMessage))}</Typography>
                </DialogContent>

            </CustomDialog>
            <Backdrop open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </React.Fragment>
    );
}