import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSession } from '../auth/SessionProvider';

const PublicRoute = ({ component: Component, restricted, ...rest }: any) => {
    const { user } = useSession();

    return (
        <Route {...rest} render={props => (
            !!user && restricted ?
                <Redirect to="/" />
                : <Component {...props} />
        )} />
    );
};

export default PublicRoute;