import React from 'react';
import { Route } from 'react-router-dom';
import { useSession } from '../auth/SessionProvider';

const PrivateRoute = ({ component: Component, anonimComponent: AnonimComponent, anonimRedirect, ...rest }: any) => {
    const { user } = useSession();

    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            !!user
                ? <Component {...props} />
                : <AnonimComponent {...props} {...rest} />
        )
        } />
    );
};

export default PrivateRoute;