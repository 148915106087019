import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener, Grow, Link, MenuItem, MenuList, Paper, Popper, Typography } from '@material-ui/core';
import { useSession } from '../../auth/SessionProvider';


const useStyles = makeStyles(() => ({
    profileMenu: {
        zIndex: 11,
    },
    headerProfileBtnTitle: {
        margin: '6px 16px 6px 16px',
        paddingBottom: 16,
        borderBottom: '1px solid rgba(28, 44, 94, 0.15)',
        outline: 'none'
    },

}));


export default function ProfileMenuPopper(props: any) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { user, signOut } = useSession();

    const handleLogOutClick = () => {
        signOut();
    };

    return (
        <Popper open={props.openProfileMenu} anchorEl={props.profileMenuBtnRef.current} role={undefined} transition disablePortal className={classes.profileMenu}>

            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >

                    <Paper>
                        <ClickAwayListener onClickAway={props.handleProfileMenuClose}>

                            <MenuList>
                                <Typography component="p" variant="body2" className={classes.headerProfileBtnTitle}>{user?.email}</Typography>

                                {/* Link to profile  */}
                                <Link color="textPrimary" underline="none" href="/profile">
                                    <MenuItem>
                                        {t('profile')}
                                    </MenuItem>
                                </Link>

                                {/* Log out of profile */}
                                <MenuItem onClick={handleLogOutClick}>
                                    {t('signOut')}
                                </MenuItem>

                            </MenuList>

                        </ClickAwayListener>
                    </Paper>

                </Grow>
            )}

        </Popper>
    );
}

