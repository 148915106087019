import React from 'react';
import { useHistory } from 'react-router-dom';
import QuestionnaireDialog from '../../features/questionnaire/QuestionnaireDialog';
import ReqCard from './req-card-elements/ReqCard';
import ReqCardBody from './req-card-elements/ReqCardBody';
import ReqCardButtonAction from './req-card-elements/ReqCardButtonAction';
import ReqCardButtonActionExt from './req-card-elements/ReqCardButtonActionExt';
import ReqCardContent from './req-card-elements/ReqCardContent';
import ReqCardDialog from './req-card-elements/ReqCardDialog';
import ReqCardFooter from './req-card-elements/ReqCardFooter';
import ReqCardTitle from './req-card-elements/ReqCardTitle';
import { DialogTitle, DialogContent, Typography } from '@material-ui/core';
import CustomDialog from '../mui-extensions/CustomDialog';


export default function FactoryCard(props: any) {
    const history = useHistory();
    const [openExtDescriptionDialog, setOpenExtDescriptionDialog] = React.useState(false);
    const [openQuestionnaireDialog, setOpenQuestionnaireDialog] = React.useState(false);
    const [errorDialogContent, setErrorDialogContent] = React.useState({ title: "", message: "" });
    const [openErrorDialog, setOpenErrorDialog] = React.useState(false);
    const [cardStatus, setCardStatus] = React.useState(props.requirement.status);

    let showErrorDialog = (title: string, message: string) => {
        setErrorDialogContent({ title, message });
        setOpenErrorDialog(true);
    };

    const handleCloseQuestionnaireDialog = () => {
        setOpenQuestionnaireDialog(false);
        history.replace({
            hash: undefined
        });
    };

    return (
        <React.Fragment>

            <ReqCard cardStatus={cardStatus} isChanged={props.isChanged}>

                {/* CONTENT CARD */}
                <ReqCardContent>
                    <ReqCardTitle requirement={props.requirement} handleDialogOpen={() => setOpenExtDescriptionDialog(true)} cardStatus={cardStatus} />
                    <ReqCardBody tileDescription={props.requirement.tileDescription} />
                </ReqCardContent>

                {/* FOOTER CARD */}
                <ReqCardFooter>
                    {(props.requirement.extActionName || props.requirement.type === 'test') &&
                        <ReqCardButtonAction
                            requirement={props.requirement}
                            segmentId={props.segmentId}
                            setOpenQuestionnaireDialog={setOpenQuestionnaireDialog}
                            showErrorDialog={showErrorDialog}
                            cardStatus={cardStatus}
                        />
                    }

                    {props.requirement.extDescription &&
                        <ReqCardButtonActionExt
                            requirement={props.requirement}
                            handleDialogOpen={() => setOpenExtDescriptionDialog(true)}
                            cardStatus={cardStatus}
                        />
                    }
                </ReqCardFooter>

            </ReqCard>


            {/* DIALOG WITH INFO */}
            <ReqCardDialog
                openDialog={openExtDescriptionDialog}
                handleDialogClose={() => setOpenExtDescriptionDialog(false)}
                dialogContent={props.requirement.extDescription}
                requirement={props.requirement}
                cardType={props.requirement.type}
                cardStatus={cardStatus}
                segmentId={props.segmentId}
                flightId={props.flightId}
                requirementId={props.requirement.id}
                setCardStatus={setCardStatus}
                showErrorDialog={showErrorDialog}
            />

            {/* DIALOG WITH THE QUESTIONNAIRE */}
            {props.requirement.type === 'rule' && <QuestionnaireDialog
                openQuestionnaireDialog={openQuestionnaireDialog}
                groupId={props.requirement.id}
                handleClose={handleCloseQuestionnaireDialog}
                setCardStatus={setCardStatus}
            />
            }

            {/* DIALOG ERROR */}
            <CustomDialog
                open={openErrorDialog}
                onClose={() => setOpenErrorDialog(false)}
            >
                <DialogTitle>{errorDialogContent.title}</DialogTitle>
                <DialogContent>
                    <Typography component="p">{errorDialogContent.message}</Typography>
                </DialogContent>
            </CustomDialog>

        </React.Fragment>
    );
}