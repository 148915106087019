import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function SegmentDetailsMenuIcon(props: any) {
    return (
        <SvgIcon {...props}>
            <path fill="none" opacity="0.2" d="M2.5 10C3.05228 10 3.5 9.55228 3.5 9C3.5 8.44772 3.05228 8 2.5 8C1.94772 8 1.5 8.44772 1.5 9C1.5 9.55228 1.94772 10 2.5 10Z" stroke="#1C2C5E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path fill="none" opacity="0.2" d="M2.5 3C3.05228 3 3.5 2.55228 3.5 2C3.5 1.44772 3.05228 1 2.5 1C1.94772 1 1.5 1.44772 1.5 2C1.5 2.55228 1.94772 3 2.5 3Z" stroke="#1C2C5E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path fill="none" opacity="0.2" d="M2.5 17C3.05228 17 3.5 16.5523 3.5 16C3.5 15.4477 3.05228 15 2.5 15C1.94772 15 1.5 15.4477 1.5 16C1.5 16.5523 1.94772 17 2.5 17Z" stroke="#1C2C5E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </SvgIcon>
    );
}