import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Layout from '../layout/Layout';
import SuccessfulRegTestIcon from '../icons/SuccessfulRegTestIcon';
import { Button } from '@material-ui/core';
import { useSession } from '../auth/SessionProvider';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 58,
        marginBottom: 58,
        width: '100%',
        maxWidth: '716px',
        margin: '0 auto',
        position: 'relative',
        paddingLeft: 89,
        '& > h1': {
            marginBottom: 28,
        },
        '& > p': {
            width: '100%',
            marginBottom: 17,
            lineHeight: 1.29,
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 0,
        },
    },
    successfulIcon: {
        fontSize: 68,
        position: 'absolute',
        left: 2,
        top: 39,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 22,
            fontSize: 100,
            left: 0,
            top: 0,
            position: 'relative',
            width: '100%',
        },
    },
    bodyLink: {
        color: theme.palette.primary.main,
    },
    goToCouponButton: {
        marginTop: 18,
        width: '100%',
        maxWidth: '278px',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100%',
        },
    },

}));

function useQuery() {
    return qs.parse(useLocation().search, { ignoreQueryPrefix: true });
}

export default function SuccessfulRegTest() {

    const { Shp_passGroupId: groupId, Shp_planId: planId } = useQuery();

    const { user } = useSession();
    const history = useHistory();
    if (!user) { history.push('/#signin'); }

    const classes = useStyles();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`SuccessfulRegTest.${key}`, options);

    // Selects the type of test, and loads the pricing information
    const handlerGoToCoupon = () => {
        history.push(`/#reception-coupon/${groupId}`);
    };

    const handlerGoToMain = () => {
        history.push(`/`);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout>
            <Grid container justify="flex-start" className={classes.root}>
                {/* Icon */}
                <SuccessfulRegTestIcon className={classes.successfulIcon} />

                {/* Title */}
                <Typography component="h1" variant="h1" color="primary">{tt('titleText1')}</Typography>

                {groupId && (
                    <React.Fragment>
                        {/* Body */}
                        <Typography component="p" variant="body2">{tt('bodyText1')} {user?.email}</Typography>
                        <Typography component="p" variant="body1">{tt('bodyText2')}</Typography>
                        <Typography component="p" variant="body1">
                            {tt('bodyText3')}
                            <a href="/" target="_blank" rel="noreferrer" className={classes.bodyLink}>
                                {tt('bodyText4')}
                            </a>.
                        </Typography>

                        {/* Button go to coupon */}
                        <Button className={classes.goToCouponButton} variant="contained" color="primary" onClick={handlerGoToCoupon}>
                            {tt('goToCouponButton')}
                        </Button>

                    </React.Fragment>
                )}

                {planId && (
                    <React.Fragment>
                        {/* Body */}
                        <Typography component="p" variant="body2">Ваш тарифный план успешно обновлен</Typography>

                        {/* Button go to coupon */}
                        <Button className={classes.goToCouponButton} variant="contained" color="primary" onClick={handlerGoToMain}>
                            Начать пользоваться сервисом
                        </Button>

                    </React.Fragment>
                )}


            </Grid>
        </Layout>
    );
}