import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import theme from './theme/MainTheme';
import axios from 'axios';
import { SessionProvider } from './components/auth/SessionProvider';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from "react-router-dom";
import TagManager from 'react-gtm-module';
import { DialogProvider } from './providers/DialogProvider';

if (process.env.REACT_APP_TAG_MANAGER_TOKEN) {
    TagManager.initialize({ gtmId: process.env.REACT_APP_TAG_MANAGER_TOKEN });
}

axios.interceptors.request.use(function (config) {
    return config;
});

axios.interceptors.response.use(
    response => response,
    error => {
        const { status } = error.response;
        if (status === 401) {
            //TODO: Sign out and clear session
        }
        throw error;
    }
);

const invitedByReferralCode = new URLSearchParams(window.location.search).get('invite');

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <CssBaseline />
                    <Suspense fallback="loading">
                        <DialogProvider>
                            <SessionProvider authTokenKeyInCookie="token" invitedByReferralCode={invitedByReferralCode}>
                                <App />
                            </SessionProvider>
                        </DialogProvider>
                    </Suspense>
                </SnackbarProvider>
            </BrowserRouter>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
