import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Grid, Typography, Button, Link } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import { data } from './FaqBlock.data';
import { getCurrentLanguageCode } from '../../i18n';
import { ReactComponent as AccountIcon } from '../icons/AccountIcon.svg';
import CustomMarkdown, { getComponent } from '../mui-extensions/CustomMarkdown';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '64px',
        marginBottom: '64px',
    },
    h2: {
        fontSize: 24,
        fontWeight: 700,
        lineHeight: '100%',
    },
    mainColumn: {
        marginTop: '-19px',
    },
    accordion: {
        width: '100%',
        marginBottom: theme.spacing(1),
        borderBottom: '2px solid rgba(28, 44, 94, 0.3)',
        boxShadow: 'none',
        '&::before': {
            background: 'none',
        },
        '&:last-child': {
            borderBottom: 'none',
        }
    },
    accordionSummary: {
        marginTop: '22px',
        marginBottom: '22px',
        padding: 0,
        '&.Mui-expanded': {
            marginTop: '22px',
        },
    },
    accordionDetails: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    question: {
        fontSize: 18,
        fontWeight: 700,
        lineHeight: '100%',
    },
    answer: {
        marginTop: '8px',
        lineHeight: '130%',
        width: '87%',
    },
    expandIcon: {
        width: '26px',
        height: '26px',
    },
    supportLink: {
        textDecoration: 'none',
        flexShrink: 0,
        color: theme.palette.primary.main,
    }
}));

export default function FaqBlock(props: any) {

    const classes = useStyles();
    const { t } = useTranslation();
    const [items, setItems] = React.useState([] as any);

    React.useEffect(() => {
        setItems(data[getCurrentLanguageCode()]);
    }, [t]);

    const [expandedIndex, setExpandedIndex] = React.useState(-1);

    const handleToggle = (index: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpandedIndex(isExpanded ? index : -1);
    };


    const markdownOptions = {
        p: {
            props: {
                className: classes.answer,
            }
        },
        'button-signup': {
            component: getComponent(Button),
            props: {
                href: '#signup',
                variant: "contained",
                color: "primary",
                style: { marginTop: '24px' },
                startIcon: <AccountIcon style={{ height: 18, width: 18 }} />
            },
        },
        support: {
            component: getComponent(Link),
            props: {
                className: classes.supportLink,
                onClick: () => jivo_api.open(),
                style: { cursor: 'pointer' },
            }
        }
    };

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} md={4}>
                <Typography variant="h2" className={classes.h2}>{t('Landing.faqTitle')}</Typography>
            </Grid>
            <Grid item xs={12} md={8} className={classes.mainColumn}>
                {items.map((itemProps: any, index: number) => (
                    <Accordion expanded={expandedIndex === index} onChange={handleToggle(index)} className={classes.accordion} key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.expandIcon} htmlColor="#3766D9" />} className={classes.accordionSummary}>
                            {<Typography className={classes.question}>{itemProps.data.question}</Typography>}
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            {<CustomMarkdown children={itemProps.content} overrides={markdownOptions} />}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Grid>
        </Grid>



    );
}
