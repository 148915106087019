import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../../components/layout/Layout';
import { Box, Typography, Button, Grid, TextField } from '@material-ui/core';
import * as userService from '../../api/user-service';
import * as infoService from '../../api/info-service';
import * as flightService from '../../api/flight-service';
import { useSession } from '../../components/auth/SessionProvider';
import { disableManualDateEntry } from '../../helpers/disableManualDateEntry';
import * as orderService from '../../api/order-service';
//import { ServerError } from '../../api/error-service';

const useStyles = makeStyles((theme) => ({
    root: {},
}));

export default function Sandbox() {
    const classes = useStyles();

    const { user } = useSession();

    const handleGetCountryListClick = () => {
        infoService.getCountryList()
            .then(() => { })
            .catch(() => { });
    };

    const handleGetAirportClick = () => {
        infoService.getAirportByCode('IAH')
            .then(() => { })
            .catch(() => { });
    };

    const handleSearchInfoClick = () => {
        infoService.searchFlightInfo('SU2072', '2021-07-23')
            .then(() => { })
            .catch(() => { });
    };

    const handleCreatePersonClick = () => {
        const person = {
            firstName: 'Ivan',
            lastName: 'Ivanoff',
            middleName: 'Ivanovich',
            birthDate: '1983-11-05'
        };

        userService.createPerson(person)
            .then(() => { })
            .catch(() => { });

    };

    const handleCreateDocumentClick = () => {
        const document = {
            type: 'passport',
            number: '123456789',
            expiredAt: '2022-12-23',
            countryId: 643
        };
        userService.createPersonDocument(user?.person?.id || 0, document)
            .then(() => { })
            .catch(() => { });
    };

    const handleGetDocumentListClick = () => {
        userService.getPersonDocumentList(user?.person?.id || 0)
            .then(() => { })
            .catch(() => { });
    };

    const handleEditDocumentClick = () => {
        userService.setDefaultDocument(user?.person?.id || 0, 69)
            .then(() => { })
            .catch(() => { });
    };

    type ButtonColor = 'inherit' | 'primary' | 'secondary' | undefined;
    type ButtonVariant = 'contained' | 'outlined';
    type ButtonSize = 'small' | undefined;

    const buttonColors: ButtonColor[] = [undefined, 'primary', 'secondary'];
    const buttonVariants: ButtonVariant[] = ['contained', 'outlined'];
    const buttonSizes: ButtonSize[] = [undefined, 'small'];


    const handleGetFlightListClick = () => {
        flightService.getList()
            .then(() => { })
            .catch(() => { });
    };

    const handleGetFlightClick = () => {
        flightService.getById(9)
            .then(() => { })
            .catch(() => { });
    };

    const handleNewFlightClick = () => {
        const flight = {
            id: undefined,
            segments: [
                {
                    flightNumber: 'SU705',
                    departure: {
                        at: '2021-08-09T18:31',
                        airportId: 1
                    },
                    arrival: {
                        at: '2021-08-09T18:31',
                        airportId: 2
                    }
                }
            ]
        };
        flightService.createFlight(flight)
            .then(() => { })
            .catch(() => { });
    };

    const handleAddSegmentClick = () => {
        const segment = {
            flightNumber: 'SU705',
            departure: {
                at: '2021-08-09T18:31',
                airportId: 1
            },
            arrival: {
                at: '2021-08-09T18:31',
                airportId: 2
            }
        };
        flightService.addSegment(9, segment)
            .then(() => { })
            .catch(() => { });
    };

    const handleDeleteSegmentClick = () => {
        flightService.deleteSegment(10)
            .then(() => { })
            .catch(() => { });
    };

    const handleDeleteFlightClick = () => {
        flightService.deleteFlight(10)
            .then(() => { })
            .catch(() => { });
    };

    const handleAddFellow = () => {
        const personParams = {
            firstName: "Ivan",
            lastName: "Ivanoff",
            middleName: "Ivanovich",
            birthDate: "1983-11-05",
            phone: "+79127539685",
            email: "petrov@user.com"
        };
        flightService.addFellow(32, personParams)
            .then(() => { })
            .catch(() => { });
    };

    const handleExcludeAddFellow = () => {
        flightService.deleteFellow(32, 75)
            .then(() => { })
            .catch(() => { });
    };

    const handleGetListFellow = () => {
        flightService.getFellowList(55)
            .then((res) => { console.log(res); })
            .catch(() => { });
    };

    const handleSignUpForTest = () => {
        const body = {
            cityId: 4772,
            persons: [44] // Person id
        };

        orderService.signUpForTest(1, body)
            .then((res) => { console.log("signUpForTest"); console.log(res); })
            .catch(() => { });
    };

    const handleOrderInformationBySecretCode = () => {
        console.log("getOrderInformationBySecretCode");
        orderService.getOrderInformationBySecretCode("Y22K85")
            .then((res) => console.log(res))
            .catch(() => { });
    };

    const handleOrderSavingOrderIDFromLaboratory = () => {
        console.log("setOrderIDFromLaboratory");
        orderService.setOrderIDFromLaboratory("Y22K85", "045cac46-09ad-4f57-a062-4b725a")
            .then((res) => console.log(res))
            .catch(() => { });
    };

    const handleGetCurrentRecordForTest = () => {
        console.log("getGetCurrentRecordForTest");
        orderService.getGetCurrentRecordForTest(1) // 1 - COVID
            .then((res) => console.log(res))
            .catch(() => { });
    };

    const handleChangTestStatusToPass = () => {
        console.log("setTestStatus");
        orderService.setTestStatus(13, false)
            .then((res) => console.log(res))
            .catch(() => { });
    };

    return (
        <Layout>
            <Box className={classes.root}>
                <input type='date'></input>
                <input type='text'></input>
                <Typography variant="h2">Sandbox</Typography>

                <Typography variant="h3">Buttons</Typography>

                {buttonSizes.map((size) => {
                    return buttonVariants.map((variant) => (
                        <Box m={1}>
                            <Grid container justify="space-between">
                                {buttonColors.map((color) => {
                                    return (
                                        <Button color={color} variant={variant} size={size}>{`${color || 'default'}-${variant}-${size || 'medium'}`}</Button>
                                    );
                                })}
                            </Grid>
                        </Box>
                    ));
                })}


                <Typography variant="h3">Date</Typography>

                <TextField
                    margin="normal"
                    label="Date"
                    name="birthDate"
                    type="date"
                    onKeyDown={disableManualDateEntry}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <Typography variant="h3">API</Typography>

                <Grid container justify="space-between">
                    <Button variant="contained" onClick={handleGetCountryListClick}>Список стран</Button>
                    <Button variant="contained" onClick={handleGetAirportClick}>Аэропорт</Button>
                    <Button variant="contained" onClick={handleCreatePersonClick}>Привязка человека к аккаунту</Button>
                    <Button variant="contained" onClick={handleCreateDocumentClick}>Создание документа</Button>
                    <Button variant="contained" onClick={handleGetDocumentListClick}>Список документов</Button>
                    <Button variant="contained" onClick={handleEditDocumentClick}>Update</Button>
                </Grid>


                <Typography variant="h3">Flight API</Typography>

                <Grid container justify="space-between">
                    <Button variant="contained" onClick={handleGetFlightListClick}>List</Button>
                    <Button variant="contained" onClick={handleGetFlightClick}>GetById</Button>
                    <Button variant="contained" onClick={handleNewFlightClick}>New Flight</Button>
                    <Button variant="contained" onClick={handleDeleteFlightClick}>Del Flight</Button>
                    <Button variant="contained" onClick={handleAddSegmentClick}>New Segment</Button>
                    <Button variant="contained" onClick={handleDeleteSegmentClick}>Del Segment</Button>
                    <Button variant="contained" onClick={handleSearchInfoClick}>Search Info</Button>
                </Grid>

                <Typography variant="h3">Fellow travelers API</Typography>

                <Grid container justify="space-between">
                    <Button variant="contained" onClick={handleAddFellow}>Add Fellow</Button>
                    <Button variant="contained" onClick={handleExcludeAddFellow}>Exclude from flight</Button>
                    <Button variant="contained" onClick={handleGetListFellow}>Getting a list fellow</Button>
                </Grid>

                <Typography variant="h3">Registration for the test API</Typography>

                <Grid container justify="space-between">
                    <Button variant="contained" onClick={handleSignUpForTest}>Sign up for a test</Button>
                    <Button variant="contained" onClick={handleOrderInformationBySecretCode}>Order a test information by secret code</Button>
                    <Button variant="contained" onClick={handleOrderSavingOrderIDFromLaboratory}>Saving Order ID from Laboratory</Button>
                    <Button variant="contained" onClick={handleGetCurrentRecordForTest}>Getting the current record for a test</Button>
                </Grid>

                <Typography variant="h3">Action with cards</Typography>

                <Grid container justify="space-between">
                    <Button variant="contained" onClick={handleChangTestStatusToPass}>Change test status to Pass</Button>
                </Grid>
            </Box>
        </Layout >
    );
}