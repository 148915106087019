import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import partner1 from '../../images/partners/aeroflot.png';
import partner2 from '../../images/partners/gemotest.png';
import partner3 from '../../images/partners/alfa.png';
import partner4 from '../../images/partners/cmd.png';
import partner5 from '../../images/partners/sheremetievo.png';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '62px',
    },
    title: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '100%',
        color: '#666666',
        maxWidth: '220px',
    },
    img: {
        filter: 'grayscale(1)',
    }
}));

const partners = [
    {
        img: partner1,
        title: 'Аэрофлот',
    },
    {
        img: partner2,
        title: 'Гемотест',
    },
    {
        img: partner3,
        title: 'Альфа страхование',
    },
    {
        img: partner4,
        title: 'CMD',
    },
    {
        img: partner5,
        title: 'Шереметьево',
    },
];


export default function Partners(props: any) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container justify="space-between" alignItems="center" className={classes.root}>
            <Grid item>
                <Typography component="p" className={classes.title}>{t('Landing.ourPartners')}</Typography>
            </Grid>
            {partners.map((partner) => (
                <Grid item key={partner.img}>
                    <img src={partner.img} alt={partner.title} className={classes.img} />
                </Grid>
            ))}
        </Grid>
    );
}

