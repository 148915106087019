import React, { useEffect } from 'react';
import { useSession } from '../auth/SessionProvider';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, FormControlLabel, IconButton, Switch, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import * as userService from '../../api/user-service';
import clsx from 'clsx';
import DocumentListItem from './DocumentListItem';
import * as datetimeHelper from '../../helpers/datetimeHelper';
import { ReactComponent as EditIcon } from '../icons/EditIcon.svg';
import AddIcon from '@material-ui/icons/Add';
import ConfirmEmailInfoDialog from '../../features/modal/ConfirmEmailInfoDialog';
import { useDialogState } from '../../providers/DialogProvider';
import { ServerError } from '../../api/error-service';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0,
    },
    wrapBlock: {
        paddingTop: 20,
        paddingBottom: 20,
        borderBottom: '1px solid  #D2D5DF',
    },
    wrapBlockFirst: {
        paddingTop: 0,
    },
    wrapBlockLast: {
        borderBottom: '0px solid  #D2D5DF',
    },
    titleText: {
        marginBottom: 0,
        lineHeight: '48px',
    },
    subText: {
        marginBottom: 0,
        lineHeight: '100%',
        fontSize: '16px',
        fontWeight: 'normal',
    },
    fullNameText: {
        wordBreak: 'break-word',
    },
    buttonEdit: {
        width: '100%',
        maxWidth: 124,
        height: 32,
        textTransform: 'none',
        color: theme.palette.text.primary,
        borderColor: theme.palette.text.primary,
    },
    wrapDocumentsList: {
        marginTop: 0,
        marginBottom: theme.spacing(1.5),
    },
    addButtonIcon: {
        height: 26,
        width: 26,
    },
    noDocText: {
        lineHeight: '48px',
        [theme.breakpoints.down('xs')]: {
            lineHeight: '100%',
            marginBottom: theme.spacing(2),
        },
    },
    xsMargin: {
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(2),
        },
    },
    fellowsBtn: {
        height: 34,
        backgroundColor: '#E7EDFA',
        boxShadow: 'none',
        color: theme.palette.primary.main,
        padding: 0,
        paddingRight: 16,
        paddingLeft: 16,
        textTransform: 'uppercase',
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "#ffffff",
            boxShadow: 'none',
        },
    },
    switchProgress: {
        position: 'absolute',
        marginTop: theme.spacing(1),
    },
}));

export default function AccountInfo() {
    const classes = useStyles();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`Profile.${key}`, options);

    const { user, documentsIsUpdate } = useSession();

    const [userInfo, setUserInfo] = React.useState({
        firstName: '',
        lastName: '',
        middleName: '',
        birthDate: ''
    });

    const [userDocuments, setUserDocuments] = React.useState([]);

    const [isLoading, setIsLoading] = React.useState(false);
    const [openConfirmEmailInfoDialog, setOpenConfirmEmailInfoDialog] = React.useState(false);
    const [isDisabledConfirmMailBtn, setIsDisabledConfirmMailBtn] = React.useState(false);
    const [confirmMailErrorMsg, setConfirmMailErrorMsg] = React.useState('');
    const [mailingByEmailChecked, setMailinByEmailChecked] = React.useState(false);
    const [mailingBySMSChecked, setMailinBySMSChecked] = React.useState(false);
    const [mailingBySMSWarning, setMailingBySMSWarning] = React.useState(false);
    const [, setOpenDialog] = useDialogState('PaymentPlanDialog', false);
    const [serverError, setServerError] = React.useState<ServerError>();
    const [disableSwitch, setDisableSwitch] = React.useState(true);

    //Get list documents
    function getPersonDocumentList(userID: any) {
        return userService.getPersonDocumentList(userID)
            .then((data) => {
                setUserDocuments(data);
            })
            .catch(() => { });
    }

    useEffect(() => {
        setIsLoading(true);
        userService.getInfoPerson(user?.person?.id)
            .then((data) => {
                setUserInfo(data);
            })
            .then(() => {
                setIsLoading(false);
            });
    }, [user]);

    useEffect(() => {
        getPersonDocumentList(user?.person?.id || 0);
    }, [user, documentsIsUpdate]);

    useEffect(() => { 
        userService.getMailingSetting()
            .then((data) => {
                setMailinBySMSChecked(data.isCountryUpdatesSms);
                setMailinByEmailChecked(data.isCountryUpdatesEmail);
                setMailingBySMSWarning(data.isCountryUpdatesSms && !subscriptionTypeAllowBySMS(user?.subscriptionType));
                setDisableSwitch(false);
            })
            .catch((e) => setServerError(e));
    }, [user]); 

    const handleConfirmMailClick = () => {
        setIsDisabledConfirmMailBtn(true);
        userService.sendVerificationCodeForEmail(user!.email!)
            .then(() => {
                setOpenConfirmEmailInfoDialog(true);
                setIsDisabledConfirmMailBtn(false);
            })
            .catch((e) => {
                setOpenConfirmEmailInfoDialog(true);
                setIsDisabledConfirmMailBtn(false);
                setConfirmMailErrorMsg(e.reason);
            });
    };

    const hundleToggleSwitchByEmail = () => {
        submitMailingSetting({ isCountryUpdatesEmail: !mailingByEmailChecked });
        setMailinByEmailChecked(!mailingByEmailChecked);
    };

    const hundleToggleSwitchBySMS = () => {
        submitMailingSetting({ isCountryUpdatesSms: !mailingBySMSChecked });
        setMailinBySMSChecked(!mailingBySMSChecked);
        if (!mailingBySMSChecked && !subscriptionTypeAllowBySMS(user?.subscriptionType)) {
            setMailingBySMSWarning(true);
            setOpenDialog(true);
        } else setMailingBySMSWarning(false);
    };

    const submitMailingSetting = (setting: any) => {
        setDisableSwitch(true);
        userService.setMailingSetting(setting)
            .then(() => setDisableSwitch(false))
            .catch((e) => setServerError(e));
    };

    const subscriptionTypeAllowBySMS = (subscriptionType: String | undefined) => { 
        return (!!subscriptionType && (subscriptionType === 'optimal' || subscriptionType === 'all_inclusive'));
    };

    return (
        <Container className={classes.root}>

            {/* Full name */}
            <Grid container item className={clsx(classes.wrapBlock, classes.wrapBlockFirst)} alignItems="center">

                <Grid item container md={3} sm={5} xs={12} alignItems="center">
                    <Typography component="h3" variant="h2" display="inline" color="primary" className={classes.titleText}>{tt('titleFullName')}</Typography>
                    <IconButton href="#edit-account" disableFocusRipple>
                        <EditIcon />
                    </IconButton>
                </Grid>
                <Grid item container md={9} sm={7} xs={12} alignItems="center">
                    <Typography component="h4" variant="h3" className={classes.fullNameText}>{`${user?.person?.lastName} ${user?.person?.firstName}`} {user?.person?.middleName ? `${user?.person?.middleName}` : ''}</Typography>
                </Grid>

                <Grid item md={3} sm={5} xs={12}>
                    <Typography component="h3" variant="h2" color="primary" className={clsx(classes.subText, classes.xsMargin)}>{tt('birthDate')}</Typography>
                </Grid>
                <Grid item md={9} sm={7} xs={12}>
                    <Typography component="h4" variant="h3" className={classes.subText} color="secondary">
                        {isLoading ? '...' :
                            userInfo.birthDate ? datetimeHelper.formatBirthDate(userInfo.birthDate) : tt('birthDateNo')}
                    </Typography>
                </Grid>

            </Grid>

            {/* Email */}
            <Grid container item className={classes.wrapBlock} alignItems="center">

                <Grid item container md={3} sm={5} xs={12} alignItems="center">
                    <Typography component="h3" variant="h2" display="inline" color="primary" className={classes.titleText}>{tt('titleEmail')}</Typography>
                    <IconButton href="#change-email" disableFocusRipple>
                        <EditIcon />
                    </IconButton>
                </Grid>
                <Grid item container md={9} sm={7} xs={12} alignItems="center">
                    <Box mr={2}>
                        <Typography component="h4" variant="h3">{user?.email}</Typography>
                    </Box>
                    {user?.isEmailConfirmed === false &&
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.fellowsBtn}
                            disabled={isDisabledConfirmMailBtn}
                            onClick={() => handleConfirmMailClick()}
                            endIcon={isDisabledConfirmMailBtn && <CircularProgress size={14} />}
                        >
                            {tt('confirmMailBtn')}
                        </Button>
                    }
                    <ConfirmEmailInfoDialog
                        email={user!.email!}
                        open={openConfirmEmailInfoDialog}
                        errorMsg={confirmMailErrorMsg}
                        handleClose={() => setOpenConfirmEmailInfoDialog(false)}
                    />
                </Grid>

            </Grid>

            {/* Phone */}
            <Grid container item className={classes.wrapBlock} alignItems="center">

                <Grid item container md={3} sm={5} xs={12} alignItems="center">
                    <Typography component="h3" variant="h2" display="inline" color="primary" className={classes.titleText}>{tt('titlePhone')}</Typography>
                    <IconButton href="#change-phone" disableFocusRipple>
                        <EditIcon />
                    </IconButton>
                </Grid>
                <Grid item container md={9} sm={7} xs={12} alignItems="center">
                    <Typography component="h4" variant="h3">{userService.formatPhone(`${user?.phone}`)}</Typography>
                </Grid>

            </Grid>

            {/* Documents */}
            <Grid container item alignItems="flex-start" className={classes.wrapBlock} >

                <Grid item md={3} sm={5} xs={12}>
                    <Typography component="h3" variant="h2" color="primary" className={classes.titleText}>{tt('titleMyDocs')}</Typography>
                </Grid>
                <Grid item md={9} sm={7} xs={12}>
                    {userDocuments.length ? (
                        <Grid container spacing={2} className={classes.wrapDocumentsList}>
                            {
                                userDocuments.map((item: any) => (
                                    <Grid item key={item.id}>
                                        <DocumentListItem item={item} getPersonDocumentList={getPersonDocumentList} />
                                    </Grid>
                                ))
                            }

                        </Grid>
                    ) : (
                        <Typography component="h4" variant="h3" color="secondary" className={classes.noDocText}>
                            {tt('infoNonDocuments')}
                        </Typography>
                    )
                    }
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<AddIcon className={classes.addButtonIcon} />}
                        href="#bind-document"
                        disableFocusRipple
                    >
                        {tt('buttonAddDocText')}
                    </Button>
                </Grid>

            </Grid>

            {/* Mailing*/}
            <Grid container item className={clsx(classes.wrapBlock, classes.wrapBlockLast)} alignItems="flex-start">

                <Grid item container md={3} sm={5} xs={12} alignItems="center" >
                    <Typography component="h3" variant="h2" display="inline" color="primary" className={classes.titleText}>{tt('titleMailing')}</Typography>
                </Grid>
                <Grid item container md={9} sm={7} xs={12} alignItems="center">
                    <Grid item container md={9} sm={7} xs={12} alignItems="center">
                        <Typography component="h4" variant="h3" className={classes.noDocText}>
                            {tt('mailingText')}
                        </Typography>
                    </Grid>
                    {serverError && <Grid container item md={9} sm={7} xs={12} alignItems="center"> <Box mt={2}>
                        <Alert severity="error">
                            <AlertTitle>{t('error')}</AlertTitle>
                            {serverError.displayMessage}
                        </Alert>
                    </Box></Grid>}
                    {!serverError && <Grid container item md={9} sm={7} xs={12} alignItems="center">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={mailingByEmailChecked}
                                    onChange={() => hundleToggleSwitchByEmail()}
                                    name="checkedB"
                                    color="primary"
                                    disabled={disableSwitch}
                                />
                            }
                            label={disableSwitch ? <CircularProgress size={24} className={classes.switchProgress} /> : <Typography component="h4" variant="body2" color={mailingByEmailChecked ? "textPrimary" : "secondary"}>{tt('mailingByEmail')}</Typography>}
                        />
                    </Grid>}
                    {!serverError && <Grid container item md={9} sm={7} xs={12} alignItems="center">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={mailingBySMSChecked}
                                    onChange={() => hundleToggleSwitchBySMS()}
                                    name="checkedB"
                                    color="primary"
                                    disabled={disableSwitch}
                                />
                            }
                            label={!disableSwitch && <Typography component="h4" variant="body2" color={mailingBySMSChecked ? "textPrimary" : "secondary"}>{tt('mailingBySMS')}</Typography>}
                        />
                        {(mailingBySMSWarning && !disableSwitch) && <Typography component="span" variant="body2" color='error'>{tt('warningSubscriptionTypeAllowBySMS')}</Typography>}
                    </Grid>}
                </Grid>

            </Grid>

        </Container >
    );
}