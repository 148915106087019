import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, CircularProgress, Grid, FormControlLabel, Switch, Box, Typography, Checkbox } from '@material-ui/core';
import * as infoService from '../../../api/info-service';
import { ServerError } from '../../../api/error-service';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import { Alert, AlertTitle } from '@material-ui/lab';
import { getCurrentLanguageCode } from '../../../i18n';
import CustomDatePicker from '../../mui-extensions/CustomDatePicker';
import CustomTimePicker from '../../mui-extensions/CustomTimePicker';
import { disableManualDateEntry } from '../../../helpers/disableManualDateEntry';
import Form from '../../form/Form';
import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';

// Get airport ID by CODE
async function getAirportIdByCode(codeAirport: string, lang: string) {
    if (!codeAirport)
        return null;

    return infoService.getAirportByCode(codeAirport, lang)
        .then((res) => res);
}


export interface AddSegmentFormProps {
    onAddSegmentSuccessfully?: () => void;
    handlerSendDataToServer: () => void;
    handlerClose: () => void;
    handlerAddSegmentToFlight: (flightItem: any) => any;
    isSubmittingToServer: boolean;
    flightSegmentsLength: number;
    openForEdit: boolean;
};


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '10px 20px 32px 20px',
        overflow: 'hidden',
    },
    formTextField: {
        overflow: 'hidden',
        marginBottom: 2,
    },
    formTextFieldMaskInput: {
        textTransform: 'uppercase',
    },
    buttonAddSegment: {
        margin: theme.spacing(1, 0, 1.5),
        height: 34,
        textTransform: 'none',
        backgroundColor: '#E7EDFA',
        boxShadow: 'none',
        color: theme.palette.primary.main,
        padding: 0,
        paddingRight: 18,
        paddingLeft: 20,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "#ffffff",
            boxShadow: 'none',
        },
        [theme.breakpoints.down(600)]: {
            margin: theme.spacing(1.5, 0, 1.5),
        },
    },
    buttonAddSegmentIcon: {
        height: 26,
        width: 26
    },
    changeWayEnterDataWrap: {
        lineHeight: 1,
        marginTop: '1px',
        paddingLeft: 8,
        marginRight: 4,
        [theme.breakpoints.down(420)]: {
            marginTop: -7,
        },
    },
    formCheckboxWrap: {
        lineHeight: 1,
        marginTop: '2px',
        marginLeft: -6,
    },
    formCheckboxIcon: {
        margiTop: 0,
        marginLeft: 2,
    },
    formCheckboxIconDefault: {
        paddingTop: 7
    },
    icon: {
        borderRadius: 4,
        width: 20,
        height: 20,
        border: '1px solid rgba(28, 44, 94, 0.15)',
        backgroundColor: '#FFFFFF',
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#FFFFFF',
        width: 20,
        height: 20,
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
    },
}));


export default function AddSegmentForm({ onAddSegmentSuccessfully, handlerAddSegmentToFlight, handlerSendDataToServer, handlerClose, isSubmittingToServer, flightSegmentsLength, openForEdit }: AddSegmentFormProps) {
    const classes = useStyles();


    // For validation code airport. Only Latin characters
    const REGEXP_CODE_AIRPORT = /^[a-zA-Z]+$/;

    // For validation flight number. Detection of Russian symbols
    const REGEXP_FLIGHT_NUMBER_RUSSIAN_SYNBOLS = /^[а-яА-ЯЁё]+$/;

    // For validation flight number. Only Latin, Number, -, Space and \ characters
    const REGEXP_FLIGHT_NUMBER = /^[a-zA-Z0-9-\s/\\]+$/;

    // For replace characters in flight number. Replace -, Space and \ characters
    const REGEXP_FLIGHT_REPLACE = /[^a-zA-Z0-9]/g;


    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`AddSegmentForm.${key}`, options);


    const [serverError, setServerError] = React.useState<ServerError>();
    const [flightNumberError, setFlightNumberError] = React.useState<string>();
    const [validationEnabled, setValidationEnabled] = React.useState(false);
    const lang = getCurrentLanguageCode();


    // Toggle between automatic segment data entry and manual entry
    const [manualDataEntry, setManualDataEntry] = React.useState(false);


    // Sets whether this is the first segment added to the list
    const [isThisFirstSegmentAdded, setIsThisFirstSegmentAdded] = React.useState(true);
    const [handlerFromDoneButton, setHandlerFromDoneButton] = React.useState(true);

    const initialValues = {
        flightNumber: '',
        departureDate: '',
        departureTime: '',
        arrivalDate: '',
        arrivalTime: '',
        departureAirport: '',
        arrivalAirport: '',
        isTransit: false,
        isManual: manualDataEntry
    };


    const { values, touched, errors, ...formik } = useFormik({
        validateOnChange: validationEnabled,
        validateOnBlur: validationEnabled,
        initialValues: initialValues,
        validationSchema: Yup.object({
            isManual: Yup.boolean(),

            flightNumber: Yup.string()
                .required(tt('requiredMessage', { fieldName: tt('flightNumber') }))
                .trim()
                .matches(REGEXP_FLIGHT_NUMBER, tt('errorOnlyLatinAndNumber')),

            departureDate: Yup.date()
                .required(tt('requiredMessage', { fieldName: tt('departureDate') })),

            isTransit: Yup.boolean(),

            departureTime: Yup.string()
                .when('isManual', {
                    is: true,
                    then: Yup.string().required(tt('requiredMessage', { fieldName: tt('departureTime') }))
                }),

            arrivalDate: Yup.string()
                .when('isManual', {
                    is: true,
                    then: Yup.string().required(tt('requiredMessage', { fieldName: tt('arrivalDate') }))
                }),

            arrivalTime: Yup.string()
                .when('isManual', {
                    is: true,
                    then: Yup.string().required(tt('requiredMessage', { fieldName: tt('arrivalTime') }))
                }),

            departureAirport: Yup.string()
                .when('isManual', {
                    is: true,
                    then: Yup.string()
                        .required(tt('requiredMessage', { fieldName: tt('departureAirport') }))
                        .min(3, tt('errorAirportCodeLength'))
                        .max(3, tt('errorAirportCodeLength'))
                        .matches(REGEXP_CODE_AIRPORT, tt('errorOnlyLatin'))
                }),

            arrivalAirport: Yup.string()
                .when('isManual', {
                    is: true,
                    then: Yup.string()
                        .required(tt('requiredMessage', { fieldName: tt('arrivalAirport') }))
                        .min(3, tt('errorAirportCodeLength'))
                        .max(3, tt('errorAirportCodeLength'))
                        .matches(REGEXP_CODE_AIRPORT, tt('errorOnlyLatin'))
                }),
        }),

        onSubmit: async (values, actions) => {
            setServerError(undefined);

            // --- AUTOMATIC DATA ENTRY ---
            if (!manualDataEntry) {

                // Get the cleaned up string
                let searchFlightNumber = values.flightNumber.trim().replace(REGEXP_FLIGHT_REPLACE, '');

                // Get flight information
                return infoService.searchFlightInfo(searchFlightNumber, values.departureDate)
                    .then((response) => {
                        const flightItem = {
                            flightNumber: searchFlightNumber.toUpperCase().trim().replace(REGEXP_FLIGHT_REPLACE, ''),
                            airline: response.airline,
                            isTransit: values.isTransit,
                            departure: {
                                airport: {
                                    city: {
                                        name: response.departure.airport.city.name,
                                    },
                                    code: (response.departure.airport.code).toUpperCase(),
                                    name: response.departure.airport.name,
                                },
                                countryId: response.departure.airport.countryId,
                                airportId: response.departure.airport.id,
                                at: response.departure.at,
                            },
                            arrival: {
                                airport: {
                                    city: {
                                        name: response.arrival.airport.city.name,
                                    },
                                    code: (response.arrival.airport.code).toUpperCase(),
                                    name: response.arrival.airport.name,
                                },
                                countryId: response.arrival.airport.countryId,
                                airportId: response.arrival.airport.id,
                                at: response.arrival.at,
                            }
                        };

                        // Add the resulting segment to the array of segments
                        handlerAddSegmentToFlight(flightItem);
                    })
                    .then(() => {
                        actions.resetForm({ values: { ...initialValues, isManual: values.isManual } });
                    })
                    .then(() => {
                        if (!isThisFirstSegmentAdded) {
                            onAddSegmentSuccessfully && onAddSegmentSuccessfully();
                        }

                        if (isThisFirstSegmentAdded || (handlerFromDoneButton && (values.flightNumber || values.departureDate))) {
                            handlerSendDataToServer();
                        }
                    })
                    .catch((err: ServerError) => {
                        setServerError(err);
                    });
            }


            // --- MANUAL DATA ENTRY --- 
            else {
                // Departure. Get the ID of the airport by code
                let departureAirportInfo = await getAirportIdByCode(values.departureAirport, lang)
                    .catch(() => {
                        formik.setFieldError('departureAirport', tt('errorAirportCode'));
                    });


                // Arrival. Get the ID of the airport by code
                let arrivalAirportInfo = await getAirportIdByCode(values.arrivalAirport, lang)
                    .catch(() => {
                        formik.setFieldError('arrivalAirport', tt('errorAirportCode'));
                    });


                if (departureAirportInfo && arrivalAirportInfo) {
                    let flightItem = {
                        flightNumber: values.flightNumber.trim().replace(REGEXP_FLIGHT_REPLACE, '').toUpperCase(),
                        isTransit: values.isTransit,
                        departure: {
                            airport: {
                                city: {
                                    name: departureAirportInfo.city.name,
                                },
                                code: (values.departureAirport).toUpperCase(),
                                name: departureAirportInfo.name,
                            },
                            countryId: departureAirportInfo.countryId,
                            airportId: departureAirportInfo.id,
                            at: values.departureDate + "T" + values.departureTime,
                        },
                        arrival: {
                            airport: {
                                city: {
                                    name: arrivalAirportInfo.city.name,
                                },
                                code: (values.arrivalAirport).toUpperCase(),
                                name: arrivalAirportInfo.name,
                            },
                            countryId: arrivalAirportInfo.countryId,
                            airportId: arrivalAirportInfo.id,
                            name: arrivalAirportInfo.name,
                            at: values.arrivalDate + "T" + values.arrivalTime,
                        }
                    };

                    // Add the resulting segment to the array of segments
                    handlerAddSegmentToFlight(flightItem);

                    actions.resetForm({ values: { ...initialValues, isManual: values.isManual } });

                    if (!isThisFirstSegmentAdded) {
                        onAddSegmentSuccessfully && onAddSegmentSuccessfully();
                    }

                    if (isThisFirstSegmentAdded || (handlerFromDoneButton && (values.flightNumber || values.departureDate))) {
                        handlerSendDataToServer();
                    }


                }

            };

        },
    });


    // Button add segment
    const handlerSubmit = () => {
        setServerError(undefined);
        setHandlerFromDoneButton(false);
        setValidationEnabled(true);
        formik.submitForm();
        setIsThisFirstSegmentAdded(false);
    };

    const handlerChangeFlightNumber = (event: React.ChangeEvent) => {
        formik.handleChange(event);
        setFlightNumberError('');
    };


    // Select date and load info
    const handlerSearchFlightInfo = (departureDate: string) => {

        let searchFlightNumber = values.flightNumber.trim().replace(REGEXP_FLIGHT_REPLACE, '');

        // For validation flight number. Detection of Russian symbols
        if (searchFlightNumber.match(REGEXP_FLIGHT_NUMBER_RUSSIAN_SYNBOLS)) {
            setFlightNumberError(tt('errorDetectionRussianSymbols'));
            return;
        }

        // For validation flight number. Only Latin, Number, -, Space and \ characters
        if (!searchFlightNumber.match(REGEXP_FLIGHT_NUMBER)) {
            setFlightNumberError(tt('errorOnlyLatinAndNumber'));
            return;
        }

        return infoService.searchFlightInfo(searchFlightNumber, departureDate)
            .then((res) => {
                formik.setFieldValue("departureTime", res.departure.at.split('T')[1]);
                formik.setFieldValue("arrivalDate", res.arrival.at.split('T')[0]);
                formik.setFieldValue("arrivalTime", res.arrival.at.split('T')[1]);
                formik.setFieldValue("departureAirport", res.departure.airport.code);
                formik.setFieldValue("arrivalAirport", res.arrival.airport.code);
            })
            .catch((err: ServerError) => {
                setServerError(err);
            });
    };


    // Select date and load info
    const handlerFlightNumberBlure = (event: React.FocusEvent) => {
        formik.handleBlur(event);

        setServerError(undefined);

        if (!values.flightNumber || !values.departureDate) {
            // setFlightNumberError(tt('fillMessageBefore'));
            return;
        }

        handlerSearchFlightInfo(values.departureDate);
    };

    // Select date and load info
    const handlerChangeDepartureDate = (event: React.ChangeEvent) => {
        formik.handleChange(event);

        setServerError(undefined);

        if (!values.flightNumber) {
            setFlightNumberError(tt('fillMessageBefore'));
            return;
        }

        const input = event.currentTarget as HTMLInputElement;
        const departureDate = input.value;

        handlerSearchFlightInfo(departureDate);
    };

    // Button done
    const handlerButtonDone = () => {
        setServerError(undefined);
        setHandlerFromDoneButton(true);

        if (!flightSegmentsLength && !values.flightNumber && !values.departureDate) {
            handlerClose();
            return;
        }

        if (values.flightNumber || values.departureDate) {
            setValidationEnabled(true);
            formik.submitForm();
        }

        else {
            handlerSendDataToServer();
        }

    };


    // Switching between automatic and manual data entry
    const handlerChangeWayEnterData = () => {
        formik.resetForm({ values });
        const newValue = !manualDataEntry;
        setManualDataEntry(newValue);
        setServerError(undefined);
        formik.setFieldValue('isManual', newValue);
    };

    return (
        <Form>
            <Grid container item spacing={1} justify="space-between">

                <Grid xs={12} item>
                    {/* Flight number */}
                    <TextField
                        id="flightNumber"
                        name="flightNumber"
                        label={tt('flightNumber')}
                        className={classes.formTextField}
                        required
                        fullWidth
                        autoComplete='off'
                        variant="filled"
                        onChange={handlerChangeFlightNumber}
                        onBlur={handlerFlightNumberBlure}
                        value={values.flightNumber}
                        error={!!flightNumberError || (touched.flightNumber && Boolean(errors.flightNumber))}
                        helperText={flightNumberError || (touched.flightNumber && errors.flightNumber)}
                    />
                </Grid>


                {/* OUT */}

                {/* Out date */}
                <Grid item xs={6}>
                    <CustomDatePicker
                        id="departureDate"
                        name="departureDate"
                        label={tt('departureDate')}
                        className={classes.formTextField}
                        required
                        fullWidth
                        type="date"
                        autoComplete='off'
                        onKeyDown={disableManualDateEntry}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            max: "9999-12-31",
                        }}
                        onChange={handlerChangeDepartureDate}
                        onBlur={formik.handleBlur}
                        value={values.departureDate}
                        error={touched.departureDate && Boolean(errors.departureDate)}
                        helperText={touched.departureDate && errors.departureDate}
                    />
                </Grid>

                {/* Out time */}
                <Grid item xs={6}>
                    <CustomTimePicker
                        id="departureTime"
                        name="departureTime"
                        label={tt('departureTime')}
                        className={classes.formTextField}
                        required
                        fullWidth
                        disabled={!manualDataEntry}
                        type="time"
                        autoComplete='off'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={values.departureTime}
                        error={touched.departureTime && Boolean(errors.departureTime)}
                        helperText={touched.departureTime && errors.departureTime}
                    />
                </Grid>


                {/* IN */}

                {/* In date */}
                <Grid item xs={6}>
                    <CustomDatePicker
                        id="arrivalDate"
                        name="arrivalDate"
                        label={tt('arrivalDate')}
                        className={classes.formTextField}
                        required
                        fullWidth
                        disabled={!manualDataEntry}
                        onKeyDown={disableManualDateEntry}
                        type="date"
                        autoComplete='off'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            max: "9999-12-31"
                        }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={values.arrivalDate}
                        error={touched.arrivalDate && Boolean(errors.arrivalDate)}
                        helperText={touched.arrivalDate && errors.arrivalDate}
                    />
                </Grid>

                {/* In time */}
                <Grid item xs={6}>
                    <CustomTimePicker
                        id="arrivalTime"
                        name="arrivalTime"
                        label={tt('arrivalTime')}
                        className={classes.formTextField}
                        required
                        fullWidth
                        disabled={!manualDataEntry}
                        type="time"
                        autoComplete='off'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={values.arrivalTime}
                        error={touched.arrivalTime && Boolean(errors.arrivalTime)}
                        helperText={touched.arrivalTime && errors.arrivalTime}
                    />
                </Grid>


                {/* Airport */}

                {/* Out airport */}
                <Grid item xs={6}>
                    <TextField
                        className={classes.formTextField}
                        id="departureAirport"
                        name="departureAirport"
                        label={tt('departureAirport')}
                        required
                        fullWidth
                        disabled={!manualDataEntry}
                        autoComplete='off'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            className: classes.formTextFieldMaskInput
                        }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={values.departureAirport}
                        error={touched.departureAirport && Boolean(errors.departureAirport)}
                        helperText={touched.departureAirport && errors.departureAirport}
                    />
                </Grid>

                {/* In airport */}
                <Grid item xs={6}>
                    <TextField
                        id="arrivalAirport"
                        name="arrivalAirport"
                        label={tt('arrivalAirport')}
                        className={classes.formTextField}
                        required
                        fullWidth
                        disabled={!manualDataEntry}
                        autoComplete='off'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            className: classes.formTextFieldMaskInput
                        }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={values.arrivalAirport}
                        error={touched.arrivalAirport && Boolean(errors.arrivalAirport)}
                        helperText={touched.arrivalAirport && errors.arrivalAirport}
                    />
                </Grid>


                <Grid container spacing={1} justify="space-between" >

                    {/* Checkbox set transit */}
                    <Grid item>
                        <FormControlLabel
                            className={classes.formCheckboxWrap}
                            control={
                                <Checkbox
                                    className={clsx(classes.formCheckboxIcon, classes.formCheckboxIconDefault)}
                                    icon={<span className={classes.icon} />}
                                    checkedIcon={<CheckIcon className={clsx(classes.icon, classes.checkedIcon)} />}
                                    disableRipple
                                    checked={values.isTransit}
                                    onChange={formik.handleChange}
                                    name="isTransit"
                                    color="primary"
                                />
                            }
                            label={tt('isTransit')}
                        />
                    </Grid>

                    {/* Button change way enter data justify="flex-end" */}
                    <Grid item >
                        <FormControlLabel
                            className={classes.changeWayEnterDataWrap}
                            control={
                                <Switch
                                    //size='small'
                                    checked={manualDataEntry!}
                                    onChange={handlerChangeWayEnterData}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label={
                                <Typography component="span" variant="body1">
                                    {tt('buttonFillInManually')}
                                </Typography>
                            }
                        />
                    </Grid>
                </Grid>


                {/* Button add segment */}
                <Grid container item spacing={0} justify="center" alignItems="center">
                    <Button
                        type="button"
                        variant="contained"
                        className={classes.buttonAddSegment}
                        onClick={handlerSubmit}
                        endIcon={<AddIcon className={classes.buttonAddSegmentIcon} />}
                        disabled={formik.isSubmitting}
                    >
                        {tt('buttonAddSegment')}
                    </Button>
                </Grid>


                {/* Button DONE */}
                <Grid container item spacing={0} justify="center" alignItems="center">
                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handlerButtonDone}
                        startIcon={formik.isSubmitting && <CircularProgress size={14} />}
                        disabled={formik.isSubmitting || ((values.flightNumber.length && values.departureDate.length) === 0 && !flightSegmentsLength)}
                    >
                        {openForEdit && !flightSegmentsLength ? tt('buttonCLOSE') : tt('buttonDONE')}
                    </Button>
                </Grid>


                {/* Show errors */}
                {serverError &&
                    <Box mt={2}>
                        <Grid container item spacing={0}>
                            <Alert severity="error">
                                <AlertTitle>{t('error')}</AlertTitle>
                                {serverError.displayMessage}
                            </Alert>
                        </Grid>
                    </Box>
                }

            </Grid >
        </Form>
    );
}