import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import OrderTestLab from '../order-test-lab/OrderTestLab';
// import OrderTestTypeHome from './order-test-type-home/OrderTestTypeHome';
// import OrderTestTypeAirport from './order-test-type-airport/OrderTestTypeAirport';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0,
        boxSizing: 'border-box',
    },
}));

export default function TypeContainer(props: any) {
    const classes = useStyles();

    const renderSwitch = (testTypeChoice: any) => {
        switch (testTypeChoice) {
            case 'typeLab':
                return <OrderTestLab
                    passTypeIdArray={props.passTypeIdArray}
                    segmentId={props.segmentId}
                    segment={props.segment}
                    countryPassSteps={props.countryPassSteps}
                    setReadyTimeHoursForTitle={props.setReadyTimeHoursForTitle}
                />;
            // case 'typeHome':
            //     return <OrderTestTypeHome />;
            // case 'typeAirport':
            //     return <OrderTestTypeAirport />;
            default:
                return <OrderTestLab
                    passTypeIdArray={props.passTypeIdArray}
                    segmentId={props.segmentId}
                    segment={props.segment}
                    countryPassSteps={props.countryPassSteps}
                    setReadyTimeHoursForTitle={props.setReadyTimeHoursForTitle}
                />;
        }
    };

    return (
        <Container className={classes.root}>
            {renderSwitch(props.testTypeChoice)}
        </Container>
    );
}