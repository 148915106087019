import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        overflow: 'hidden',// TODO: Find best solution to fix Grid spacing issue
    },
}));

export default function Form(props: any) {
    const classes = useStyles();

    return (
        <form className={clsx(classes.root, props.className)} noValidate autoComplete="off">
            {props.children}
        </form>
    );
}