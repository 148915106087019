import React from 'react';
import ConfirmPhoneNumber from '../profile/ConfirmPhoneNumber';
import SetPassword from './SetPassword';

export default function SignUp(props: any) {
    const [code, setCode] = React.useState<string>('');
    const [phone, setPhone] = React.useState<string>('');
    const [isConfirmPhoneDone, setIsConfirmPhoneDone] = React.useState<boolean>(false);

    const handleSetPasswordDone = () => {
        setIsConfirmPhoneDone(false);
        setCode('');
        setPhone('');
    };

    return (
        <React.Fragment>
            {code.length === 0 && phone.length === 0 && isConfirmPhoneDone === false &&
                <ConfirmPhoneNumber mode='signup' setCode={setCode} setPhone={setPhone} setIsConfirmPhoneDone={setIsConfirmPhoneDone} />
            }

            {code.length > 0 && phone.length > 0 && isConfirmPhoneDone === true &&
                <SetPassword mode='signup' code={code} phone={phone} handleSetPasswordDone={handleSetPasswordDone} />
            }
        </React.Fragment>
    );
}