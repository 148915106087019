import React from 'react';
import { useLocation, Switch } from 'react-router-dom';
import { Location } from 'history';

const ModalSwitch = ({ children }: { children?: React.ReactNode; }) => {
    const location = useLocation();

    const modalLocation = {
        pathname: `${location.hash}`
    } as Location<unknown>;

    return (
        <Switch location={modalLocation}>
            {children}
        </Switch>
    );
};

export default ModalSwitch;