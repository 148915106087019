import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, Grid, Link, TextField, FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as userService from '../../api/user-service';
import { ServerError } from '../../api/error-service';
import { AlertTitle } from '@material-ui/lab';
import { useSession } from './SessionProvider';
import CustomNumberFormat from '../mui-extensions/CustomNumberFormat';
import Form from '../form/Form';
import { useSnackbar } from 'notistack';
import RecoveryButton, { recoveryErrors } from './RecoveryButton';

export interface SignInProps {
    onCompleted?: (result?: any) => void;
}

const useStyles = makeStyles((theme) => ({
    actionsGrid: {
        marginTop: theme.spacing(2.5),
        marginBottom: theme.spacing(0),
    },
    linkCell: {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            textAlign: 'center',
            marginTop: theme.spacing(2),
            paddingBottom: theme.spacing(0)
        }
    },
    confirmProcessing: {
        fontSize: 11,
        color: "#808080"
    },
    linkConfirm: {
        color: "#808080",
        textDecoration: 'underline'
    },
    errorMsg: {
        color: "#f44336",
        fontSize: 11
    },
}));

export default function SignIn({ onCompleted }: SignInProps) {
    const classes = useStyles();

    const { refreshSession } = useSession();
    const { enqueueSnackbar } = useSnackbar();

    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`SignIn.${key}`, options);

    const [serverError, setServerError] = React.useState<ServerError>();
    const [validationEnabled, setValidationEnabled] = React.useState(false);

    const { values, touched, errors, ...formik } = useFormik({
        validateOnChange: validationEnabled,
        validateOnBlur: validationEnabled,
        initialValues: {
            phone: '',
            password: '',
            check: true
        },
        validationSchema: Yup.object({
            phone: Yup.string()
                .required(t('reuiredFieldValidationMessage', { fieldName: t('phoneNumberLabel') }))
                .transform(value => value.replace(/[^\d]/g, ''))
                .min(11, t('phoneFormatValidationMessage')),
            password: Yup.string()
                .required(t('reuiredFieldValidationMessage', { fieldName: t('passwordLabel') })),
            check: Yup.bool()
                .oneOf([true], t('reuiredFieldValidationMessage')),
        }),
        onSubmit: async (values) => {
            setServerError(undefined);
            return userService.signIn('+' + values.phone.replace(/[^\d]/g, ''), values.password)
                .then(() => refreshSession())
                .then(() => {
                    onCompleted && onCompleted();
                })
                .catch((err: ServerError) => {
                    setServerError(err);
                });
        },
    });

    const handleSubmit = () => {
        setValidationEnabled(true);
        formik.submitForm();
    };

    function handleSignUpClosedTesting(event: React.MouseEvent<HTMLSpanElement, MouseEvent> | React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        if (window.location.hostname === "telepath.ru") {
            event.preventDefault();
            enqueueSnackbar(tt("closedTesting"), { variant: 'info', preventDuplicate: true });
        }
    };

    return (
        <Form>
            <TextField
                margin="normal"
                required
                fullWidth
                autoFocus
                autoComplete='off'
                label={t('phoneNumberLabel')}
                name="phone"
                value={values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={touched.phone && Boolean(errors.phone)}
                helperText={touched.phone && errors.phone}
                InputProps={{
                    inputComponent: CustomNumberFormat,
                }}
                inputProps={{
                    inputMode: 'tel',
                    format: '+7 (###) ###-##-##'
                }}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={t('passwordLabel')}
                type="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={values.password}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
            />
            <Grid container alignItems="center" className={classes.actionsGrid}>
                <Grid item xs={12}>
                    <Button
                        type="button"
                        onClick={handleSubmit}
                        fullWidth
                        variant="contained"
                        color="primary"
                        startIcon={formik.isSubmitting && <CircularProgress size={14} />}
                        disabled={formik.isSubmitting}
                    >
                        {tt('submitBtn')}
                    </Button>
                </Grid>
            </Grid>
            {serverError &&
                <Box mt={2}>
                    <Alert severity="error">
                        <AlertTitle>{t('error')}</AlertTitle>
                        {serverError.displayMessage}
                    </Alert>
                </Box>
            }
            {(!!serverError && recoveryErrors.includes(serverError.reason)) && <RecoveryButton phone={values.phone} formik={formik} />}
            <Grid container alignItems="center" justify="space-between" className={classes.actionsGrid}>
                <Grid item>
                    <Link
                        href="#reset-password"
                        variant="body1"
                        onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent> | React.MouseEvent<HTMLAnchorElement, MouseEvent>) => handleSignUpClosedTesting(event)}>
                        {t('forgotPasswordText')}
                    </Link>
                </Grid>
                <Grid item>
                    <Link
                        href="#signUp"
                        variant="body1"
                        onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent> | React.MouseEvent<HTMLAnchorElement, MouseEvent>) => handleSignUpClosedTesting(event)}>
                        {t('signUp')}
                    </Link>
                </Grid>
            </Grid>
            <Grid item>
                <Box mt={1}>
                    <FormControlLabel
                        onChange={formik.handleChange}
                        control={
                            <Checkbox
                                name="check"
                                defaultChecked
                                disableRipple
                                style={{
                                    color: "#3766D9",
                                }}
                            />}
                        label={<Typography className={classes.confirmProcessing}>{t('SignIn.iAccept')}<Link className={classes.linkConfirm} href='/blog/confidentiality'>{t('SignIn.confirmProcessingPolicy')}</Link></Typography>}
                    />
                    {(errors.check) &&
                        <Typography className={classes.errorMsg}>{t('reuiredFieldValidationMessage')}</Typography>
                    }
                </Box>
            </Grid>
        </Form>
    );
}