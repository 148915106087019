import axios from 'axios';
import { getCurrentLanguageCode } from '../i18n';
import { getServerError } from './error-service';
import { Flight, FlightInfo, FlightSegment, FlightShortInfo } from './flight-service.types';
import { Person, PersonParams } from './user-service.types';

export const createFlight = (flight: Flight) =>
    axios.post<{ accessToken: string }>('/api/flight', flight)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

export const deleteFlight = (flightId: number) =>
    axios.delete<{ accessToken: string }>(`/api/flight/${flightId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

export const addSegment = (flightId: number, segment: FlightSegment) =>
    axios.post<{ id: number }>(`/api/flight/${flightId}/segment`, segment)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

export const deleteSegment = (segmentId: number) =>
    axios.delete<{ accessToken: string }>(`/api/flight/segment/${segmentId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });


export const getList = (lang: string = getCurrentLanguageCode()) =>
    axios.get<FlightShortInfo[]>('/api/flight', { params: { lang } })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

export const getById = (id: number, lang: string = getCurrentLanguageCode()) =>
    axios.get<FlightInfo>(`/api/flight/${id}`, { params: { lang } })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });


export const addFellow = (flightId: number, fellow: PersonParams) =>
    axios.post<{ id: number }>(`/api/flight/${flightId}/person`, fellow)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

export const addFellowById = (flightId: number, fellowId: number) =>
    axios.post<{ id: number }>(`/api/flight/${flightId}/person`, { id: fellowId })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

export const deleteFellow = (flightId: number, personId: number) =>
    axios.delete<{ accessToken: string }>(`/api/flight/${flightId}/person/${personId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

export const getFellowList = (segmentId: number) =>
    axios.get<Person[]>(`/api/flight/segment/${segmentId}/person`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

export const getListRequirement = (flightId: number, segmentId: number) =>
    axios.get(`/api/flight/${flightId}/segment/${segmentId}/pass-group`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

export const getListRequirementByCountry = (countryId: number) =>
    axios.get(`/api/country/${countryId}/pass-group`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

export const getListChangedRequirementByCountry = (ucId: number) =>
    axios.get(`/api/country/update/${ucId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error, 'SegmentDetails');
        });

export const getListStepsForRequirementGroup = (groupId: number) =>
    axios.get(`/api/pass-group/${groupId}/pass-step`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

export const getSegmentById = (segmentId: number, lang: string = getCurrentLanguageCode()) =>
    axios.get(`/api/flight/segment/${segmentId}`, { params: { lang } })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });
