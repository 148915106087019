import axios from 'axios';
import { getServerError } from './error-service';

// Beginning of passing the questionnaire, receiving the first question
export const startQuestionnaire = (groupId: number) =>
    axios.post(`/api/pass-group/${groupId}/start`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

// Sending an answer to a question
export const setAnswerToQuestion = (groupId: number, stepId: number, answer: boolean) =>
    axios.post(`/api/pass-group/${groupId}/pass-step/${stepId}/answer`, { answer })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });
