import React from 'react';
import { Switch } from 'react-router-dom';
import Home from './features/home/Home';
import WelcomeSignUp from './features/welcome/WelcomeSignUp';
import ChangePassword from './features/change-password/ChangePassword';
import ConfirmEmail from './features/confirm-email/ConfirmEmail';
import Profile from './features/profile/Profile';
import Sandbox from './features/sandbox/Sandbox';
import PrivateRoute from './components/route/PrivateRoute';
import PublicRoute from './components/route/PublicRoute';
import ModalRoute from './components/route/ModalRoute';
import ModalSwitch from './components/route/ModalSwitch';
import SignUpDialog from './features/modal/SignUpDialog';
import SignInDialog from './features/modal/SignInDialog';
import ResetPasswordDialog from './features/modal/ResetPasswordDialog';
import ChangeEmailDialog from './features/modal/ChangeEmailDialog';
import EditPersonDialog from './features/modal/EditPersonDialog';
import ConfirmPhoneNumberDialog from './features/modal/ConfirmPhoneNumberDialog';
import ChangePhoneDialog from './features/modal/ChangePhoneDialog';
import EditDocumentDialog from './features/modal/EditDocumentDialog';
import { useSession } from './components/auth/SessionProvider';
import SuccessfulRegTest from './components/order-test/SuccessfulRegTest';
import ReceptionCouponDialog from './features/modal/ReceptionCouponDialog';
import SignUpDoneDialog from './features/modal/SignUpDoneDialog';
import ConfirmRecoveryCode from './components/auth/ConfirmRecoveryCode';

const App: React.FC = () => {
    const { user } = useSession();

    if (user && !user.phone) {
        return (
            <ConfirmPhoneNumberDialog />
        );
    }

    if (user && !user.person) {
        return (
            <EditPersonDialog mode='signup' />
        );
    }

    return (
        <React.Fragment>
            <Switch>
                <ModalRoute restricted={true} component={WelcomeSignUp} path="/welcome/:code" exact />
                <PublicRoute restricted={false} component={ConfirmEmail} path="/confirm-email/:code" exact />
                <PublicRoute restricted={false} component={ConfirmRecoveryCode} path="/confirm-recovery/:recoveryCode" exact />
                <PublicRoute restricted={false} component={ChangePassword} path="/change-password/:code" exact />
                <PrivateRoute component={Profile} path="/profile" anonimComponent={SignInDialog} disableClose={true} exact />
                <PublicRoute restricted={false} component={Sandbox} path="/sandbox" exact />
                <PrivateRoute component={SuccessfulRegTest} anonimComponent={SignInDialog} disableClose={true} path="/order-test/successful" exact />
                <PublicRoute component={Home} anonimComponent={SignInDialog} disableClose={true} path={["/", "/country/:countryId", "/order-test/:segmentId/:groupId", "/blog/:articleId", "/uc/:ucId"]} exact />
            </Switch>

            <ModalSwitch>
                <ModalRoute component={SignUpDialog} path="#signup" exact />
                <ModalRoute component={SignInDialog} path="#signin" exact />
                <ModalRoute component={ResetPasswordDialog} path="#reset-password" exact />
                <ModalRoute component={ConfirmPhoneNumberDialog} path="#confirm-phone" exact />
                <ModalRoute component={ChangeEmailDialog} path="#change-email" exact />
                <ModalRoute component={ChangePhoneDialog} path="#change-phone" exact />
                <ModalRoute component={EditDocumentDialog} path="#bind-document" exact />
                <ModalRoute component={EditPersonDialog} path="#edit-account" exact />
                <ModalRoute component={ReceptionCouponDialog} path="#reception-coupon/:groupId" exact />
                <ModalRoute component={SignUpDoneDialog} path="#sign-up-done" exact />
            </ModalSwitch>

        </React.Fragment>
    );
};

export default App;
