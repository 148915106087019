import React from 'react';
import { Button, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomDialog from '../mui-extensions/CustomDialog';
import { useTranslation } from 'react-i18next';
import AboutRulesButtonIcon from '../icons/AboutRulesButtonIcon';


// DIALOG TITLE ABOUT RULES
const useStyles = makeStyles((theme) => ({
    aboutRulesContent: {
        marginTop: -8,
        marginBottom: 30
    },
    aboutRulesP: {
        lineHeight: 1.3,
        marginTop: 8,
        marginBottom: 16,
        fontWeight: 400,
    },
    aboutRulesUL: {
        fontSize: 14,
        fontWeight: 400,
        paddingLeft: 30,
        marginTop: 8,
        marginBottom: 8,
    },
    aboutRulesA: {
        color: theme.palette.primary.main,
        fontWeight: 400,
    },
    aboutRulesButton: {
        backgroundColor: '#3AC582',
        color: theme.palette.text.secondary,
        height: 46,
        textTransform: 'uppercase',
        padding: '16px 28px',
        "&:hover": {
            backgroundColor: '#3AB082',
            boxShadow: 'none',
        },
        [theme.breakpoints.down(670)]: {
            marginRight: 0,
            width: '100%',
        },
    },
    aboutRulesButtonIcon: {
        fontSize: 22,
        height: 22,
        width: 22,
        marginTop: 8,
        marginRight: -7,
    },
}));


export default function AboutRulesDialog(props: any) {
    const classes = useStyles();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`Title.${key}`, options);

    return (
        <CustomDialog onClose={props.handleDialogClose} open={props.openDialog} >

            {/* BODY DIALOG */}
            <DialogContent >
                <DialogTitle>{tt('aboutRulesTitle')}</DialogTitle>

                <Grid container className={classes.aboutRulesContent}>
                    <Typography component="p" className={classes.aboutRulesP}>
                        {tt('aboutRulesText1')}
                    </Typography>
                    <Typography component="p" className={classes.aboutRulesP}>
                        {tt('aboutRulesText2')}
                    </Typography>
                </Grid>

                <Button
                    onClick={props.handleDialogClose}
                    className={classes.aboutRulesButton}
                    startIcon={<AboutRulesButtonIcon className={classes.aboutRulesButtonIcon} />}
                >
                    {tt('aboutRulesButton')}
                </Button>

            </DialogContent>

        </CustomDialog>
    );
}
