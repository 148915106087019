import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function TransitSegmentCardIcon(props: any) {
    return (
        <SvgIcon {...props}>
            <svg width="29" height="29" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle opacity="0.23" cx="14.5" cy="14.5" r="14.5" fill="#C3D1F4" />
                <path fillRule="evenodd" clipRule="evenodd" d="M17.1414 8.71204C17.3717 8.48168 17.7452 8.48168 17.9756 8.71204L21.3182 12.0547C21.5486 12.285 21.5486 12.6585 21.3182 12.8889L17.9756 16.2315C17.7452 16.4619 17.3717 16.4619 17.1414 16.2315C16.911 16.0011 16.911 15.6276 17.1414 15.3973L19.477 13.0616H13.626C13.3002 13.0616 13.0361 12.7975 13.0361 12.4718C13.0361 12.146 13.3002 11.8819 13.626 11.8819L19.477 11.8819L17.1414 9.54625C16.911 9.31589 16.911 8.9424 17.1414 8.71204Z" fill="#3766D9" />
                <path fillRule="evenodd" clipRule="evenodd" d="M15.6992 17.477C15.6992 17.8028 15.4351 18.0669 15.1093 18.0669H9.25827L11.5939 20.4025C11.8243 20.6329 11.8243 21.0064 11.5939 21.2367C11.3635 21.4671 10.9901 21.4671 10.7597 21.2367L7.41708 17.8941C7.18672 17.6638 7.18672 17.2903 7.41708 17.0599L10.7597 13.7173C10.9901 13.4869 11.3635 13.4869 11.5939 13.7173C11.8243 13.9477 11.8243 14.3211 11.5939 14.5515L9.25827 16.8871L15.1093 16.8871C15.4351 16.8871 15.6992 17.1512 15.6992 17.477Z" fill="#3766D9" />
            </svg>
        </SvgIcon >
    );
}