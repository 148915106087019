import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

export interface CharFieldGroupProps {
    n: number;
    onChanged?: (value: string) => void;
}

export interface CharFieldGroupHandle {
    focus: () => void;
    clear: () => void;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textField: {
        display: 'inline-block',
        margin: theme.spacing(1.25),
        width: 37,
    },
    input: {
        textAlign: 'center',
        padding: 0,
    },
}));

const CharFieldGroup: React.ForwardRefRenderFunction<CharFieldGroupHandle, CharFieldGroupProps> = ({ n, onChanged, ...props }, ref) => {
    const classes = useStyles();
    const itemsRef = useRef<HTMLInputElement[]>([]);

    useImperativeHandle(ref, () => ({

        focus() {
            itemsRef.current[0].focus();
        },
        clear() {
            itemsRef.current.forEach((el) => {
                el.value = '';
            });
            itemsRef.current[0].focus();
        }

    }));

    const handleChange = (e: React.ChangeEvent) => {
        const index = parseInt(e.target.id);
        if (itemsRef.current[index].value) {
            if (index + 1 < n) {
                itemsRef.current[index + 1].focus();
            }
        } else {
            if (index > 0) {
                //itemsRef.current[index - 1].focus();
            }
        }

        let value = '';
        itemsRef.current.forEach((el) => {
            value = value + el.value;
        });

        onChanged && onChanged(value);
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        const input = e.currentTarget.querySelector('input') as HTMLInputElement;
        if (input.value === e.key) {
            input.value = '';
        }

        if (e.key !== 'Backspace') //Backspace//Delete
            return;

        const index = parseInt(input.id);
        if (index > 0 && !input.value) {
            //itemsRef.current[index - 1].selectionStart = itemsRef.current[index - 1].value.length;
            itemsRef.current[index - 1].focus();
        }
    };

    const handleFocus = (e: React.FocusEvent) => {
        const input = e.currentTarget as HTMLInputElement;
        setTimeout(function () {
            input.selectionStart = input.value.length;
            input.setSelectionRange(0, input.value.length);
        }, 1);

    };

    const handleMouseDown = (e: React.MouseEvent) => {
        const input = e.currentTarget.querySelector('input') as HTMLInputElement;
        if (((input.selectionEnd || 0) - (input.selectionStart || 0)) > 0) {
            e.preventDefault();
            input.focus();
        }
    };

    const handlePaste = (e: React.ClipboardEvent) => {
        const paste = e.clipboardData.getData('text');
        const pasteValue = paste.replace(/[^\d]/g, '');

        let value = '';
        itemsRef.current.forEach((el, i) => {
            el.value = pasteValue[i] || '';
            value = value + el.value;
        });

        onChanged && onChanged(value);
    };

    return (
        <div className={classes.root}>
            {[...Array(n)].map((e, index) =>
                <TextField
                    inputRef={el => itemsRef.current[index] = el}
                    onChange={handleChange}
                    key={index}
                    id={index.toString()}
                    className={classes.textField}
                    inputProps={{ maxLength: 1, className: classes.input, inputMode: 'numeric' }}
                    onKeyDown={handleKeyDown}
                    onPaste={handlePaste}
                    onFocus={handleFocus}
                    onMouseDown={handleMouseDown}
                    variant="outlined"
                    margin="normal"
                    required
                />

            )}
        </div>
    );
};

export default forwardRef(CharFieldGroup);