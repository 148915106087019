import React from 'react';
import { useHistory } from 'react-router-dom';
import { DialogTitle, DialogContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditPerson from '../../components/profile/EditPerson';
import { useSession } from '../../components/auth/SessionProvider';
import { useSnackbar } from 'notistack';
import CustomDialog from '../../components/mui-extensions/CustomDialog';

export default function EditPersonDialog(props: any) {
    const { t } = useTranslation();
    const history = useHistory();
    const [open, setOpen] = React.useState(true);

    const { user } = useSession();

    const { enqueueSnackbar } = useSnackbar();

    const isAlvaisShow = !user?.person;// Нельзя закрыть диалог если не закончена регистрация  

    const handleClose = (isCompleted: boolean) => {
        if (!isCompleted && isAlvaisShow)
            return;

        setOpen(false);
        history.replace({
            hash: undefined
        });
    };

    const handleCloseByUser = () => handleClose(false);

    const handleCloseCompleted = () => {
        handleClose(true);
        if (!isAlvaisShow) onChangedSuccessfully();
        if (props.mode === 'signup') history.push('#sign-up-done');;
    };

    const onChangedSuccessfully = () => {
        enqueueSnackbar(t('EditPerson.successTitle'), { variant: 'success' });
    };

    return (
        <React.Fragment>
            <CustomDialog maxWidth="xs" onClose={handleCloseByUser} open={open}>
                <DialogTitle>
                    {t('EditPerson.dialogTitle')}
                </DialogTitle>
                <DialogContent>
                    <EditPerson onCompleted={handleCloseCompleted} />
                </DialogContent>
            </CustomDialog>
        </React.Fragment>
    );
}