import enAnswer1 from '../../content/en/FAQ/item1.md';
import enAnswer2 from '../../content/en/FAQ/item2.md';
import enAnswer3 from '../../content/en/FAQ/item3.md';
import enAnswer4 from '../../content/en/FAQ/item4.md';
import ruAnswer1 from '../../content/ru/FAQ/item1.md';
import ruAnswer2 from '../../content/ru/FAQ/item2.md';
import ruAnswer3 from '../../content/ru/FAQ/item3.md';
import ruAnswer4 from '../../content/ru/FAQ/item4.md';
import matter from 'gray-matter';


export const data = {
    en: [
        matter(enAnswer1),
        matter(enAnswer2),
        matter(enAnswer3),
        matter(enAnswer4),
    ],
    ru: [
        matter(ruAnswer1),
        matter(ruAnswer2),
        matter(ruAnswer3),
        matter(ruAnswer4),
    ],
} as any;
