import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ReactComponent as NoFlightIcon } from '../../icons/NoFlightIcon.svg';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { getCurrentLanguageCode } from '../../../i18n';
import * as infoService from '../../../api/info-service';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '75px'
    },
    h2: {
        fontSize: '19px',
        marginBottom: '18px',
        marginTop: '16px',
    },
    body1: {
        fontWeight: 400,
        lineHeight: '100%',
    },
    contentColumn: {
        maxWidth: '480px',
    },
    form: {
        marginTop: theme.spacing(3),
    },
    autocomplete: {
        width: '260px',
        marginRight: theme.spacing(1.5),
    }
}));

export default function NoFlight(props: any) {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`NoFlight.${key}`, options);

    const [countryList, setCountryList] = React.useState([]);
    const [selectedCountry, setSelectedCountry] = React.useState<any>();

    // Get country List
    React.useEffect(() => {
        const language = getCurrentLanguageCode();
        infoService.getCountryList(language)
            .then((items) => {
                setCountryList(items);
            })
            .catch(() => { });
    }, [t]);

    const goToCountry = () => {
        if (!selectedCountry)
            return;

        history.push(`/country/${selectedCountry?.id}`);

    };

    return (
        <Grid container className={classes.root} justify="center" alignItems="flex-start" spacing={4}>
            <Grid item>
                <NoFlightIcon />
            </Grid>
            <Grid item className={classes.contentColumn}>
                <Typography variant="h2" className={classes.h2}>{tt('title')}</Typography>
                <Typography variant="body1" className={classes.body1}>{tt('message')}</Typography>

                <Grid container className={classes.form}>
                    <Grid item>
                        <Autocomplete
                            className={classes.autocomplete}
                            filterOptions={createFilterOptions({ matchFrom: 'start', trim: true })}
                            value={selectedCountry}
                            options={countryList}
                            onChange={(e: any, value: any) => {
                                setSelectedCountry(value);
                            }}
                            autoHighlight
                            getOptionLabel={(option: any) => (option.name ? option.name : "")}
                            getOptionSelected={(option: any, value: any) => option.key === value.id}
                            renderOption={(option: any) => (
                                <React.Fragment>
                                    {option.name}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={tt('countryPlaceholder')}
                                    name="countryName"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            type="button"
                            onClick={goToCountry}
                            variant="contained"
                            color="primary"
                            disabled={!selectedCountry}
                        >
                            {tt('submitBtnText')}
                        </Button>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    );
}