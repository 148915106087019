import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ButtonCloseIcon(props: any) {
    return (
        <SvgIcon {...props}>
            <g opacity="0.3">
                <path d="M12.6826 11.8151L17.9337 17.0662M23.1848 22.3173L17.9337 17.0662M17.9337 17.0662L12.6826 22.3173L23.1848 11.8151" stroke="white" strokeWidth="1.31278" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </SvgIcon>
    );
}