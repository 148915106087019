import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../../components/layout/Layout';
import { DialogContent, DialogTitle, Typography, TextField, Link } from '@material-ui/core';
import { verificationRecoveryCode } from '../../api/user-service';
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { ServerError } from '../../api/error-service';
import CustomDialog from '../mui-extensions/CustomDialog';
import Form from '../form/Form';
import * as Yup from 'yup';
import CustomNumberFormat from '../mui-extensions/CustomNumberFormat';
import { useFormik } from 'formik';
import RecoveryButton from './RecoveryButton';

const useStyles = makeStyles((theme) => ({
    root: {},
    telephoneInput: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    dialogText: {
        textAlign: 'justify',
        marginTop: theme.spacing(1)
    },
}));

export default function ConfirmRecoveryCode() {
    const [validationEnabled] = React.useState(false);
    const [serverError, setServerError] = React.useState<ServerError>();
    const { recoveryCode } = useParams<{ recoveryCode: string }>();
    const history = useHistory();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`ConfirmRecoveryCode.${key}`, options);

    const { values, touched, errors, ...formik } = useFormik({
        validateOnChange: validationEnabled,
        validateOnBlur: validationEnabled,
        initialValues: {
            phone: ''
        },
        validationSchema: Yup.object({
            phone: Yup.string()
                .required(t('reuiredFieldValidationMessage', { fieldName: t('phoneNumberLabel') }))
                .transform(value => value.replace(/[^\d]/g, ''))
                .min(11, t('phoneFormatValidationMessage')),
        }),
        onSubmit: () => {
        },
    });

    useEffect(() => {
        verificationRecoveryCode(recoveryCode)
            .then(() => {
                enqueueSnackbar(tt('successfullMessage'), { variant: 'success' });
                history.push("/#signin");
            })
            .catch((err: ServerError) => {
                setServerError(err);
            });
    }, [recoveryCode]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Layout>
            <CustomDialog
                maxWidth="xs"
                open={!!serverError && (serverError.reason === 'confirmation_code_not_found' || serverError.reason === 'confirmation_code_expired')}
                onClose={() => { history.push("/"); }}
            >
                <DialogTitle>{t('error')}</DialogTitle>
                <DialogContent>
                    {serverError?.displayMessage}
                    <Typography variant='body1' className={classes.dialogText}>
                        {tt('recoveryDialogText')}
                        <br />
                        {tt('recoveryDialogSupportText')}
                        <Link
                            style={{ 'cursor': 'pointer' }}
                            onClick={() => { if (jivo_api.open().opened) document.body.classList.add('jivo-chat-active'); }}>
                            {tt('supportLinkText')}
                        </Link>
                    </Typography>
                    <Form>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            autoFocus
                            autoComplete='off'
                            label={t('phoneNumberLabel')}
                            name="phone"
                            className={classes.telephoneInput}
                            value={values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={touched.phone && Boolean(errors.phone)}
                            helperText={touched.phone && errors.phone}
                            InputProps={{
                                inputComponent: CustomNumberFormat,
                            }}
                            inputProps={{
                                inputMode: 'tel',
                                format: '+7 (###) ###-##-##'
                            }}
                        />
                        <RecoveryButton phone={values.phone} formik={formik} />
                    </Form>
                </DialogContent>
            </CustomDialog>

        </Layout>
    );
}