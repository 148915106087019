import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import WarningIcon from '../icons/WarningIcon';
import { formatDataTime, getPassinTimeForTest } from '../../helpers/datetimeHelper';
import { Button } from '@material-ui/core';
import TitileArrowButtonIcon from '../icons/TitileArrowButtonIcon';
import AboutRulesDialog from './AboutRulesDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 0,
    },
    titleWrap: {
        padding: '13px 10px 10px 0px',
        marginBottom: 10,
        overflow: 'hidden',
    },
    titleDateInfoWrap: {
        overflow: 'hidden',
        marginBottom: 32,
        padding: '0px 0px 0px 0px',
        fontWeight: 700,
    },
    titleDateInfoIcon: {
        padding: '0px 0px 0px 0px',
        fontSize: 32,
        marginRight: 10,
        marginTop: 0,
    },
    titleDateInfo: {
        padding: '0px 0px 0px 0px',
        lineHeight: 1.1,
        fontWeight: 700,
    },
    mainAboutRulesButton: {
        padding: '0px 0px 0px 0px',
        height: 28,
        fontWeight: 400,
        color: theme.palette.primary.main,
        fontSize: 14,
    },
    titileArrowButtonIcon: {
        height: 26,
        width: 26,
        marginTop: 16,
        marginLeft: -5
    },
}));



export default function Title(props: any) {
    const classes = useStyles();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`Title.${key}`, options);
    const spareHoursBeforeDeparture = 4;
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);

    return (
        <Grid item container md={12} justify="space-between" className={classes.root}>

            {/* Title */}
            <Grid item className={classes.titleWrap}>
                <Typography component="h2" variant="h1" gutterBottom>{tt('title')}</Typography>
            </Grid>

            {/* Date info */}
            {props.segment?.id && props.readyTimeHoursForTitle &&
                props.countryPassSteps[0].passResultValidHours && props.countryPassSteps[0].passResultCountdownTime &&
                <Grid item className={classes.titleDateInfoWrap}>
                    <Grid item container>

                        <Grid item >
                            <WarningIcon className={classes.titleDateInfoIcon} />
                        </Grid>

                        <Grid item >
                            <Typography component="p" className={classes.titleDateInfo}>{tt('dateInfo')}</Typography>
                            <Typography component="p" className={classes.titleDateInfo}>
                                {tt('from')}
                                {formatDataTime(
                                    getPassinTimeForTest('from', props.countryPassSteps[0].passResultCountdownTime, props.segment, props.readyTimeHoursForTitle,
                                        props.countryPassSteps[0].passResultValidHours))
                                }
                                {tt('to')}
                                {formatDataTime(
                                    getPassinTimeForTest('to', props.countryPassSteps[0].passResultCountdownTime, props.segment, props.readyTimeHoursForTitle,
                                        props.countryPassSteps[0].passResultValidHours, spareHoursBeforeDeparture))
                                }
                            </Typography>
                            <Button className={classes.mainAboutRulesButton}
                                endIcon={<TitileArrowButtonIcon className={classes.titileArrowButtonIcon} />}
                                onClick={() => setOpenDialog(true)}
                            >
                                {tt('mainAboutRulesButton')}

                            </Button>
                        </Grid>

                    </Grid>
                </Grid>
            }


            {/* DIALOG WITH INFO */}
            <AboutRulesDialog
                openDialog={openDialog}
                handleDialogClose={() => setOpenDialog(false)}
            />

        </Grid>
    );
}