import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Box, IconButton, Typography } from '@material-ui/core';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import FlyIcon from '../../icons/FlyIcon';
import * as datetimeHelper from '../../../helpers/datetimeHelper';


const useStyles = makeStyles(() => ({
    root: {
        overflow: 'hidden',
        color: "#1C2C5E",
        border: '1px solid #E8EAEF',
        padding: '20px 0px 20px 20px',
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 6
    },
    infoTextHidden: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        maxWidth: 310,
        width: '100%',
    },
    infoTextDataTime: {
        marginTop: 10,
        lineHeight: 1.2
    },
    iconThreeDots: {
        fontSize: 24,
    },
}));


export default function SegmentList(props: any) {
    const classes = useStyles();

    return (
        <Grid container className={classes.root} justify="space-between" wrap="nowrap">


            {/* Block info  */}
            <Grid item container alignItems="stretch">


                {/* Slot FROM */}
                <Grid item xs={4}>
                    <Typography component="p" variant="body2" className={classes.infoTextHidden}>
                        {props.segment.departure.airport.city.name}
                    </Typography>

                    <Grid container wrap="nowrap">
                        <Grid item className={classes.infoTextHidden}>
                            <Typography component="p" variant="body1" className={classes.infoTextHidden}>
                                {props.segment.departure.airport.name}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography component="p" variant="body1">
                                ({props.segment.departure.airport.code})
                            </Typography>
                        </Grid>
                    </Grid>

                    <Typography component="p" variant="body1" className={classes.infoTextDataTime}>
                        {datetimeHelper.formatDataTime(props.segment.departure.at)}
                    </Typography>
                </Grid>


                {/* Slot NUMBER */}
                <Grid container item xs={3} alignItems="center" >
                    <Grid item container justify="center">
                        <FlyIcon />
                    </Grid>

                    <Grid item container justify="center">
                        <Box mt={-2.5}>
                            <Typography component="p" variant="body2" className={classes.infoTextHidden}>
                                {props.segment.flightNumber}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>


                {/* Slot TO */}
                <Grid item xs={4}>
                    <Typography component="p" variant="body2" className={classes.infoTextHidden}>
                        {props.segment.arrival.airport.city.name}
                    </Typography>

                    <Grid container wrap="nowrap">
                        <Grid item className={classes.infoTextHidden}>
                            <Typography component="p" variant="body1" className={classes.infoTextHidden}>
                                {props.segment.arrival.airport.name}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography component="p" variant="body1">
                                ({props.segment.arrival.airport.code})
                            </Typography>
                        </Grid>
                    </Grid>

                    <Typography component="p" variant="body1" className={classes.infoTextDataTime}>
                        {datetimeHelper.formatDataTime(props.segment.arrival.at)}
                    </Typography>
                </Grid>


            </Grid>


            {/* Block three dots icon */}
            <Box mt={-1} ml={-5}>
                <Grid item>
                    <IconButton
                        aria-label="close icon"
                        aria-describedby={props.id}
                        onClick={(event: any) => { props.handlerShowDeletePopper(props.segment, event); }}>
                        <MoreVertOutlinedIcon className={classes.iconThreeDots} />
                    </IconButton>
                </Grid>
            </Box>

        </Grid>

    );
}