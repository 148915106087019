import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as userService from '../../api/user-service';
import { AlertTitle } from '@material-ui/lab';
import { ServerError } from '../../api/error-service';
import Form from '../form/Form';

export interface ResetPasswordResult {
    email: string;
}

export interface ResetPasswordProps {
    onCompleted?: (result: ResetPasswordResult) => void;
}

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(2.5, 0, 0),
    },
}));

export default function ResetPassword({ onCompleted }: ResetPasswordProps) {
    const classes = useStyles();

    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`ResetPassword.${key}`, options);

    const [serverError, setServerError] = React.useState<ServerError>();
    const [sentAtUtc, setSentAtUtc] = React.useState<Date>();
    const [validationEnabled, setValidationEnabled] = React.useState(false);

    const { values, touched, errors, ...formik } = useFormik({
        validateOnChange: validationEnabled,
        validateOnBlur: validationEnabled,
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email(t('emailFormatValidationMessage'))
                .required(t('reuiredFieldValidationMessage', { fieldName: t('emailLabel') })),
        }),
        onSubmit: async ({ email }) => {
            setServerError(undefined);
            return userService.resetPassword(email)
                .then(() => {
                    setSentAtUtc(new Date());
                    onCompleted && onCompleted({ email });
                })
                .catch((err: ServerError) => {
                    setServerError(err);
                });
        },
    });

    const handleSubmit: React.FormEventHandler = (e) => {
        e.preventDefault();
        setValidationEnabled(true);
        formik.submitForm();
    };


    return (
        <Form onSubmit={handleSubmit}>
            <TextField
                margin="normal"
                required
                fullWidth
                label={t('emailLabel')}
                name="email"
                autoFocus
                disabled={!!sentAtUtc}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={values.email}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
            />
            <Button
                type="button"
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                color="primary"
                startIcon={formik.isSubmitting && <CircularProgress size={14} />}
                disabled={formik.isSubmitting || !!sentAtUtc}
                className={classes.submit}
            >
                {tt('submitBtn')}
            </Button>
            {sentAtUtc &&
                <Box mt={2}>
                    <Alert severity="success">
                        <AlertTitle>{tt('successTitle')}</AlertTitle>
                        {tt('successMessage')}
                    </Alert>
                </Box>
            }
            {serverError &&
                <Box mt={2}>
                    <Alert severity="error">
                        <AlertTitle>{t('error')}</AlertTitle>
                        {serverError.displayMessage}
                    </Alert>
                </Box>
            }
        </Form>
    );
}