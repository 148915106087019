import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Title from '../../components/order-test/Title';
import SelectType from '../../components/order-test/SelectType';
import TypeContainer from '../../components/order-test/TypeContainer';
import { useHistory, useParams } from 'react-router-dom';
import { useSession } from '../../components/auth/SessionProvider';
import BackButton from '../../components/layout/BackButton';
import * as flightService from '../../api/flight-service';
import { FlightInfo } from '../../api/info-service.types';
import { CountryPassStep } from '../../api/flight-service.types';

const useStyles = makeStyles((theme) => ({
    root: {},
}));


export default function OrderTest(props: any) {
    const { user } = useSession();
    const history = useHistory();
    if (!user) { history.push('/#signin'); }

    const classes = useStyles();

    const [testTypeChoice, setTestTypeChoice] = React.useState('typeLab');
    const [passTypeIdArray, setPassTypeIdArray] = React.useState([] as any);
    const [countryPassSteps, setCountryPassSteps] = React.useState<CountryPassStep[]>();
    const [segment, setSegment] = React.useState<FlightInfo>();
    const [readyTimeHoursForTitle, setReadyTimeHoursForTitle] = React.useState();

    const switchOrderTypeTest = (testType: string) => {
        setTestTypeChoice(testType);
    };

    let { segmentId } = useParams<{ segmentId: any }>();
    let { groupId } = useParams<{ groupId: any }>();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Get a list of steps for this card, and get a list of requirements into an array
    useEffect(() => {
        flightService.getListStepsForRequirementGroup(groupId)
            .then((res) => {
                setPassTypeIdArray(res.map((item: any) => item.passTypeId));
                setCountryPassSteps(res);
            })
            .catch((e) => console.log(e));
    }, [groupId]);

    // Get information about the selected segment
    useEffect(() => {
        flightService.getSegmentById(segmentId)
            .then((res) => {
                setSegment(res);
            })
            .catch((e) => console.log(e));
    }, [segmentId]);

    return (
        <Grid container className={classes.root}>

            <BackButton />

            {/* Title & Date info */}
            <Title
                segment={segment}
                countryPassSteps={countryPassSteps}
                readyTimeHoursForTitle={readyTimeHoursForTitle}
            />

            {/* Radio button - choies type test */}
            <SelectType switchOrderTypeTest={switchOrderTypeTest} />

            {/* Render of the selected type */}
            {passTypeIdArray.length > 0 &&
                <TypeContainer
                    testTypeChoice={testTypeChoice}
                    passTypeIdArray={passTypeIdArray}
                    segmentId={segmentId}
                    segment={segment}
                    countryPassSteps={countryPassSteps}
                    setReadyTimeHoursForTitle={setReadyTimeHoursForTitle}
                />}

        </Grid >
    );


}