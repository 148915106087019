import enIcon1 from '../../content/en/landing/icon-cards/item1.md';
import enIcon2 from '../../content/en/landing/icon-cards/item2.md';
import enIcon3 from '../../content/en/landing/icon-cards/item3.md';
import ruIcon1 from '../../content/ru/landing/icon-cards/item1.md';
import ruIcon2 from '../../content/ru/landing/icon-cards/item2.md';
import ruIcon3 from '../../content/ru/landing/icon-cards/item3.md';
import { ReactComponent as LandingIcon1 } from '../icons/Landing1.svg';
import { ReactComponent as LandingIcon2 } from '../icons/Landing2.svg';
import { ReactComponent as LandingIcon3 } from '../icons/Landing3.svg';


export const data = {
    en: [
        {data: LandingIcon1, content: enIcon1},
        {data: LandingIcon2, content: enIcon2},
        {data: LandingIcon3, content: enIcon3},
    ],
    ru: [
        {data: LandingIcon1, content: ruIcon1},
        {data: LandingIcon2, content: ruIcon2},
        {data: LandingIcon3, content: ruIcon3},
    ],
} as any;
