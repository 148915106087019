import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import SegmentDetailsArrowIcon from '../../icons/SegmentDetailsArrowIcon';


const useStyles = makeStyles(() => ({
    root: {},
    flightNumber: {
        marginRight: 4,
    },
    itemChain: {
        height: 34,
        lineHeight: 2.1,
    },
    arrowFly: {
        marginBottom: -15,
        marginRight: -1,
        marginLeft: 4,
        width: 30,
        height: 26
    },
}));

export default function SegmentFlightChain(props: any) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Typography component="span" variant="h3" color="primary" className={classes.flightNumber}>{props.segment.flightNumber.toUpperCase()}:</Typography>

            {/* Show transit segments */}
            {props.transitSegmentList.length > 0 &&
                props.transitSegmentList.map((segment: any) =>
                    <Typography key={segment.id} component="span" variant="h3" className={classes.itemChain}>
                        {`${segment.departure.airport.city.name} ${segment.departure.airport.code}`}
                        <SegmentDetailsArrowIcon className={classes.arrowFly} />
                    </Typography>
                )
            }

            {/* Last segment */}
            <Typography component="span" variant="h3" className={classes.itemChain}>
                {`${props.segment.departure.airport.city.name} ${props.segment.departure.airport.code}`}
            </Typography>
            <SegmentDetailsArrowIcon className={classes.arrowFly} />
            <Typography component="span" variant="h3" className={classes.itemChain}>
                {`${props.segment.arrival.airport.city.name} ${props.segment.arrival.airport.code}`}
            </Typography>
        </React.Fragment>
    );
}