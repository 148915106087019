import React from 'react';
import { DialogContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomDialog from '../../mui-extensions/CustomDialog';
import ReqCardDialogButton from './ReqCardDialogButton';
import { useTranslation } from 'react-i18next';
import ReqCardTitle from './ReqCardTitle';
import clsx from 'clsx';


// DIALOG CARD
const useStyles = makeStyles((theme) => ({
    dialogContent: {
        padding: '36px 36px 26px 36px',
        [theme.breakpoints.down(670)]: {
            padding: '36px 18px 8px 18px',
        },
    },
    dialogContentStatusPassed: {
        backgroundColor: '#ECF9F3'
    },
    content: {
        marginTop: 20,
        '& p': {
            lineHeight: 1.3,
            marginTop: 8,
            marginBottom: 16,
            fontWeight: 400,
        },
        '& ul': {
            fontSize: 14,
            fontWeight: 400,
            paddingLeft: 30,
            marginTop: 8,
            marginBottom: 8,
        },
        '& a': {
            color: theme.palette.primary.main,
            fontWeight: 400,
        },
    },
    dialogButtons: {
        marginTop: 46
    },
}));


// DIALOG CARD
export default function ReqCardDialog({ handleDialogClose, openDialog, dialogContent, requirement, cardType, cardStatus, segmentId, flightId, requirementId, setCardStatus, showErrorDialog }: any) {
    const classes = useStyles();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`Cards.${key}`, options);

    return (
        <CustomDialog onClose={handleDialogClose} open={openDialog} PaperProps={{ style: { maxWidth: "776px", padding: 0 } }}>

            {/* BODY DIALOG */}
            <DialogContent className={clsx(classes.dialogContent, `${cardStatus === 'passed' ? classes.dialogContentStatusPassed : ''}`)}>
                <ReqCardTitle requirement={requirement} cardStatus={cardStatus} />
                <div className={classes.content} dangerouslySetInnerHTML={{ __html: dialogContent }} />

                {cardType === 'test' &&
                    <Grid container className={classes.dialogButtons}>
                        {(cardStatus !== 'passed' && cardStatus !== 'pending' && cardStatus !== 'not_requested' && cardStatus !== 'not_paid') &&
                            <ReqCardDialogButton
                                buttonText={tt('buttonDialogOrderTest')}
                                buttonType={'orderTest'}
                                cardStatus={cardStatus}
                                requirementId={requirementId}
                                segmentId={segmentId}
                                flightId={flightId}
                                setCardStatus={setCardStatus}
                                showErrorDialog={showErrorDialog}
                                errorDialogContent={{title: tt('dialogErrorTitleTest'), message: tt('dialogErrorMessageTest')}}
                            />
                        }
                        {segmentId && <ReqCardDialogButton
                            buttonText={tt('buttonDialogSetStatus')}
                            buttonTextAlt={tt('buttonDialogSetStatusAlt')}
                            buttonType={'setStatus'}
                            cardStatus={cardStatus}
                            requirementId={requirementId}
                            segmentId={segmentId}
                            flightId={flightId}
                            setCardStatus={setCardStatus}
                        />}
                    </Grid>
                }
            </DialogContent>

        </CustomDialog>
    );
}
