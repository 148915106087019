import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as flightService from '../../../api/flight-service';
import ButtonCloseIcon from '../../icons/ButtonCloseIcon';
import SegmentCard from './SegmentCard';
import { CircularProgress, ClickAwayListener, IconButton, Popper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TriangleIcon from '../../icons/TriangleIcon';
import { FlightSegmentDetails } from '../../../api/flight-service.types';


const useStyles = makeStyles((theme) => ({
    popperSegmentShow: {
        zIndex: 9,
        paddingTop: 23,
        paddingLeft: 5,
        paddingRight: 5,
        boxSizing: 'border-box',
        [theme.breakpoints.down(960)]: {
            paddingLeft: 14,
            paddingRight: 14,
        },
        [theme.breakpoints.down(420)]: {
            paddingLeft: 24,
            paddingRight: 24,
        },
    },
    rootGrid: {
        position: 'relative',
    },
    popperSegmentShowContent: {
        display: 'inline',
        float: 'left',
        overflow: 'hidden',
        padding: '22px 0px 0px 0px',
        borderRadius: 5,
        background: '#2C3972',
        boxShadow: '0px 2px 6px -2px rgba(0, 0, 0, 0.04);',
        [theme.breakpoints.down(670)]: {
            padding: '20px 0px 0px 0px',
        },
    },
    popperSegmentShowContentIsLoading: {
        display: 'inline',
        float: 'left',
        overflow: 'hidden',
        padding: '22px 100px 22px 100px',
        borderRadius: 5,
        background: '#2C3972',
        boxShadow: '0px 2px 6px -2px rgba(0, 0, 0, 0.04);',
    },
    popperSegmentTitle: {
        color: '#ffffff',
        marginTop: -3,
        marginBottom: 7,
        padding: '0px 16px 0px 16px',
        fontSize: 12,
        [theme.breakpoints.down(670)]: {
            marginTop: -2,
            marginBottom: 8,
        },
    },
    popperSegmentWrapItem: {
        paddingTop: 0,
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.down(960)]: {
            display: 'inline',
        },
    },
    buttonCloseIconWrap: {
        position: 'absolute',
        right: -5,
        top: 0,
        padding: 0,
        textAlign: 'center',
        paddingRight: 15,
        paddingBottom: 15,
        [theme.breakpoints.down(960)]: {
            right: -5,
            top: -5,
        },
        [theme.breakpoints.down(670)]: {
            right: 0,
            top: 0,
        },
    },
    buttonCloseIcon: {
        height: 24,
        width: 24,
    },
    triangleIcon: {
        position: 'absolute',
        top: '-12px',
        textAlign: 'center',
        [theme.breakpoints.down(960)]: {
            paddingRight: 20,
        },
        [theme.breakpoints.down(600)]: {
            paddingRight: 35,
        },
    }

}));


export default function SegmentsPopper({ open, anchorEl, onClickClose, idFlight, }: any) {

    const classes = useStyles();

    const [segmentsArray, setSegmentsArray] = React.useState<FlightSegmentDetails[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);

    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`SegmentsPopper.${key}`, options);

    const arrowRef = React.useRef(null);


    // Get an array of flight segments
    useEffect(() => {
        setIsLoading(true);
        if (open) {
            flightService.getById(idFlight)
                .then((response) => {
                    setSegmentsArray(response.segments);
                })
                .catch(() => { })
                .then(() => {
                    setIsLoading(false);
                });
        }
    }, [open, idFlight]);


    return (
        <Popper
            open={open}
            anchorEl={anchorEl}
            placement="bottom"
            disablePortal={false}
            className={classes.popperSegmentShow}
            popperOptions={{
                modifiers: {
                    preventOverflow: {
                        padding: 0
                    },
                    flip: {
                        enabled: false,
                    },
                    arrow: {
                        enabled: true,
                        element: arrowRef.current,
                    },
                }
            }}>

            <ClickAwayListener onClickAway={onClickClose} >

                <Grid className={classes.rootGrid}>

                    {/* Triangle icon */}
                    <span ref={arrowRef} className={classes.triangleIcon}>
                        <TriangleIcon />
                    </span>

                    {/* Segment loading icon */}
                    {isLoading && (
                        <Grid container className={classes.popperSegmentShowContentIsLoading}>
                            <CircularProgress size={32} />
                        </Grid>
                    )}


                    {!isLoading && (
                        <Grid container className={classes.popperSegmentShowContent}>

                            {/* Button to close the window with segments */}
                            <IconButton onClick={onClickClose} className={classes.buttonCloseIconWrap} >
                                <ButtonCloseIcon className={classes.buttonCloseIcon} />
                            </IconButton>


                            {/* Number of transfers */}
                            <Typography component="p" variant="body2" className={classes.popperSegmentTitle}>
                                {tt('countSegmentsTitle')}{segmentsArray.length - 1}
                            </Typography>


                            {/* Display of all flight segments in a cycle */}
                            <Grid
                                className={classes.popperSegmentWrapItem}>
                                {segmentsArray.map((segment, key) => (
                                    <Grid item key={key}>
                                        <SegmentCard {...segment} isLast={!segmentsArray[key + 1]} />
                                    </Grid>
                                ))}
                            </Grid>

                        </Grid>
                    )}

                </Grid>

            </ClickAwayListener>

        </Popper >
    );
}

