import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, Grid, Link, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@material-ui/icons/Check';
import * as userService from '../../api/user-service';
import { useSession } from '../auth/SessionProvider';
import clsx from 'clsx';

//Formatting for russian passport XX/XXXXXXX
function formatPassport(number: string) {
    if (number) {
        return number.replace(/(\d{2})(\d{7})/, "$1 / $2");
    }
    return null;
}

const useStyles = makeStyles((theme) => ({
    itemWrap: {
        border: '1px solid #E8EAEF',
        padding: '20px',
        borderRadius: 6,
        overflow: 'hidden',
        wordWrap: 'break-word',
        wordBreak: 'break-all',
        maxWidth: '300px',
    },
    infoText: {
        lineHeight: '100%',
        marginBottom: theme.spacing(1),
        width: '100%',
    },
    checkedIcon: {
        color: theme.palette.primary.main,
        width: 20,
        height: 20,
    },
    defaultDoc: {
        backgroundColor: '#F5F7FD',
    }
}));
export default function DocumentListItem(props: any) {
    const classes = useStyles();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`DocumentListItem.${key}`, options);
    const { user } = useSession();

    const handleSetDefaultDoc = () => {
        userService.setDefaultDocument(user?.person?.id || 0, props.item.id)
            .then(() => {
                props.getPersonDocumentList(user?.person?.id || 0);
            })
            .catch(() => { });
    };

    return (
        <Grid container className={clsx(classes.itemWrap, props.item.isDefault ? classes.defaultDoc : '')}>
            <Typography
                className={classes.infoText}
                component="h4"
                variant="body2"
                color="textPrimary">
                {props.item.type === 'passport' ? tt('nameDocRU') : tt('nameDocEN')}
            </Typography>
            <Typography
                className={classes.infoText}
                component="p"
                variant="body1"
                color="textPrimary">
                {props.item.type === 'passport' ? tt('passportNumberTitleRU') : tt('passportNumberTitleEN')} {props.item.type === 'passport' ? formatPassport(props.item.number) : `${props.item.number}`}
            </Typography>
            {props.item.isDefault
                ? <Chip label={tt('defaultDoc')} icon={<CheckIcon className={classes.checkedIcon} />} variant="outlined" />
                : <Link component="button" variant="body1" onClick={handleSetDefaultDoc}>
                    {tt('checkDefaultDoc')}
                </Link>
            }
        </Grid >
    );
}