import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import CustomMarkdown, {getComponent} from '../mui-extensions/CustomMarkdown';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '8px',
        paddingRight: '16px',
        marginBottom: theme.spacing(1),
    },
    iconColumn: {
        width: '64px',
        height: '64px',
        borderRadius: '32px',
        backgroundColor: '#F5F7FD',
        marginRight: theme.spacing(2),
    },
    title: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '100%',
    },
    firstWord: {
        fontWeight: 700,
    }
}));


export default function IconCard(props: any) {
    const classes = useStyles();

    const markdownOptions = {
        p: {
            component: getComponent(Typography),
            props: {
                className: classes.title,
                display: 'inline'
            }
        },
        strong : {
            component: getComponent(Typography),
            props: {
                className: clsx(classes.title, classes.firstWord),
                display: 'inline'
            }
        }
    };

    return (
        <Paper className={classes.root} elevation={0}>
            <Grid container justify="center" alignItems="center" className={classes.iconColumn}>
                <props.data/>
            </Grid>
            <div style={{ flex: 1, lineHeight: '100%' }}>
                <CustomMarkdown children={props.content} overrides={markdownOptions}/>
            </div>

        </Paper>
    );
}