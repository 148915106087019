import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, TextField, Grid, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';
import * as userService from '../../api/user-service';
import * as infoService from '../../api/info-service';
import { AlertTitle } from '@material-ui/lab';
import { ServerError } from '../../api/error-service';
import { useSession } from '../auth/SessionProvider';
import CheckIcon from '@material-ui/icons/Check';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getCurrentLanguageCode } from '../../i18n';
import CustomDatePicker from '../mui-extensions/CustomDatePicker';
import CustomNumberFormat from '../mui-extensions/CustomNumberFormat';
import { disableManualDateEntry } from '../../helpers/disableManualDateEntry';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Form from '../form/Form';

export interface EditDocumentProps {
    onCompleted?: () => void;
}

// Get current data and + 10 years from validation
function checkPassportExpiration(addYears: number = 0) {
    var today = new Date();
    return (
        today.getFullYear() + addYears + '-'
        + ("0" + (today.getMonth() + 1)).slice(-2) + '-'
        + ("0" + today.getDate()).slice(-2)
    );
}

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(1, 0, 0),
    },
    formControl: {
        width: "100%",
        margin: theme.spacing(2, 0, 1),
    },
    formControlSelectType: {
        width: "100%",
        margin: theme.spacing(2, 0, -1),
    },
    formControlOption: {
        cursor: 'pointer',
        padding: '8px 10px',
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    noOptions: {
        color: theme.palette.secondary.dark,
    },
    formCheckboxWrap: {
        lineHeight: 1,
        marginTop: '5px',
    },
    formCheckboxIcon: {
        margiTop: 0,
        marginLeft: 2,
    },
    formCheckboxIconDefault: {
        paddingTop: 7
    },
    icon: {
        borderRadius: 4,
        width: 20,
        height: 20,
        border: '1px solid rgba(28, 44, 94, 0.15)',
        backgroundColor: '#FFFFFF',
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#FFFFFF',
        width: 20,
        height: 20,
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
    },
}));

export default function EditDocument({ onCompleted }: EditDocumentProps, props: any) {
    const classes = useStyles();

    // Get user ID 
    const { user, setDocumentsIsUpdateHandler } = useSession();
    const userId = user?.person?.id || 0;

    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`EditDocument.${key}`, options);

    const [serverError, setServerError] = React.useState<ServerError>();
    const [validationEnabled, setValidationEnabled] = React.useState(false);
    const [sentAtUtc, setSentAtUtc] = React.useState<Date>();

    // Сountry List
    const [countryList, setCountryList] = React.useState([]);
    const [findRussianCountry, setFindRussianCountry] = React.useState([{ id: '', name: '' }]);

    const { values, touched, errors, setFieldValue, ...formik } = useFormik({
        validateOnChange: validationEnabled,
        validateOnBlur: validationEnabled,
        initialValues: {
            type: '',
            number: '',
            expiredAt: '',
            countryId: '',
            selectedCountryName: '',
            countryName: '',
            isDefault: false,
        },

        validationSchema: Yup.object({
            number: Yup.string()
                .required(tt('reuiredFieldValidationMessage', { fieldName: tt('passportLabel') }))
                .max(128, tt('passportIsTooLong')),

            expiredAt: Yup.date()
                .min(checkPassportExpiration(), tt('passportDataMin'))
                .max(checkPassportExpiration(10), tt('passportDataMax'))
                .required(tt('reuiredFieldValidationMessage', { fieldName: tt('passportDataLabel') })),

            countryId: Yup.number()
                .required(tt('reuiredFieldValidationMessage', { fieldName: tt('countryIssueLabel') })),
            countryName: Yup.string()
                .when('selectedCountryName', {
                    is: () => true,
                    then: Yup.string().oneOf(
                        [Yup.ref('selectedCountryName')],
                        tt('selectCountryFromListErrorMessage')
                    )
                }),
        }),

        onSubmit: async ({ type, number, expiredAt, countryId, isDefault }) => {

            setServerError(undefined);
            const document = {
                type,
                number: type === "passport" ? number.replace(/[^\d]/g, '') : number,
                expiredAt,
                countryId: parseInt(countryId, 10),
                isDefault,
            };

            return userService.createPersonDocument(userId, document)
                .then(() => {
                    setDocumentsIsUpdateHandler();
                    setSentAtUtc(new Date());
                    onCompleted && onCompleted();
                    values.type = '';
                    values.number = '';
                    values.expiredAt = '';
                    values.countryId = '';
                    values.selectedCountryName = '';
                    values.countryName = '';
                    values.isDefault = false;
                })
                .catch((err: ServerError) => {
                    setServerError(err);
                });
        },
    });

    // Get country List
    useEffect(() => {
        const language = getCurrentLanguageCode();
        infoService.getCountryList(language)
            .then((items) => {
                setCountryList(items);

                // Looking for Russia in the resulting list and save the result
                setFindRussianCountry(items.filter((country: any) => country.code === "RUS"));
            })
            .catch(() => { });
    }, []);


    const handleSubmit = () => {
        setValidationEnabled(true);
        formik.submitForm();
    };


    // Set the ID and NAME in the AUTOCOMPLATE from the state with the found country
    const handleSelectRusTypeDocument = () => {
        values.countryId = findRussianCountry[0].id;
        values.selectedCountryName = findRussianCountry[0].name;
        values.countryName = findRussianCountry[0].name;
    };

    // Clear AUTOCOMPLATE if you have chosen a different type of document
    const handleSelectOtherTypeDocument = () => {
        values.countryId = '';
        values.selectedCountryName = '';
        values.countryName = '';
    };

    return (
        <Form>
            <Box mt={-1.25}>
                <Grid container item spacing={1} justify="space-between">

                    {/* Select type document */}
                    <Grid xs={12} item>
                        <FormControl variant="filled" className={classes.formControlSelectType}>
                            <InputLabel id="type-passport-select-label">{tt('typeDocument')}</InputLabel>
                            <Select
                                labelId="type-passport-select-label"
                                id="type-passport-select-label-outlined"
                                value={values.type}
                                name="type"
                                onChange={formik.handleChange}
                                label={tt('typeDocument')}
                            >
                                <MenuItem id="rusPassportSelect" value={'passport'} onClick={handleSelectRusTypeDocument}>{tt('rusPassportSelect')}</MenuItem>
                                <MenuItem id="interPassportSelect" value={'international-passport'} onClick={handleSelectOtherTypeDocument}>{tt('interPassportSelect')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Field number document */}
                    <Grid sm={6} xs={12} item>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label={tt('passportLabel')}
                            name="number"
                            autoComplete='off'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled={!values.type}
                            value={values.number}
                            error={touched.number && Boolean(errors.number)}
                            helperText={touched.number && errors.number}
                            InputProps={values.type === "passport" ? {
                                inputComponent: CustomNumberFormat,
                            } : undefined}
                            inputProps={{
                                inputMode: 'numeric',
                                format: '## / #######'
                            }}
                        />
                    </Grid>

                    {/* Field data document */}
                    <Grid sm={6} xs={12} item>
                        <CustomDatePicker
                            margin="normal"
                            required
                            fullWidth
                            label={tt('passportDataLabel')}
                            name="expiredAt"
                            disabled={!values.type}
                            type="date"
                            onKeyDown={disableManualDateEntry}
                            autoComplete='off'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                max: "9999-12-31"
                            }}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={values.expiredAt}
                            error={touched.expiredAt && Boolean(errors.expiredAt)}
                            helperText={touched.expiredAt && errors.expiredAt}
                        />
                    </Grid>

                </Grid>
            </Box>

            {/* Field country list */}
            <Autocomplete
                filterOptions={createFilterOptions({ matchFrom: 'start', trim: true })}
                autoSelect={values.countryName.length >= 3}
                noOptionsText={tt('noOptions')}
                disableClearable={!values.countryName}
                value={values.countryId}
                inputValue={values.countryName}
                options={countryList}
                disabled={!values.type}
                onChange={(e: any, value: any) => {
                    setFieldValue('countryId', value?.id || '');
                    setFieldValue('selectedCountryName', value?.name || '');
                    setFieldValue('countryName', value?.name || '');

                    setTimeout(() => { formik.validateField('countryName'); }, 1); //Не нашел другого способа очистить валидатор сразу после выбора страны
                }}
                classes={{
                    option: classes.option,
                    noOptions: classes.noOptions
                }}
                autoHighlight
                getOptionLabel={(option: any) => (option.name ? option.name : "")}
                getOptionSelected={(option: any, value: any) => option.key === value.id}
                renderOption={(option: any) => (
                    <React.Fragment>
                        {option.name}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        margin="normal"
                        label={tt('countryIssueLabel')}
                        value={values.countryName}
                        name="countryName"
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                        error={touched.countryName && Boolean(errors.countryName || errors.countryId)}
                        helperText={touched.countryName && (errors.countryName || errors.countryId)}
                        required
                    />
                )}
            />

            <FormControlLabel
                className={classes.formCheckboxWrap}
                control={
                    <Checkbox
                        className={clsx(classes.formCheckboxIcon, classes.formCheckboxIconDefault)}
                        icon={<span className={classes.icon} />}
                        checkedIcon={<CheckIcon className={clsx(classes.icon, classes.checkedIcon)} />}
                        disableRipple
                        checked={values.isDefault}
                        onChange={formik.handleChange}
                        name="isDefault"
                        color="primary"
                        disabled={!values.type}
                    />
                }
                label={tt('isDefaultDoc')}
            />

            <Button
                type="button"
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                color="primary"
                startIcon={formik.isSubmitting && <CircularProgress size={14} />}
                disabled={formik.isSubmitting || !!sentAtUtc || !values.type}
                className={classes.submit}
            >
                {tt('submitBtn')}
            </Button>

            {
                sentAtUtc &&
                <Box mt={2}>
                    <Alert severity="success">
                        <AlertTitle>{tt('successTitle')}</AlertTitle>
                    </Alert>
                </Box>
            }
            {
                serverError &&
                <Box mt={2}>
                    <Alert severity="error">
                        <AlertTitle>{t('error')}</AlertTitle>
                        {serverError.displayMessage}
                    </Alert>
                </Box>
            }
        </Form>
    );
}