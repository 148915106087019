import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import LinkInNewWindowDarkIcon from '../../icons/LinkInNewWindowDarkIcon';
import Image from '../../../img/card_image.png';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {},

    // BLOCK CARD
    card: {
        backgroundImage: `url(${Image})`,
        backgroundPosition: `right bottom`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `45% auto`,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: "26px 24px 14px 24px",
        border: '1px solid #F5F7FD',
        backgroundColor: '#F5F7FD',
        borderRadius: 8,
        justifyContent: 'space-between',
        [theme.breakpoints.down(600)]: {
            padding: "15px 16px 11px 16px",
        },
    },

    // TITLE CARD 
    title: {
        color: '#3766D9',
        width: '100%',
        marginBottom: 14,
        [theme.breakpoints.down(600)]: {
            fontSize: 18
        },
    },

    // BODY CARD
    bodyText1: {
        color: '#3766D9',
        lineHeight: 1.3,
        marginBottom: 16,
        width: '100%',
    },
    actionButton: {
        height: 36,
        paddingRight: 14,
        paddingLeft: 14,
        marginTop: 16,
    },

    // BOTTOM CARD
    bottomCard: {
        minHeight: 46,
        marginTop: 13,
    },
    infoButtonLink: {
        textDecoration: 'none',
    },
    infoButton: {
        height: 36,
        textTransform: 'uppercase',
        marginRight: -7,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: -10,
        "&:hover": {
            backgroundColor: 'inherit',
        },
    },
    infoButtonIcon: {
        height: 36,
        width: 36,
        marginTop: 8,
    },
}));

// MAIN
export default function InsuranceCard(props: any) {
    const classes = useStyles();

    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`InsuranceCard.${key}`, options);

    const handleButtonBuyClick = (link: string) => {
        window.open(link);
    };


    return (
        <Grid item className={classes.root} xs={12} sm={6} md={4}>
            <Grid className={classes.card}>

                {/* CONTAINER CARD */}
                <Grid container>

                    {/* TITLE CARD */}
                    <Typography component="h4" variant="h2" className={classes.title}>
                        {tt('title')}
                    </Typography>


                    {/* BODY CARD */}
                    <Typography component="p" className={classes.bodyText1}>
                        {tt('optionalLabel')}
                    </Typography>

                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.actionButton}
                        onClick={() => handleButtonBuyClick('https://www.alfastrah.ru/individuals/travel/vzr/')}
                    >
                        {tt('buyText')}
                    </Button>
                </Grid>


                {/* BOTTOM CARD */}
                <Grid container justify="space-between" alignItems="center" className={classes.bottomCard}>
                    <Button
                        color="primary"
                        key="anketa"
                        endIcon={<LinkInNewWindowDarkIcon className={classes.infoButtonIcon} />}
                        className={classes.infoButton} onClick={() => handleButtonBuyClick('https://www.alfastrah.ru/docs/Pamyarka_COVID_07.08.2020.pdf')}
                    >
                        {tt('moreBtnText')}
                    </Button>
                </Grid>

            </Grid>


        </Grid>
    );
}