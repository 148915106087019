import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TransitSegmentMoreDialog from './TransitSegmentMoreDialog';


const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 5,
        display: 'flex',
        [theme.breakpoints.down(600)]: {
            marginBottom: 10,
        },
    },
    wrapCityAndCode: {
        whiteSpace: 'nowrap',
    },
    cardText: {
        display: 'inline-block',
        '& p': {
            wordBreak: 'break-word',
            padding: 0,
            margin: 0,
            display: 'inline',
            color: '#707898',
            marginRight: 5,
            fontWeight: 400,
        },
        '& a': {
            color: theme.palette.primary.main,
            fontWeight: 400,
            textDecoration: 'none'
        },
    },
    cardRace: {
        marginRight: 5,
        lineHeight: 1.4
    },
    buttonMore: {
        display: 'inline-block',
        padding: 0,
        color: theme.palette.primary.main,
        fontWeight: 400,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    '@keyframes flicker': {
        '0%': {
            backgroundColor: '#ffffff'
        },
        '50%': {
            backgroundColor: '#FFF3E0',
        },
        '100%': {
            backgroundColor: '#FFFDE7',
        },
    },
    flicker: {
        padding: '2px 6px 2px 6px',
        animationName: '$flicker',
        animationDuration: '1.5s',
        animationDelay: '500ms',
        animationFillMode: 'forwards',
        animationIterationCount: '1',
        animationDirection: 'normal',
        animationTimingFunction: 'ease-in-out',
    },
}));


export default function TransitSegmentCards(props: any) {
    const classes = useStyles();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`TransitSegmentCards.${key}`, options);
    const [openDialog, setOpenDialog] = React.useState(false);

    return (
        <Grid className={classes.root}>

            {props.segment && <Grid className={classes.wrapCityAndCode} >
                {/* Text CITY and CODE */}
                <Typography component="p" variant="body1" className={classes.cardRace} >
                    {`${props.segment.arrival.airport.city.name} ${props.segment.arrival.airport.code}:`}
                </Typography>
            </Grid>}

            <Grid>

                {/*Text card if there is no data from the server */}
                {!Boolean(props.requirementList?.length) &&
                    <div className={classes.cardText}><p>{tt('defaultCardText')}</p></div>
                }

                {props.requirementList.map((requirement: any, index: any) => (
                    <React.Fragment key={index}>
                        <div className={requirement.isChanged ? classes.flicker : ''}>
                            <div className={classes.cardText} dangerouslySetInnerHTML={{ __html: requirement.tileDescription }} />

                            {/* Button MORE */}
                            {requirement.extDescription &&
                                <Typography className={classes.buttonMore} onClick={() => setOpenDialog(true)}>
                                    {tt('moreButton')}
                                </Typography>
                            }
                        </div>

                        {/* Dialog MORE */}
                        {requirement.extDescription &&
                            <TransitSegmentMoreDialog
                                openDialog={openDialog}
                                handleClose={() => setOpenDialog(false)}
                                name={props.segment?.arrival.airport.city.name}
                                code={props.segment?.arrival.airport.code}
                                text={requirement.extDescription}
                            />

                        }

                    </React.Fragment>
                ))}

            </Grid>

        </Grid>
    );
}

