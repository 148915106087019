import React, { useRef } from 'react';
import { useSession } from '../auth/SessionProvider';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Checkbox, CircularProgress, Collapse, FormControlLabel, Grid, Link, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CharFieldGroup, { CharFieldGroupHandle } from '../form/CharFieldGroup';
import * as userService from '../../api/user-service';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Alert, AlertTitle } from '@material-ui/lab';
import { ServerError } from '../../api/error-service';
import CustomNumberFormat from '../mui-extensions/CustomNumberFormat';
import Form from '../form/Form';
import RecoveryButton, { recoveryErrors } from '../auth/RecoveryButton';

export interface ConfirmPhoneNumberProps {
    onCompleted?: () => void;
    setCode?: (code: string) => void;
    setPhone?: (code: string) => void;
    setIsConfirmPhoneDone?: (status: boolean) => void;
    mode?: 'signup' | 'reset',
}

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(2.5, 0, 0),
    },
    confirmProcessing: {
        fontSize: 11,
        color: "#808080"
    },
    linkConfirm: {
        color: "#808080",
        textDecoration: 'underline'
    },
    errorMsg: {
        color: "#f44336",
        fontSize: 11
    },
}));

export default function ConfirmPhoneNumber({ onCompleted, mode, setCode, setPhone, setIsConfirmPhoneDone }: ConfirmPhoneNumberProps) {
    const classes = useStyles();

    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`ConfirmPhoneNumber.${key}`, options);

    const { user, refreshSession } = useSession();

    const [serverError, setServerError] = React.useState<ServerError>();
    const [validationEnabled, setValidationEnabled] = React.useState(false);
    const [serverErrorOnCodeConfirm, setServerErrorOnCodeConfirm] = React.useState<ServerError>();
    const [disabledCheckBox, setDisabledCheckBox] = React.useState<boolean>(false);
    const [codeSentAt, setCodeSentAt] = React.useState<Date>();
    const smsCodeRef = useRef<CharFieldGroupHandle>(null);

    const currentPhoneValidationValue = (user?.phone || '').replace(/[^\d]/g, '');

    const { values, touched, errors, ...formik } = useFormik({
        validateOnChange: validationEnabled,
        validateOnBlur: validationEnabled,
        initialValues: {
            phone: '',
            check: true
        },
        validationSchema: Yup.object({
            phone: Yup.string()
                .required(t('reuiredFieldValidationMessage', { fieldName: t('phoneNumberLabel') }))
                .transform(value => value.replace(/[^\d]/g, ''))
                .notOneOf([currentPhoneValidationValue], tt('theSamePhoneErrorMessage'))
                .min(11, t('phoneFormatValidationMessage')),
            check: Yup.bool()
                .oneOf([true], t('reuiredFieldValidationMessage')),
        }),
        onSubmit: async ({ phone }) => {
            setServerError(undefined);
            return userService.sendVerificationCodeForPhone('+' + phone.replace(/[^\d]/g, ''))
                .then(() => {
                    setDisabledCheckBox(true);
                    setCodeSentAt(new Date());
                    smsCodeRef!.current!.focus();
                })
                .catch((err: ServerError) => {
                    setDisabledCheckBox(false);
                    setServerError(err);
                });
        },
    });

    const confirmPhone = (code: string) => {
        setServerErrorOnCodeConfirm(undefined);
        userService.confirmPhoneNumber('+' + values.phone.replace(/[^\d]/g, ''), code)
            .then(() => {
                if (mode === 'reset') refreshSession();
                if (mode === 'signup') {
                    setPhone!('+' + values.phone.replace(/[^\d]/g, ''));
                    setCode!(code);
                    setIsConfirmPhoneDone!(true);
                };
            })
            .then(() => {
                onCompleted && onCompleted();
            })
            .catch((err: ServerError) => {
                setServerErrorOnCodeConfirm(err);
                if (err.reason === 'confirmation_code_not_found') {
                    smsCodeRef!.current!.clear();
                }
            });
    };

    const handleOnChangeSmsCode = (value: string) => {
        if (value.length === 4) {
            confirmPhone(value);
        }
    };

    const handleSubmit: React.FormEventHandler = (e) => {
        setServerErrorOnCodeConfirm(undefined);
        e.preventDefault();
        setValidationEnabled(true);
        formik.submitForm();
    };

    return (
        <React.Fragment>
            <Form onSubmit={handleSubmit}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    autoFocus
                    autoComplete='off'
                    label={t('phoneNumberLabel')}
                    name="phone"
                    value={values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={touched.phone && errors.phone}
                    InputProps={{
                        inputComponent: CustomNumberFormat,
                    }}
                    inputProps={{
                        inputMode: 'tel',
                        format: '+7 (###) ###-##-##'
                    }}
                />

                <Button
                    type="button"
                    onClick={handleSubmit}
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={formik.isSubmitting && <CircularProgress size={14} />}
                    disabled={formik.isSubmitting}
                    className={classes.submit}
                >
                    {tt('sendCodeBtn')}
                </Button>
                {mode === 'signup' &&
                    <Grid item>
                        <Box mt={1}>
                            <FormControlLabel
                                onChange={formik.handleChange}
                                disabled={disabledCheckBox}
                                control={
                                    <Checkbox
                                        name="check"
                                        defaultChecked
                                        disableRipple
                                        style={{
                                            color: "#3766D9",
                                        }}
                                    />}
                                label={<Typography className={classes.confirmProcessing}>{t('SignIn.iAccept')}<Link className={classes.linkConfirm} href='/blog/confidentiality'>{t('SignIn.confirmProcessingPolicy')}</Link></Typography>}
                            />
                            {(errors.check) &&
                                <Typography className={classes.errorMsg}>{t('reuiredFieldValidationMessage')}</Typography>
                            }
                        </Box>
                    </Grid>
                }

                {serverError &&
                    <Box mt={2}>
                        <Alert severity="error">
                            <AlertTitle>{t('error')}</AlertTitle>
                            {serverError.displayMessage}
                        </Alert>
                    </Box>
                }
                {(!!serverError && recoveryErrors.includes(serverError.reason)) && <RecoveryButton phone={values.phone} formik={formik} />}
            </Form>

            <Form>

                <Collapse in={!!codeSentAt}>
                    <Box m="auto" marginTop={2}>
                        <Typography variant="h3" align="center">{t('codeFromSmsLabel')}</Typography>

                        <Grid container justify="center">
                            <CharFieldGroup ref={smsCodeRef} n={4} onChanged={handleOnChangeSmsCode} />
                        </Grid>
                    </Box>
                </Collapse>

                {serverErrorOnCodeConfirm &&
                    <Box mt={2}>
                        <Alert severity="error">
                            <AlertTitle>{t('error')}</AlertTitle>
                            {serverErrorOnCodeConfirm.displayMessage}
                        </Alert>
                    </Box>
                }

            </Form>
        </React.Fragment>
    );
}