import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ButtonCardCouponIcon(props: any) {
    return (
        <SvgIcon {...props}>
            <path d="M9.75 1.25H2.75C2.28587 1.25 1.84075 1.43437 1.51256 1.76256C1.18437 2.09075 1 2.53587 1 3V17C1 17.4641 1.18437 17.9092 1.51256 18.2374C1.84075 18.5656 2.28587 18.75 2.75 18.75H13.25C13.7141 18.75 14.1592 18.5656 14.4874 18.2374C14.8156 17.9092 15 17.4641 15 17V6.5L9.75 1.25Z" fill="none" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.75 1.25V6.5H15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none" />
            <path d="M11.5 10.875H4.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none" />
            <path d="M11.5 14.375H4.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none" />
            <path d="M6.25 7.375H5.375H4.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none" />
        </SvgIcon>
    );
}