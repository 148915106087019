import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import IconCard from './IconCard';
import BgImageCard from './BgImageCard';
import CityCard from './CityCard';
import TwoColBanner from './TwoColBanner';
import Partners from './Partners';
import FaqBlock from './FaqBlock';

import landingImg1 from '../../images/Landing1.jpg';
import landingImg2 from '../../images/Landing2.jpg';
import landingImg3 from '../../images/Landing3.jpg';

import franceImg from '../../images/countries/France.jpg';
import turkeyImg from '../../images/countries/Turkey.jpg';
import italyImg from '../../images/countries/Italy.jpg';
import cyprusImg from '../../images/countries/Cyprus.jpg';
import { useTranslation } from 'react-i18next';
import { data } from './IconCards.data';
import { getCurrentLanguageCode } from '../../i18n';

const helpCards = [
    {
        title: 'Landing.helpCards.item1.title',
        image: landingImg1,
        link: 'blog/1',
    },
    {
        title: 'Landing.helpCards.item2.title',
        image: landingImg2,
        link: 'blog/2',
    },
    {
        title: 'Landing.helpCards.item3.title',
        image: landingImg3,
        link: 'blog/3',
    },
];


const cityCards = [
    {
        countryId: 250,
        name: 'Landing.cityCards.item1.name',
        message: 'Landing.cityCards.item1.desc',
        image: franceImg,
    },
    {
        countryId: 792,
        name: 'Landing.cityCards.item2.name',
        message: 'Landing.cityCards.item2.desc',
        image: turkeyImg,
    },
    {
        countryId: 380,
        name: 'Landing.cityCards.item3.name',
        message: 'Landing.cityCards.item3.desc',
        image: italyImg,
    },
    {
        countryId: 196,
        name: 'Landing.cityCards.item4.name',
        message: 'Landing.cityCards.item4.desc',
        image: cyprusImg,
    },
];

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '48px',
    },
    h2: {
        maxWidth: '563px',
        marginTop: '56px',
        fontSize: 22,
        fontWeight: 700,
        lineHeight: '100%',
    },
    helpItems: {
        marginBottom: '32px',
    },
    cityCards: {
        marginTop: '32px',
    },
    showAllBtn: {
        marginTop: '32px',
        width: '176px',
    },
    banner: {
        marginTop: '54px',
        backgroundColor: '#F5F7FD',
        borderRadius: '8px',
    },
}));


export default function Landing(props: any) {
    const [icons, setIcons] = React.useState([] as any);
    const classes = useStyles();
    const { t } = useTranslation();

    React.useEffect(() => {
        setIcons(data[getCurrentLanguageCode()]);
    }, [t]);



    return (
        <div className={classes.root}>
            <Typography variant="h2" className={classes.h2}>{t('Landing.title1')}</Typography>

            <Grid container className={classes.helpItems} spacing={3} justify="space-between">
                {icons.map((itemProps: any, index: number) => (
                    <Grid item xs={12} md={4} key={index}>
                        <IconCard {...itemProps} />
                    </Grid>
                ))}

            </Grid>


            <Grid container spacing={3} justify="space-between">
                {helpCards.map((itemProps: any, index: number) => (
                    <Grid item xs={12} md={4} key={index}>
                        <BgImageCard {...itemProps} />
                    </Grid>
                ))}

            </Grid>

            <Typography variant="h2" className={classes.h2}>{t('Landing.title2')}</Typography>


            <Grid container className={classes.cityCards}>
                {cityCards.map((itemProps, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                        <CityCard {...itemProps} />
                    </Grid>
                ))}
            </Grid>

            {/* <Button variant="outlined" color="primary" className={classes.showAllBtn}>Показать все</Button> */}

            <TwoColBanner className={classes.banner} />

            <Partners />

            <FaqBlock />




        </div>
    );
}