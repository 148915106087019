import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, DialogTitle, DialogContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';
import FellowList from './FellowList';
import { Alert, AlertTitle } from '@material-ui/lab';
import CustomDialog from '../mui-extensions/CustomDialog';


const useStyles = makeStyles((theme) => ({
    line: {
        width: "100%",
        height: 1,
        backgroundColor: '#1C2C5E',
        opacity: .2,
        marginBottom: 14,
        [theme.breakpoints.down('sm')]: {
            backgroundColor: 'inherit',
        },
    },
    isDisableCheckBox: {
        color: '#79809E'
    },
    checkboxControlLabel: {
        lineHeight: 1,
        width: "100%",
    },
    checkboxIcon: {
        margiTop: 0,
        marginLeft: 2,
    },
    checkboxIconDefault: {
        paddingTop: 7
    },
    icon: {
        borderRadius: 4,
        width: 20,
        height: 20,
        border: '1px solid rgba(28, 44, 94, 0.15)',
        backgroundColor: '#FFFFFF',
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#FFFFFF',
        width: 20,
        height: 20,
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
    },
    totalCostTitle: {
        width: '100%',
        marginTop: 15,
        marginBottom: 8,
    },
    totalCostPrice: {
        width: '100%',
        marginBottom: 8,
    },
    totalCostText: {
        width: '100%',
        marginBottom: 3,
    },
    checkoutButton: {
        marginTop: 16,
    },
    profilButton: {
        display: 'flex',
        height: 46,
        textTransform: 'uppercase',
        padding: '16px 28px',
        marginTop: 10,
    },
}));


// Total coast Component
export default function TotalCost(props: any) {
    const classes = useStyles();

    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`OrderTestLab.${key}`, options);

    const [orderFellowList, setOrderFellowList] = React.useState([]);

    useEffect(() => {
        setOrderFellowList(props.isBookFellow ? props.fellowList : []);
    }, [props.isBookFellow, props.fellowList]);


    // Popper component hendler from delete fellows
    const removeFellowFromList = (item: any) => {
        const fellows = orderFellowList.filter((check: any) => check.id !== item.id);
        setOrderFellowList(fellows);

        // Get the ID of all fellow travelers 
        props.setFellowIdArrayToRegisterForRest(fellows.map((fellow: any) => fellow.id));
    };


    return (
        <React.Fragment>
            {/* Line */}
            <div className={classes.line}></div>


            {/* Book Fellow */}
            {props.fellowList && props.fellowList.length > 1 &&
                <FormControlLabel
                    className={clsx(classes.checkboxControlLabel, `${props.isBookFellow ? '' : classes.isDisableCheckBox}`)}
                    control={
                        <Checkbox
                            className={clsx(classes.checkboxIcon, classes.checkboxIconDefault)}
                            icon={<span className={classes.icon} />}
                            checkedIcon={<CheckIcon className={clsx(classes.icon, classes.checkedIcon)} />}
                            disableRipple
                            checked={props.isBookFellow}
                            onChange={props.setIsBookFellow}
                            name="isBookFellow"
                            color="primary"
                        />
                    }
                    label={tt('checkboxBookFellow')}
                />
            }

            {/* FellowList */}
            {props.isBookFellow && props.fellowList.length > 1 &&
                <FellowList
                    orderFellowList={orderFellowList}
                    price={props.price}
                    removeFellowFromList={(item: any) => removeFellowFromList(item)}
                />
            }


            {/* Total Cost */}
            {props.price &&
                <React.Fragment>
                    <Typography component="p" variant="h3" className={classes.totalCostTitle}>{tt('totalCostTitle')}</Typography>
                    <Typography component="p" variant="h2" className={classes.totalCostPrice}>
                        {`${props.isBookFellow ? props.price * orderFellowList.length : parseInt(props.price, 10)} `}{tt('totalCostCurrency')}
                    </Typography>
                    <Typography component="p" variant="body1" className={classes.totalCostText}>{tt('totalCostText')}{props.texeWhichTypeSelected.toLowerCase()}</Typography>
                </React.Fragment >
            }


            {/* Button Checkout */}
            <Button
                className={classes.checkoutButton}
                variant="contained"
                color="primary"
                fullWidth
                onClick={props.handleCheckoutButton}
                startIcon={props.formik.isSubmitting && <CircularProgress size={14} />}
                disabled={props.formik.isSubmitting}
            >
                {tt('checkoutButton')}
            </Button>


            {props.serverError &&
                <Box mt={2}>
                    <Alert severity="error">
                        <AlertTitle>{t('error')}</AlertTitle>
                        {props.serverError.displayMessage}
                    </Alert>
                    <CustomDialog
                        open={props.serverError && props.serverError.reason === 'insufficient_permissions'}
                        onClose={() => props.setServerError(undefined)}
                    >
                        <DialogTitle>{t('error')}</DialogTitle>
                        <DialogContent>
                            <Typography component="p">{props.serverError.displayMessage}</Typography>
                            <Box mt={2}>
                                <Button
                                    className={classes.profilButton}
                                    variant="contained"
                                    color="primary"
                                    href='/profile'
                                >
                                    {tt('profilButton')}
                                </Button>
                            </Box>
                        </DialogContent>
                    </CustomDialog>
                </Box>
            }
        </React.Fragment >
    );

}