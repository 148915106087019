import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function LinkInNewWindowDarkIcon(props: any) {
    return (
        <SvgIcon {...props}>
            <svg width="26" height="26" viewBox="0 0 36 36" fill="none">
                <circle opacity="0.1" r="13" transform="matrix(1 0 0 -1 13 13)" fill="#3766D9" />
                <path d="M10.4502 16.7221L14.1169 13.0554L10.4502 9.38879L11.3968 8.44879L16.0035 13.0554L11.3969 17.6621L10.4502 16.7221Z" fill="#3766D9" />
            </svg>
        </SvgIcon>
    );
}