import React from 'react';
import { useSession } from './SessionProvider';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as userService from '../../api/user-service';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ServerError } from '../../api/error-service';
import { Alert, AlertTitle } from '@material-ui/lab';
import Form from '../form/Form';

export interface SetPasswordResult {
    email: string;
}

export interface SetPasswordProps {
    code: string;
    email?: string;
    phone?: string;
    mode: 'signup' | 'reset',
    onCompleted?: (result: SetPasswordResult) => void;
    handleSetPasswordDone?: () => void;
}

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(2.5, 0, 0),
    },
}));

export default function SetPassword({ mode, email, onCompleted, handleSetPasswordDone, ...props }: SetPasswordProps) {
    const classes = useStyles();

    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`SetPassword.${key}`, options);

    const [serverError, setServerError] = React.useState<ServerError>();
    const [validationEnabled, setValidationEnabled] = React.useState(false);

    const { refreshSession, invitedByReferralCode } = useSession();

    const { values, touched, errors, ...formik } = useFormik({
        validateOnChange: validationEnabled,
        validateOnBlur: validationEnabled,
        initialValues: {
            email: email || '',
            password: '',
            confirmPassowrd: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email(t('emailFormatValidationMessage'))
                .required(t('reuiredFieldValidationMessage', { fieldName: t('emailLabel') })),
            password: Yup.string()
                .required(t('reuiredFieldValidationMessage', { fieldName: t('passwordLabel') }))
                .min(8, t('passwordMinValidationMessage', { min: 8 }))
                .max(127, t('passwordMaxValidationMessage', { max: 127 }))
                .matches(/(?=.*[a-zA-Z])(?=.*\d)/, t('passwordFormatValidationMessage')),
            confirmPassowrd: Yup.string()
                .required(t('reuiredFieldValidationMessage', { fieldName: t('confirmPasswordLabel') }))
                .when('password', {
                    is: (val: string) => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref('password')],
                        t('confirmPasswordIncorrectMessage')
                    )
                })
        }),
        onSubmit: async ({ email, password }) => {
            const code = props.code;
            const phone = props.phone;
            const setPasswordApi = mode === 'signup'
                ? userService.signUp
                : userService.changePassword
                ;
            return setPasswordApi({ email, password, code, phone, invitedByReferralCode })
                .then(() => refreshSession())
                .then(() => { if (mode === 'signup') handleSetPasswordDone!(); })
                .then(() => {
                    onCompleted && onCompleted({ ...values });
                })
                .catch((err: ServerError) => {
                    setServerError(err);
                });
        },
    });

    const handleSubmit = () => {
        setValidationEnabled(true);
        formik.submitForm();
    };


    return (
        <Form>
            <TextField
                margin="normal"
                required
                fullWidth
                label={t('emailLabel')}
                name="email"
                autoComplete='off'
                autoFocus
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={values.email}
                disabled={!!email}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                autoComplete='off'
                label={t('passwordLabel')}
                type="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={values.password}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassowrd"
                autoComplete='off'
                label={t('confirmPasswordLabel')}
                type="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={values.confirmPassowrd}
                error={touched.confirmPassowrd && Boolean(errors.confirmPassowrd)}
                helperText={touched.confirmPassowrd && errors.confirmPassowrd}
            />
            <Button
                type="button"
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                color="primary"
                disabled={formik.isSubmitting}
                className={classes.submit}
            >
                {tt('submitBtn')}
            </Button>

            {serverError &&
                <Box mt={2}>
                    <Alert severity="error">
                        <AlertTitle>{t('error')}</AlertTitle>
                        {serverError.displayMessage}
                    </Alert>
                </Box>
            }
        </Form>

    );
}