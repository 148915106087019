import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Button, ClickAwayListener, Container, Grow, IconButton, MenuItem, MenuList, Paper, Popper, withStyles, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { FlightShortInfo, FlightSegmentDetails } from '../../../api/flight-service.types';
import { useTranslation } from 'react-i18next';
import SegmentCards from './SegmentCards';
import EditFellowDialog from '../../fellow/EditFellowDialog';
import FellowStatusesDialog from '../../fellow/FellowStatusesDialog';
import * as flightService from '../../../api/flight-service';
import { useSnackbar } from 'notistack';
import { Person } from '../../../api/user-service.types';
import EditFlightDialog from '../edit/EditFlightDialog';
import SegmentDetailsMenuIcon from '../../icons/SegmentDetailsMenuIcon';
import DeletePopper from '../DeletePopper';
import SegmentFlightChain from './SegmentFlightChain';
import TransitSegmentCards from './TransitSegmentCards';
import TransitSegmentCardIcon from '../../icons/TransitSegmentCardIcon';

export interface SegmentDetailsProps {
    flight: FlightShortInfo,
    segment: FlightSegmentDetails,
    onDeleteSegment: (segmentId: number) => void;
    fellowList: any[];
    segments: any[];
    transitSegmentList: any[];
    onEditFellowCompleted: (fellow: Person, isNew: boolean) => void;
    onDeleteFellow: (fellow: Person) => void;
    isLoading?: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(5),
    },
    segmentFlightChainWrap: {
        marginBottom: 6
    },
    fellowsBtn: {
        height: 34,
        backgroundColor: '#E7EDFA',
        boxShadow: 'none',
        color: theme.palette.primary.main,
        padding: 0,
        paddingRight: 16,
        paddingLeft: 16,
        textTransform: 'uppercase',
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "#ffffff",
            boxShadow: 'none',
        },
    },
    menuButtonIcon: {
        height: 34,
        width: 34,
        textAlign: 'center',
        marginBottom: 0,
        paddingRight: 0,
        paddingBottom: 7,
        marginLeft: 2,
        marginRight: 2,
    },
    menuIcon: {
        display: 'block',
        marginLeft: 6,
        height: 24,
        width: 24,
        textAlign: 'center',
    },
    fellowsBtnIcon: {
        height: 26,
        width: 26
    },
    isTransit: {
        marginLeft: 24,
        border: '1px solid #4FC685',
        borderRadius: 50,
        color: '#4FC685',
        padding: '6px 10px',
        fontSize: 12
    },
    transitSegmentRequirementWrap: {
        marginTop: 2,
        paddingLeft: 40,
        position: 'relative',
    },
    transitSegmentCardIconWrap: {
        position: 'absolute',
        left: 0,
        width: 40,
        height: 40,
    },
    transitSegmentCardIcon: {
        width: 42,
        height: 42,
    },
    transitSegmentCardWrap: {
        marginTop: 7,
    },
    tooltip: {
        boxShadow: '0px 10px 60px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    },

}));


const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.primary,
        boxShadow: '0px 10px 60px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        padding: '8px 13px',
        fontSize: 12,
        borderRadius: 56,
    },
}))(Tooltip);


// Popper - Drop Down menu in segment
function DropDownMenu({ deletedSegment, anchorEl, setAddFlightsOpen, onClickMenuDelete, onClickAway, isOpenDeletePopper, setIsOpenDeletePopper }: any) {
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`SegmentDetails.${key}`, options);


    if (!anchorEl)
        return null;

    const handlerEditFlightClick = () => {
        setAddFlightsOpen(true);
    };

    return (
        <Popper
            open={true}
            anchorEl={anchorEl}
            placement="bottom"
            disablePortal={false}
            transition
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={onClickAway}>
                            <MenuList>

                                {/* Edit menu item */}
                                <MenuItem onClick={handlerEditFlightClick}>
                                    <Typography component="p" variant="body2">{tt('dropMenuItemEdit')}</Typography>
                                </MenuItem>

                                {/* Delete menu item */}
                                <MenuItem onClick={() => setIsOpenDeletePopper(true)}>
                                    <Typography component="p" variant="body2">{tt('dropMenuItemDelete')}</Typography>
                                </MenuItem>

                                {/* Confirm delete segment popper */}
                                {isOpenDeletePopper === true &&
                                    <DeletePopper deletedItem={deletedSegment} anchorEl={anchorEl} onClickYes={onClickMenuDelete} onClickNo={() => setIsOpenDeletePopper(false)} />
                                }
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>


    );
}


export default function SegmentDetails({ flight, fellowList, segment, segments, transitSegmentList, isLoading, ...props }: SegmentDetailsProps) {
    const classes = useStyles();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`SegmentDetails.${key}`, options);

    const { enqueueSnackbar } = useSnackbar();

    const [addFellowOpened, setAddFellowOpened] = React.useState(false);
    const [editedFellow, setEditedFellow] = React.useState<Person>();
    const [fellowStatusesOpened, setFellowStatusesOpened] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState();
    const [deletedSegment, setDeletedSegment] = React.useState(undefined);
    const [isOpenDeletePopper, setIsOpenDeletePopper] = React.useState(false);
    const [openAddFlights, setAddFlightsOpen] = React.useState<boolean>(false);

    const [segmentRequirement, setSegmentRequirement] = React.useState([]);
    const [segmentRequirementIsLoad, setSegmentRequirementIsLoad] = React.useState(false);
    const [transitListRequirement, setTransitListRequirement] = React.useState([] as any);
    const [transitListRequirementIsLoad, setTransitListRequirementIsLoad] = React.useState(false);

    const handleAddFlightsClose = () => {
        setAddFlightsOpen(false);
    };

    const handleFellowsBtnClick = () => {
        if (fellowList.length === 0) {
            setAddFellowOpened(true);

        } else {
            setFellowStatusesOpened(true);
        }
    };

    // Open drop menu
    const handleDropMenuClick = (item: any, event: any) => {
        setAnchorEl(event.currentTarget);
        setDeletedSegment(item);
        setIsOpenDeletePopper(false);
    };

    // Get segment requirements in listRequirement
    useEffect(() => {

        if (segment.id !== undefined) {
            flightService.getListRequirement(flight.id, segment.id)
                .then((res) => {
                    setSegmentRequirement(res);
                })
                .then(() => setSegmentRequirementIsLoad(true))
                .catch((e) => {
                    console.log(e);
                });
        }
    }, [flight.id, segment.id]);

    //  Get transit requirements in transitListRequirement
    useEffect(() => {
        if (transitSegmentList.length !== 0) {

            setTransitListRequirement([]);

            (async function getListRequirementInLoop() {
                for (let i = 0; i < transitSegmentList.length; i++) {
                    await flightService.getListRequirement(flight.id, transitSegmentList[i]?.id)
                        .then((res) => {
                            setTransitListRequirement((prevState: any) => ([
                                ...prevState, {
                                    segmentInfo: transitSegmentList[i],
                                    requirementList: res
                                }])
                            );
                        })
                        .catch((e) => {
                            console.log(e);
                        });

                };
            })();

            setTransitListRequirementIsLoad(true);
        }
    }, [flight.id, transitSegmentList]);

    // Menu item delete segment
    const handleClickMenuDelete = () => {
        flightService.deleteSegment(segment.id)
            .then(() => {
                enqueueSnackbar(tt('successfulSegmentSeletion'), { variant: 'success' });
                props.onDeleteSegment(segment.id);
                setIsOpenDeletePopper(false);
            })
            .catch(() => {
                enqueueSnackbar(tt('errorSegmentSeletion'), { variant: 'error' });
            });
    };

    const openAddFellowDialog = () => {
        setEditedFellow(undefined);
        setAddFellowOpened(true);
    };

    const openEditFellowDialog = (fellow: Person) => {
        setEditedFellow(fellow);
        setAddFellowOpened(true);
    };

    const closeEditFellowDialog = () => {
        setEditedFellow(undefined);
        setAddFellowOpened(false);
    };

    const onEditFellowCompleted = (fellow: Person, isNew: boolean) => {
        closeEditFellowDialog();
        props.onEditFellowCompleted(fellow, isNew);
    };

    const closeFellowStatusesDialog = () => {
        setFellowStatusesOpened(false);
    };

    const onClickAway = () => {
        setAnchorEl(undefined);
    };

    return (
        <Container className={classes.root} disableGutters>

            <Grid container alignItems="center" className={classes.segmentFlightChainWrap}>
                <SegmentFlightChain segment={segment} transitSegmentList={transitSegmentList} />

                <IconButton
                    className={classes.menuButtonIcon}
                    onClick={(event: any) => { handleDropMenuClick(segment, event); }}
                >
                    <SegmentDetailsMenuIcon className={classes.menuIcon} />
                </IconButton>

                <Button
                    variant="contained"
                    color="primary"
                    className={classes.fellowsBtn}
                    onClick={handleFellowsBtnClick}
                    endIcon={<AddIcon className={classes.fellowsBtnIcon} />}
                >
                    {tt('fellowsBtn', { fellowCount: fellowList.length || 0 })}
                </Button>
                {addFellowOpened && <EditFellowDialog close={closeEditFellowDialog} onCompleted={onEditFellowCompleted} flight={flight} editedFellow={editedFellow} flightFellows={fellowList} />}
                {fellowStatusesOpened && <FellowStatusesDialog flight={flight} fellows={fellowList} addFellow={openAddFellowDialog} editFellow={openEditFellowDialog} onDeleteFellow={props.onDeleteFellow} close={closeFellowStatusesDialog} />}


                {segment.isTransit === true &&
                    <Grid item className={classes.isTransit}>
                        {tt('isTransitTagTitle')}
                    </Grid>
                }
            </Grid>

            {/* The rules for transit flights */}
            {((transitSegmentList.length > 0 && transitListRequirementIsLoad) || (segment.isTransit === true && segmentRequirementIsLoad)) &&
                <Grid container className={classes.transitSegmentRequirementWrap}>
                    <Grid item className={classes.transitSegmentCardIconWrap} >
                        <LightTooltip title={tt('tooltipTitle')} placement="top">
                            <div>
                                <TransitSegmentCardIcon className={classes.transitSegmentCardIcon} />
                            </div>
                        </LightTooltip >
                    </Grid>

                    <Grid item className={classes.transitSegmentCardWrap}>
                        {transitListRequirement.map((transit: any) => (
                            <TransitSegmentCards
                                key={transit.segmentInfo.id}
                                segment={transit.segmentInfo}
                                requirementList={transit.requirementList}
                            />
                        ))}

                        {/* Show if this is a transit segment and does not have an associated chain of transfers */}
                        {segment.isTransit === true &&
                            <TransitSegmentCards key={segment.id} segment={segment} requirementList={segmentRequirement} />
                        }
                    </Grid>
                </Grid>
            }

            {/* Rules for non-transit flights */}
            {segment.isTransit === false &&
                <SegmentCards segmentRequirement={segmentRequirement} segmentId={segment.id} flightId={flight.id} segmentRequirementIsLoad={isLoading} />
            }

            {/* Popper component. Confirm dialog delete */}
            <DropDownMenu
                deletedSegment={deletedSegment}
                anchorEl={anchorEl}
                setAddFlightsOpen={setAddFlightsOpen}
                onClickMenuDelete={handleClickMenuDelete}
                onClickAway={onClickAway}
                isOpenDeletePopper={isOpenDeletePopper}
                setIsOpenDeletePopper={setIsOpenDeletePopper}
            />

            <EditFlightDialog openEditFlightDialog={openAddFlights} handleCloseEditFlightDialog={handleAddFlightsClose} openForEdit={true} idFlight={flight.id} segments={segments} />

        </Container >
    );
}

