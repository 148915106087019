import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import RadioCard from '../form/RadioCard';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    formControlWrap: {
        marginBottom: 40,
        width: '100%',
    },
    orderTestTypeText: {
        width: '100%',
        fontWeight: 500,
        paddingTop: 4,
        paddingBottom: 1,
    },
    orderTestTypePrice: {
        width: '100%',
        fontWeight: 700,
    },
}));

// Custom children content
function ChildrenRadioBox(item: any,) {
    const classes = useStyles();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`SelectType.${key}`, options);
    return (
        <Grid item container>
            <Typography component="p" className={classes.orderTestTypeText}>{tt(item.text)}</Typography>
            <Typography component="p" className={classes.orderTestTypePrice}>{tt(item.price)}</Typography>
        </Grid>
    );
}

export default function SelectType(props: any) {
    const classes = useStyles();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`SelectType.${key}`, options);

    const [value, setValue] = React.useState('typeLab');

    // Change state in parants component
    const handleChangeTypeTest = (event: any) => {
        setValue(event.target.value);
        props.switchOrderTypeTest(event.target.value);
    };

    return (
        <FormControl component="fieldset" className={classes.formControlWrap}>
            <RadioGroup defaultValue="typeLab" className={classes.root} aria-label="orderTest" name="orderTest" value={value} onChange={handleChangeTypeTest} >

                {/* Test type Lab */}
                <Grid container justify="space-between" alignItems="stretch" spacing={3} >
                    <Grid item container md={4} alignItems="stretch">
                        <RadioCard
                            value={'typeLab'}
                            label={tt('labLabel')}
                            checkedValue={value}
                        >
                            <ChildrenRadioBox text={'labText'} price={'labPrice'} />
                        </RadioCard>
                    </Grid>

                    {/* Test type Home */}
                    <Grid item container md={4} alignItems="stretch">
                        <RadioCard
                            value={'typeHome'}
                            label={tt('homeLabel')}
                            checkedValue={value}
                        >
                            <ChildrenRadioBox text={'homeText'} price={'homePrice'} />

                        </RadioCard>
                    </Grid>

                    {/* Test type Airport */}
                    <Grid item container md={4} alignItems="stretch">
                        <RadioCard
                            value={'typeAirport'}
                            label={tt('airLabel')}
                            checkedValue={value}
                        >
                            <ChildrenRadioBox text={'airText'} price={'airPrice'} />
                        </RadioCard>
                    </Grid>
                </Grid>

            </RadioGroup >
        </FormControl >
    );
}