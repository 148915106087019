import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FactoryCard from '../../cards/FactoryCard';
import InsuranceCard from '../../cards/req-card-static/InsuranceCard';
import SkeletonCards from '../../mui-extensions/CustomSkeleton';


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 13,
        marginBottom: 16,
    },
}));


export default function SegmentCards(props: any) {
    const classes = useStyles();
    return (
        <Grid container spacing={3} className={classes.root}>
            {props.segmentRequirementIsLoad ? <SkeletonCards count={6} />
                : <>{props.segmentRequirement.map((requirement: any) => (
                    <Grid item xs={12} sm={6} md={4} key={requirement.id}>
                        <FactoryCard requirement={requirement} key={requirement.id} segmentId={props.segmentId} flightId={props.flightId} isChanged={requirement.isChanged} />
                    </Grid>
                ))}<InsuranceCard /></>
            }

        </Grid>
    );
}

