import React from 'react';
import { DialogTitle, DialogContent, Typography, makeStyles } from '@material-ui/core';
import ConfirmPhoneNumber from '../../components/profile/ConfirmPhoneNumber';
import { useTranslation } from 'react-i18next';
import { useSession } from '../../components/auth/SessionProvider';
import CustomDialog from '../../components/mui-extensions/CustomDialog';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    dialogBackDrop: {
        backdropFilter: "blur(5px)",
    },
}));

export default function ConfirmPhoneNumberDialog(props: any) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(true);

    const { user } = useSession();

    const isAlvaisShow = !user?.phone;// Нельзя закрыть диалог если не закончена регистрация

    const handleClose = (isCompleted: boolean) => {
        if (!isCompleted && isAlvaisShow)
            return;

        setOpen(false);
    };

    const handleCloseByUser = () => handleClose(false);
    const handleCloseCompleted = () => handleClose(true);

    return (
        <CustomDialog
            maxWidth="xs"
            onClose={handleCloseByUser}
            open={open}
            BackdropProps={{
                classes: {
                    root: classes.dialogBackDrop,
                },
            }}
            hideButtonClose={true}
        >
            <DialogTitle disableTypography={true}>
                <Typography variant="subtitle2">{t('stepNumberN', { stepNumber: 2 })}</Typography>
                <Typography variant="h6" component="h2">{t('confirmPhoneNumberTitlle')}</Typography>
            </DialogTitle>
            <DialogContent>
                <ConfirmPhoneNumber onCompleted={handleCloseCompleted} />
            </DialogContent>
        </CustomDialog>
    );
}