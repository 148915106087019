import React from 'react';
import NumberFormat from 'react-number-format';

export default function CustomNumberFormat(props: any) {
    const { inputRef, onChange, onFocus, onBlur, format, ...other } = props;

    const [isFocused, setIsFocused] = React.useState(false);

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values: any) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.formattedValue,
                    },
                });
            }}
            onFocus={(event: any) => {
                onFocus(event);
                setIsFocused(true);
            }}
            onBlur={(event: any) => {
                onBlur(event);
                setIsFocused(false);
            }}
            //isNumericString={false}
            format={format}
            mask="_"
            allowEmptyFormatting={isFocused}
        />
    );
}