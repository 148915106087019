import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, DialogContent, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../../mui-extensions/CustomDialog';
import TransitMoreDialogDoneIcon from '../../icons/TransitMoreDialogDoneIcon';

// DIALOG CARD
const useStyles = makeStyles((theme) => ({
    content: {
        marginTop: 20,
        '& p': {
            lineHeight: 1.3,
            marginTop: 8,
            marginBottom: 16,
            fontWeight: 400,
        },
        '& ul': {
            fontSize: 14,
            fontWeight: 400,
            paddingLeft: 30,
            marginTop: 8,
            marginBottom: 8,
        },
        '& a': {
            color: theme.palette.primary.main,
            fontWeight: 400,
        },
    },
    dialogFooter: {
        marginTop: 46,
        paddingBottom: 3,
    },
    buttonIRead: {
        backgroundColor: '#3AC582',
        color: theme.palette.text.secondary,
        padding: '16px 25px',
        "&:hover": {
            backgroundColor: '#3AB082',
            boxShadow: 'none',
        },
    },
    buttonDoneIcon: {
        fontSize: 22,
        marginTop: 8
    },
}));


export default function TransitSegmentMoreDialog(props: any) {
    const classes = useStyles();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`TransitSegmentMoreDialog.${key}`, options);

    return (
        <CustomDialog maxWidth="sm" onClose={props.handleClose} open={props.openDialog}>
            <Typography component="h2" variant="h2">
                {tt('dialogTitle')}
                {props.name && <>
                    <Typography component="span" variant="h2" color="primary">{' ' + props.name + ' '}</Typography>
                    <Typography component="span" variant="h2" color="primary">{props.code}</Typography>
                </>}
            </Typography>

            <DialogContent className={classes.content}>

                {/* Text card */}
                <div dangerouslySetInnerHTML={{ __html: props.text }} />

                <Grid container className={classes.dialogFooter}>
                    <Button variant="contained" onClick={props.handleClose} className={classes.buttonIRead}>
                        <TransitMoreDialogDoneIcon className={classes.buttonDoneIcon} />
                        {tt('buttonIRead')}
                    </Button>
                </Grid>
            </DialogContent>
        </CustomDialog>
    );
}