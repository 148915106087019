import React from 'react';
import { useHistory } from 'react-router-dom';
import { DialogTitle, DialogContent, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../../components/mui-extensions/CustomDialog';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    title: {
        color: "#4FC685"
    },
}));


export default function SignUpDoneDialog() {
    const classes = useStyles();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`SignUpDoneDialog.${key}`, options);
    const history = useHistory();
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        history.replace({
            hash: undefined
        });
    };

    return (
        <CustomDialog maxWidth="xs" onClose={handleClose} open={open}>
            <DialogTitle className={classes.title}>{tt('successTitle')}</DialogTitle>
            <DialogContent><Typography component='p' >{tt('successText')}</Typography></DialogContent>
        </CustomDialog>
    );
}