import React from 'react';
import { DialogTitle, DialogContent, Grid, Typography } from '@material-ui/core';
import SegmentList from './SegmentList';
import AddSegmentForm from './AddSegmentForm';
import * as flightService from '../../../api/flight-service';
import { ServerError } from '../../../api/error-service';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useSession } from '../../auth/SessionProvider';
import { Flight, FlightSegmentDetails } from '../../../api/flight-service.types';
import DeletePopper from '../DeletePopper';
import CustomDialog from '../../mui-extensions/CustomDialog';
import { useHistory } from 'react-router-dom';


// Creates an object to send when sending one segment.
const createSegmentObjectToServer = (item: any) => (
    {
        flightNumber: item.flightNumber,
        isTransit: item.isTransit,
        departure: {
            at: item.departure.at,
            airportId: item.departure.airportId
        },
        arrival: {
            at: item.arrival.at,
            airportId: item.arrival.airportId
        }
    });


// Create an array of objects to send to the server
function createSegmentArrayToServer(segmentsOriginal: any[]): Flight {
    const segments = segmentsOriginal.map((item: any) => createSegmentObjectToServer(item));
    return { segments };
}


const useStyles = makeStyles((theme) => ({
    root: {},
    dialogTitle: {
        padding: '0px 0px 8px 0px',
    },
    buttonAndErrorBlockWrap: {
        padding: '15px 0px 0px 0px',
    },
    noSegmentsAdded: {
        padding: 10,
        marginBottom: 10,
        marginTop: 10,
        backgroundColor: '#FFF5DD',
        color: '#212194',
        textAlign: 'center'
    },
    flightCompletelyDeleted: {
        padding: '0px 0px 32px 0px',
    },
    segmentList: {
        overflow: 'hidden',
        padding: '0px 0px 22px 0px',
    }
}));


export default function EditFlightDialog(props: any) {
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`EditFlightDialog.${key}`, options);
    const classes = useStyles();

    const history = useHistory();

    const [serverError, setServerError] = React.useState<ServerError>();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [isChange, setIsChange] = React.useState(false);

    //For delete popper
    const [anchorEl, setAnchorEl] = React.useState();
    const [deletedItem, setDeletedItem] = React.useState();
    const openDeletePopper = Boolean(anchorEl);
    const openingElementPopperID = openDeletePopper ? 'simple-popper' : undefined;


    // Shows popper delete
    const handlerShowDeletePopper = (item: any, event: any) => {
        setAnchorEl(event.currentTarget);
        setDeletedItem(item);
    };

    const { setTripListIsUpdateHandler } = useSession();
    const { enqueueSnackbar } = useSnackbar();


    // Object of arrays with segments of one flights
    const [flight, setFlight] = React.useState<Flight>({ segments: props.openForEdit ? props.segments : [] });

    React.useEffect(() => {
        setFlight({ segments: props.openForEdit ? props.segments : [] });
    }, [props.segments, props.openForEdit]);

    // Close modal window with adding / editing flights
    const handlerClose = () => {
        if (props.openForEdit && isChange) {
            setIsChange(false);
            setTripListIsUpdateHandler();
        }
        props.handleCloseEditFlightDialog();
    };


    const onAddSegmentSuccessfully = () => {
        enqueueSnackbar(tt('successAddSegment'), { variant: 'success' });
    };

    const onEditFlightSuccessfully = () => {
        enqueueSnackbar(
            props.openForEdit ? tt('successSavedFlight') : tt('successAddFlight'),
            { variant: 'success' }
        );
    };


    // Add a new segment to the component SegmentList
    const handlerAddSegmentToFlight = (segment: FlightSegmentDetails) => {
        setServerError(undefined);

        // If it open as editing, then add the segment directly to the server
        if (props.openForEdit) {
            setIsChange(true);
            const segmentObjectForServer = createSegmentObjectToServer(segment);

            flightService.addSegment(props.idFlight, segmentObjectForServer)
                .then(({ id }) => {
                    // Add the segment locally to the list
                    const segments = flight.segments;
                    segment.id = id;
                    segments.push(segment);
                    setFlight({ ...flight, segments });
                })
                .catch((err: ServerError) => {
                    setIsSubmitting(false);
                    setServerError(err);
                });
        }
        else {
            // Add the segment locally to the list
            const segments = flight.segments;
            segments.push(segment);
            setFlight({ ...flight, segments });
        }



    };


    // Delete segment in SegmentList
    const handlerDeleteSegment = (segment: FlightSegmentDetails) => {

        // If it open as editing, then delete the segment directly to the server
        if (props.openForEdit) {
            setIsChange(true);

            flightService.deleteSegment(segment.id)
                .then(() => { })
                .catch(() => {
                    enqueueSnackbar(tt('errorDeleteSegment'), { variant: 'error' });
                });
        }

        // Delete the segment locally to the list
        const segments = flight.segments;
        const index = segments.indexOf(segment);
        segments.splice(index, 1);
        setFlight({ ...flight, segments });
        enqueueSnackbar(tt('successfulDeleteSegment'), { variant: 'success' });

        // Close popper
        setDeletedItem(undefined);
    };


    // Create new flight and segments, or save changes in segments if editing
    const handlerSendDataToServer = () => {
        setIsSubmitting(true);
        setServerError(undefined);

        // If open as edit flight
        if (props.openForEdit) {
            setTripListIsUpdateHandler();
            onEditFlightSuccessfully && onEditFlightSuccessfully();
            setFlight({ segments: [] });
            handlerClose();
            setIsSubmitting(false);
        }

        // If open as add flight
        else {
            const segmentArrayToServer = createSegmentArrayToServer(flight.segments);

            flightService.createFlight(segmentArrayToServer)
                .then((res: any) => {
                    history.push('/');
                    // props.setSelectedFlight(res);
                    setTripListIsUpdateHandler();
                    onEditFlightSuccessfully && onEditFlightSuccessfully();
                    setFlight({ segments: [] });
                    handlerClose();
                    setIsSubmitting(false);
                })
                .catch((err: ServerError) => {
                    setIsSubmitting(false);
                    setServerError(err);
                });
        }

    };


    return (
        <CustomDialog
            maxWidth="xs"
            onClose={handlerClose}
            open={props.openEditFlightDialog}
            scroll="body"
        >
            {/* Title dialog*/}
            <DialogTitle>{tt('dialogTitle')}</DialogTitle>

            <DialogContent>

                {/* Shown a list of segments and a form for adding segments if opened as adding a new flight, or when editing and having segments */}
                {((props.openForEdit && flight.segments.length) || !props.openForEdit) && (
                    <React.Fragment>


                        {/* List of segments in flight */}
                        <Grid className={classes.segmentList}>

                            {/* Shown if there are no segments */}
                            {!flight.segments.length && (
                                <Typography component="p" variant="body1" color="secondary" className={classes.noSegmentsAdded}>
                                    {tt('noSegmentsAdded')}
                                </Typography>
                            )}

                            {/* Shown if there is at least one segment */}
                            {flight.segments.length > 0 && (
                                <React.Fragment>

                                    {flight.segments.map((segment: any, key: any) => {
                                        return (
                                            <SegmentList
                                                key={key}
                                                id={openingElementPopperID}
                                                segment={segment}
                                                handlerShowDeletePopper={handlerShowDeletePopper}
                                            />
                                        );
                                    })
                                    }

                                    {/* Confirm delete segment popper */}
                                    <DeletePopper deletedItem={deletedItem} anchorEl={anchorEl} onClickYes={handlerDeleteSegment} onClickNo={() => setDeletedItem(undefined)} />

                                </React.Fragment>
                            )}

                        </Grid>

                        {/* Form for adding a segment to a flight */}
                        <AddSegmentForm
                            onAddSegmentSuccessfully={onAddSegmentSuccessfully}
                            handlerAddSegmentToFlight={handlerAddSegmentToFlight}
                            handlerSendDataToServer={handlerSendDataToServer}
                            isSubmittingToServer={isSubmitting}
                            flightSegmentsLength={flight.segments.length}
                            openForEdit={props.openForEdit}
                            handlerClose={handlerClose}
                        />


                    </React.Fragment>
                )}


                {/* Shown if open for editing and there are no segments */}
                {props.openForEdit && !flight.segments.length && (
                    <Typography component="p" variant="body1" color="secondary" className={classes.flightCompletelyDeleted}>{tt('flightCompletelyDeleted')}</Typography>
                )}


                {/* Show errors */}
                {serverError &&
                    <Grid container item xs={12} className={classes.buttonAndErrorBlockWrap}>
                        <Alert severity="error">
                            <AlertTitle>{t('error')}</AlertTitle>
                            {serverError.displayMessage}
                        </Alert>
                    </Grid>
                }

            </DialogContent>

        </CustomDialog>
    );
}