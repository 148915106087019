import React, { useEffect } from 'react';
import { useSession } from '../auth/SessionProvider';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as userService from '../../api/user-service';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ServerError } from '../../api/error-service';
import { Alert, AlertTitle } from '@material-ui/lab';
import CustomDatePicker from '../mui-extensions/CustomDatePicker';
import { disableManualDateEntry } from '../../helpers/disableManualDateEntry';
import Form from '../form/Form';

export interface EditPersonProps {
    onCompleted?: () => void;
};

// Check if user is 18 years old
function checkAge(addYears: number = 0) {
    var today = new Date();
    return (
        today.getFullYear() - addYears + '-'
        + ("0" + (today.getMonth() + 1)).slice(-2) + '-'
        + ("0" + today.getDate()).slice(-2)
    );
}

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(2.5, 0, 0),
    },
}));

export default function EditPerson({ onCompleted }: EditPersonProps) {
    const classes = useStyles();
    const REGEXP_NAME = /^[0-9a-zA-Z-\s]+$/; //only Latin / Space / '-' / Number characters

    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`EditPerson.${key}`, options);

    const [serverError, setServerError] = React.useState<ServerError>();
    const [validationEnabled, setValidationEnabled] = React.useState(false);

    const { user, refreshSession } = useSession();

    const [userInfo, setUserInfo] = React.useState({
        firstName: '',
        lastName: '',
        middleName: '',
        birthDate: ''
    });

    const { person } = user || {};

    const { values, touched, errors, setFieldValue, ...formik } = useFormik({
        validateOnChange: validationEnabled,
        validateOnBlur: validationEnabled,
        initialValues: {
            firstName: person?.firstName || '',
            lastName: person?.lastName || '',
            middleName: person?.middleName || '',
            birthDate: userInfo?.birthDate || '',
        },
        validationSchema: Yup.object({
            firstName: Yup.string()
                .required(tt('reuiredFieldError'))
                .max(255, t('fieldIsTooLongError', { max: 255 }))
                .matches(REGEXP_NAME, tt('nameLabelLatinError')),
            lastName: Yup.string()
                .required(tt('reuiredFieldError'))
                .max(255, t('fieldIsTooLongError', { max: 255 }))
                .matches(REGEXP_NAME, tt('nameLabelLatinError')),
            middleName: Yup.string()
                .max(255, t('fieldIsTooLongError', { max: 255 }))
                .matches(REGEXP_NAME, tt('nameLabelLatinError')),
            birthDate: Yup.date()
                .max(checkAge(18), tt('dataError'))
        }),
        onSubmit: async (values) => {
            const person = {
                firstName: values.firstName,
                lastName: values.lastName,
                middleName: values.middleName || undefined,
                birthDate: values.birthDate || undefined,
            };

            if (user && user.person) {
                return userService.editPerson(person, user.person.id)
                    .then(() => {
                        refreshSession(); 
                    })
                    .then(() => {
                        onCompleted && onCompleted();
                    })
                    .catch((err: ServerError) => {
                        setServerError(err);
                    });
            }
            else {
                return userService.createPerson(person)
                    .then(() => {
                        refreshSession();
                    })
                    .then(() => {
                        onCompleted && onCompleted();
                    })
                    .catch((err: ServerError) => {
                        setServerError(err);
                    });
            }
        },
    });

    useEffect(() => {
        const personId = user?.person?.id;
        if (personId ) {
            userService.getInfoPerson(user?.person?.id)
                .then((data) => {  
                    setUserInfo(data);
                    setFieldValue('birthDate', data.birthDate || ''); 
                })
                .catch(() => {

                });
        }
    }, [user, setFieldValue]);

    const handleSubmit = () => {
        setValidationEnabled(true);
        formik.submitForm();
    };

    return (
        <Form>

            {/* Last name edit */}
            <TextField
                margin="normal"
                required
                fullWidth
                label={tt('lastNameLabel')}
                name="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={values.lastName}
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={touched.lastName && errors.lastName}
            />

            {/* First name edit */}
            <TextField
                margin="normal"
                required
                fullWidth
                label={tt('firstNameLabel')}
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={values.firstName}
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName}
            />

            {/* Middle name edit */}
            <TextField
                margin="normal"
                fullWidth
                label={tt('middleNameLabel')}
                name="middleName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={values.middleName}
                error={touched.middleName && Boolean(errors.middleName)}
                helperText={touched.middleName && errors.middleName}
            />

            {/* Birth date edit */}
            {(user && user.person) &&
                <CustomDatePicker
                    margin="normal"
                    fullWidth
                    label={tt('birthDateLabel')}
                    name="birthDate"
                    type="date"
                    onKeyDown={disableManualDateEntry}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        max: "9999-12-31",
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={values.birthDate}
                    error={touched.birthDate && Boolean(errors.birthDate)}
                    helperText={touched.birthDate && errors.birthDate}
                />
            }


            {/* Button submit */}
            <Button
                type="button"
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                color="primary"
                startIcon={formik.isSubmitting && <CircularProgress size={14} />}
                disabled={formik.isSubmitting}
                className={classes.submit}
            >
                {(user && user.person) ? tt('saveBtn') : tt('nextBtn')}
            </Button>


            {
                serverError &&
                <Box mt={2}>
                    <Alert severity="error">
                        <AlertTitle>{t('error')}</AlertTitle>
                        {serverError.displayMessage}
                    </Alert>
                </Box>
            }
        </Form >

    );
}