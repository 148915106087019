import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import * as orderService from '../../api/order-service';
import { useHistory, useParams } from 'react-router-dom';
import { useSession } from '../auth/SessionProvider';

export interface ReceptionCouponInterface {
    persons: [{
        id: number,
        firstName: string,
        lastName: string,
        middleName?: string,
        secret: string,
        status: string
    }],
    passTest: {
        id: number,
        name: string,
        description: string,
        paidAtUtc: string,
        paymentLink: string,
    },
    passOption?: {
        id: number,
        name: string,
        description: string
    },
    organization: {
        id: number,
        name: string
    }
};


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 30
    },
    line: {
        marginBottom: 16,
        '&  h3': {
            marginBottom: 13,
            width: '100%',
        },
        '&  p': {
            width: '100%',
            marginBottom: 12,
            lineHeight: 1.29,
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: 16,
            '&  h3': {
                marginBottom: 4,
            },
            '&  p': {
                marginBottom: 5,
                fontSize: 14
            },
        },
    },
    fellowWrap: {
        marginBottom: '18px !important',
        [theme.breakpoints.down('md')]: {
            marginBottom: '16px !important',
        },
    },
    fellowItem: {
        marginBottom: '0px !important',
        [theme.breakpoints.down('md')]: {
            marginBottom: '4px !important',
        },
    },
    fellowTitle: {
        [theme.breakpoints.down('md')]: {
            marginBottom: '4px !important',
        },
    },
    fellowText: {
        marginBottom: '10px !important',
        [theme.breakpoints.down('md')]: {
            marginBottom: '1px !important',
        },
    },
    recommendationsText: {
        lineHeight: '1.15 !important',
    },
}));


export default function ReceptionCoupon(props: any) {
    const { user } = useSession();
    const history = useHistory();
    if (!user) { history.push('/#signin'); }

    const classes = useStyles();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`ReceptionCoupon.${key}`, options);

    const [receptionCoupon, setReceptionCoupon] = React.useState<ReceptionCouponInterface>();

    let { groupId } = useParams<{ groupId: any }>();


    // Get information on the selected coupon
    useEffect(() => {
        orderService.getGetCurrentRecordForTest(groupId)
            .then((res: ReceptionCouponInterface) => {
                setReceptionCoupon(res);
            })
            .catch();
    }, [groupId]);


    return (
        <Grid container className={classes.root}>

            {/* Coupon number */}
            <Grid container item className={classes.line}>
                <Grid container item sm={12} md={4}>
                    <Typography component="h3" variant="h3" color="primary">{tt('yourNumberTitle')}</Typography>
                </Grid>
                <Grid container item sm={12} md={8}>
                    <Typography component="p" variant="h3" color="primary">
                        {receptionCoupon && receptionCoupon.persons.filter(u => u.id === user?.person?.id).map(u => u.secret)}
                        {!receptionCoupon && `...`}
                    </Typography>
                    <Typography component="p" variant="caption">
                        {tt('yourNumberText1')}
                    </Typography>
                    {receptionCoupon && !receptionCoupon.passTest.paidAtUtc && receptionCoupon.passTest.paymentLink &&
                        <Button color="primary" variant="contained" href={receptionCoupon.passTest.paymentLink}>
                            {tt('checkoutBtn')}
                        </Button>
                    }
                </Grid>
            </Grid>

            {/* Test status */}
            <Grid container item className={classes.line}>
                <Grid container item sm={12} md={4}>
                    <Typography component="h3" variant="h3" color="primary">{tt('statusTitle')}</Typography>
                </Grid>
                <Grid container item sm={12} md={8}>
                    {!receptionCoupon && <Typography component="p" variant="h3">...</Typography>}
                    {receptionCoupon && !receptionCoupon.passTest.paidAtUtc && <Typography component="p" variant="h3">{tt('notPaidStatus')}</Typography>}
                    {receptionCoupon && receptionCoupon.passTest.paidAtUtc && <Typography component="p" variant="h3">{tt('paidStatus')}</Typography>}
                </Grid>
            </Grid>

            {/* Name of the organization where the test is taken */}
            <Grid container item className={classes.line}>
                <Grid container item sm={12} md={4}>
                    <Typography component="h3" variant="h3" color="primary">{tt('placeTakingTestTitle')}</Typography>
                </Grid>
                <Grid container item sm={12} md={8}>
                    <Typography component="p" variant="h3">{receptionCoupon ? receptionCoupon.organization.name : `...`}</Typography>
                </Grid>
            </Grid>

            {/* Test name */}
            <Grid container item className={classes.line}>
                <Grid container item sm={12} md={4}>
                    <Typography component="h3" variant="h3" color="primary">{tt('testTypeTitle')}</Typography>
                </Grid>
                <Grid container item sm={12} md={8}>
                    <Typography component="p" variant="h3">{receptionCoupon ? receptionCoupon.passTest.name : `...`}</Typography>
                    <Typography component="p" variant="body1">{receptionCoupon ? receptionCoupon?.passTest.description : `...`}</Typography>
                </Grid>
            </Grid>

            {/* List of fellow */}
            <Grid container item className={classes.fellowWrap}>
                <Grid container item sm={12} md={4}>
                    <Typography component="h3" variant="h3" color="primary" className={classes.fellowTitle}>{tt('fellowTitle')}</Typography>
                </Grid>
                <Grid container item sm={12} md={8}>

                    {receptionCoupon && receptionCoupon?.persons?.length > 1 &&
                        receptionCoupon?.persons?.map((item: any, key: any) => (
                            <React.Fragment key={key}>
                                {item.id !== user?.person?.id &&
                                    <Grid key={key} container item sm={12} className={classes.fellowItem} >
                                        <Grid container item sm={12} md={7}>
                                            <Typography component="p" variant="body2" className={classes.fellowText}>
                                                {/* Last name */}
                                                {`${item.lastName} `}

                                                {/* First name */}
                                                {`${item.firstName.charAt(0)}. `}

                                                {/* Middle name */}
                                                {item.middleName ? `${item.middleName.charAt(0)}.` : ''}
                                            </Typography>
                                        </Grid>
                                        <Grid container item sm={12} md={5}>
                                            <Typography component="p" variant="body2" color="primary" className={classes.fellowText}>{item.secret}</Typography>
                                        </Grid>
                                    </Grid>
                                }
                            </React.Fragment>
                        ))
                    }

                    {receptionCoupon && receptionCoupon?.persons?.length === 1 &&
                        <Typography component="p" variant="h3" className={classes.fellowText}>
                            {tt('noFellowText')}
                        </Typography>
                    }

                    {!receptionCoupon && `...`}

                </Grid>
            </Grid>

            <Grid container item className={classes.line}>
                <Grid container item sm={12} md={4}>
                    <Typography component="h3" variant="h3" color="primary">{tt('recommendationsTitle')}</Typography>
                </Grid>
                <Grid container item sm={12} md={8}>
                    <Typography component="p" variant="body1" className={classes.recommendationsText}>
                        За 2-3 часа до забора мазков из ротовой и носовой полости не есть, не пить (допускается негазированная вода без добавок), не чистить зубы, не жевать резинку, не полоскать горло, не промывать нос, в том числе лекарственными и антисептическими препаратами.
                        Не курить, в том числе и электронные сигареты, в течение получаса до забора биоматериала.
                        Если у вас повышена температура, а также есть признаки ОРВИ, оставайтесь дома и обратитесь к врачу.
                    </Typography>
                </Grid>
            </Grid>

        </Grid >
    );


}