import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Paper } from '@material-ui/core';
import clsx from 'clsx';
import * as datetimeHelper from '../../../helpers/datetimeHelper';
import { Airport } from '../../../api/info-service.types';

import { ReactComponent as FlightPanelIcon } from '../../icons/FlightPanelIcon.svg';


export interface SegmentCardProps {
    id: number;
    isLast: boolean;
    flightNumber: string;
    departure: {
        at: string,
        airport: Airport
    },
    arrival: {
        at: string,
        airport: Airport
    }
}


const useStyles = makeStyles((theme) => ({
    paper: {
        margin: 'auto',
        border: 'none',
        background: 'inherit',
        color: '#FFFFFF',
        overflow: 'hidden',
        width: '100%',
        marginBottom: 22,
        padding: '9px 10px 9px 10px',
        borderRadius: 0,
        [theme.breakpoints.down(670)]: {
            marginBottom: 11,
            padding: '5px 10px 15px 10px',
        },
    },
    paperLine: {
        borderRight: '1px solid #56618E',
        [theme.breakpoints.down(670)]: {
            borderRight: '0px solid #56618E',
            borderBottom: '1px solid #56618E',
        },
    },
    rootGrid: {
        paddingLeft: 8,
        paddingRight: 8,
        height: '100%',
        justifyContent: "center",
        [theme.breakpoints.down(960)]: {
            justifyContent: "flex-start"
        },
        [theme.breakpoints.down(750)]: {
            justifyContent: "space-between",
        },
    },
    shortName: {
        fontSize: 16,
        fontWeight: 700,
        textTransform: 'uppercase',
        lineHeight: 1.2,
        color: '#FFFFFF',
        overflow: 'hidden',
        textAlign: 'center'
    },
    fullName: {
        fontSize: 11,
        fontWeight: 400,
        marginBottom: 2,
        marginLeft: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        color: '#FFFFFF',
        textAlign: 'center',
        [theme.breakpoints.down(670)]: {
            marginBottom: -1,
        },
    },
    dataTime: {
        color: '#BBC0CF',
        fontSize: 11,
        fontWeight: 400,
        textTransform: 'uppercase',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        textAlign: 'center'
    },
    rootGridItem: {
        width: '100%',
        maxWidth: 78,
        minWidth: 68,
    },
    rootGridItemCenter: {
        paddingTop: 0
    },
    flightIconRace: {
        paddingTop: 0,
        fontSize: 12,
        fontWeight: 700
    },
    AirplaneIcon1: {
        transform: "rotate(90deg)",
        fontSize: 25,
        paddingRight: 0,
        opacity: 0.5
    },
    ArrowRight2: {
        fontSize: 25,
        opacity: 0.5
    },
    flightPanelIcon: {
        width: 50,
    },
}));


export default function SegmentCard(props: SegmentCardProps) {
    const classes = useStyles();

    return (
        <Paper className={clsx(classes.paper, `${!props.isLast ? classes.paperLine : ''}`)} elevation={0} variant="outlined">

            <Grid className={classes.rootGrid} container spacing={0} alignItems="flex-start">

                {/* Departure information */}
                <Grid item xs={4} className={classes.rootGridItem}>
                    <Typography component="p" className={classes.shortName}>{props.departure.airport.code}</Typography>
                    <Typography component="p" className={classes.fullName}>{props.departure.airport.city.name}</Typography>
                    <Typography component="p" className={classes.dataTime}>
                        {datetimeHelper.formatDataTime(props.departure.at)}
                    </Typography>
                </Grid>


                {/* Icon and flight number */}
                <Grid item xs={4} container justify="center" className={classes.rootGridItemCenter}>
                    <Grid item xs={12} container justify="center">
                        <FlightPanelIcon className={classes.flightPanelIcon} />
                    </Grid>
                    <Typography component="p" variant="caption" className={classes.flightIconRace}>{props.flightNumber}</Typography>
                </Grid>


                {/* Arrival information */}
                <Grid item xs={4} className={classes.rootGridItem}>
                    <Typography component="p" className={classes.shortName}>{props.arrival.airport.code}</Typography>
                    <Typography component="p" className={classes.fullName}>{props.arrival.airport.city.name}</Typography>
                    <Typography component="p" className={classes.dataTime}>
                        {datetimeHelper.formatDataTime(props.arrival.at)}
                    </Typography>
                </Grid>

            </Grid>

        </Paper>
    );
}