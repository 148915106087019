import React from 'react';
import { useHistory } from 'react-router-dom';
import { DialogTitle, DialogContent } from '@material-ui/core';
import ChangeEmail from '../../components/profile/ChangeEmail';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../../components/mui-extensions/CustomDialog';

export default function ChangeEmailDialog(props: any) {
    const { t } = useTranslation();
    const history = useHistory();
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        history.replace({
            hash: undefined
        });
    };

    return (
        <CustomDialog maxWidth="xs" onClose={handleClose} open={open}>
            <DialogTitle>{t('ChangeEmail.dialogTitle')}</DialogTitle>
            <DialogContent>
                <ChangeEmail />
            </DialogContent>
        </CustomDialog>
    );
}