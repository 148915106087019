import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    orderTestTypeWrap: {
        padding: '12px 24px 18px 21px',
        width: '100%',
        minHeight: 120,
        borderRadius: 8,
        marginBottom: 0,
        backgroundColor: 'inherit',
        border: '1px solid #E8EAEF',
    },
    activeWrap: {
        backgroundColor: "#F5F7FD",
        border: '1px solid #F5F7FD',
    },
    radioButtonLabel: {
        fontWeight: 700,
        fontSize: 16
    },
    activeLabel: {
        color: theme.palette.primary.main
    },
    icon: {
        borderRadius: '50%',
        width: 22,
        height: 22,
        fontSize: 22,
        color: '#8D95AF',
    },
    checkedIcon: {
        borderRadius: '50%',
        color: '#3766D9',
        fontSize: 22,
        width: 22,
        height: 22,
    },
}));

// Custom Radio Button
function StyledRadio(props: any) {
    const classes = useStyles();

    return (
        <Radio
            checkedIcon={<CheckCircleIcon className={classes.checkedIcon} />}
            icon={<RadioButtonUncheckedIcon className={classes.icon} />}
            {...props}
        />
    );
}

export default function RadioCard(props: any) {
    const classes = useStyles();

    const checked = props.value === props.checkedValue;

    return (
        <Paper elevation={0} className={`${classes.orderTestTypeWrap} ${checked ? classes.activeWrap : ''}`}>
            <FormControlLabel
                value={props.value}
                control={<StyledRadio />}
                disabled
                label={
                    <Typography className={`${classes.radioButtonLabel} ${checked ? classes.activeLabel : ''}`}>
                        {props.label}
                    </Typography>}
            />
            {props.children}
        </Paper>
    );
}