import React from 'react';
import { Grid, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


// FOOTER CARD
const useStyles = makeStyles((theme) => ({
    footerCard: {
        minHeight: 46,
        marginTop: 13,
    },
}));


// FOOTER CARD
export default function ReqCardFooter(props: any) {
    const classes = useStyles();
    return (
        <Grid container justify="space-between" alignItems="center" className={classes.footerCard}>{props.children}</Grid>
    );
}
