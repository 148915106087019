export const disableManualDateEntry = (event: any) => {
    switch (event.keyCode) {
        case 116: // 'F5'
            break;
        default:
            return event.preventDefault();
    }
};


