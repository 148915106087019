import React from 'react';
import { Grid, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


// BODY CARD
const useStyles = makeStyles((theme) => ({
    bodyCard: {
        wordBreak: 'break-word',
        width: '100%',
        marginBottom: 14,
        '& p': {
            lineHeight: 1.3,
            fontWeight: 400,
            marginTop: -1,
        },
        '& ul': {
            fontSize: 14,
            fontWeight: 400,
            paddingLeft: 30,
            marginTop: 8,
            marginBottom: 8,
        },
        '& a': {
            color: theme.palette.primary.main,
            fontWeight: 400,
            textDecoration: 'none'
        },
    },
}));


// BODY CARD
export default function ReqCardBody(props: any) {
    const classes = useStyles();

    return (
        <Grid container className={classes.bodyCard}>
            <div dangerouslySetInnerHTML={{ __html: props.tileDescription }} />
        </Grid>
    );
}
