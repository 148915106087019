import React from 'react';
import { DialogTitle, makeStyles, Button, DialogContent, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Person } from '../../api/user-service.types';
import AddIcon from '@material-ui/icons/Add';
import FellowInfo from './FellowInfo';
import { FlightShortInfo } from '../../api/flight-service.types';
import * as datetimeHelper from '../../helpers/datetimeHelper';
import * as userService from '../../api/user-service';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CustomDialog from '../mui-extensions/CustomDialog';

export interface FellowStatusesDialogProps {
    flight: FlightShortInfo;
    fellows: Person[];
    close: () => void;
    addFellow: () => void;
    editFellow: (fellow: Person) => void;
    onDeleteFellow: (fellow: Person) => void;
};


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(4),
    },
    submit: {
        margin: theme.spacing(1.5, 0, 0),
    },
    flightInfo: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(2),
    },
    arrowIcon: {
        fontSize: '24px',
    },
    fligthDateText: {
        fontSize: '12px',

    }
}));


export default function FellowStatusesDialog({ close, flight, fellows, addFellow, editFellow, ...props }: FellowStatusesDialogProps) {

    const classes = useStyles();
    const { t } = useTranslation();
    //const tt = (key: string, options: any = undefined) => t(`FellowStatusesDialog.${key}`, options);

    const [expandedFellowId, setExpandedFellowId] = React.useState(0);

    const handleClose = () => {
        close();
    };

    return (
        <CustomDialog
            className={classes.root}
            maxWidth="xs"
            fullWidth
            onClose={handleClose}
            open={true}
        >
            <DialogTitle>{t('FellowInfo.dialogTitle')}</DialogTitle>
            <DialogContent>

                <Grid container justify="space-between" alignItems="center" className={classes.flightInfo}>
                    <Grid item>
                        <Grid container alignItems="center">
                            <Typography variant="h3" display="inline">{flight.departure.airport.city.name} {flight.departure.airport.code}</Typography>
                            <ArrowRightAltIcon className={classes.arrowIcon} />
                            <Typography variant="h3" display="inline">{flight.arrival.airport.city.name} {flight.arrival.airport.code}</Typography>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Typography className={classes.fligthDateText}>
                            {datetimeHelper.formatDataTime(flight.departure.at)}
                        </Typography>
                    </Grid>
                </Grid>

                <div>
                    {fellows.sort(userService.compareByFullNameAsc).map((fellow) => (
                        <FellowInfo key={fellow.id} flightId={flight.id} fellow={fellow} editFellow={editFellow} expanded={expandedFellowId === fellow.id} setExpanded={setExpandedFellowId} onDeleteFellow={props.onDeleteFellow} />
                    ))}

                </div>



                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={addFellow}
                    endIcon={<AddIcon />}
                    className={classes.submit}
                >
                    {t('SegmentDetails.addFellowsBtn')}
                </Button>

            </DialogContent>
        </CustomDialog>
    );
}