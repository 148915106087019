import React from 'react';
import { useHistory } from 'react-router-dom';
import { DialogTitle, DialogContent } from '@material-ui/core';
import ResetPassword from '../../components/auth/ResetPassword';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../../components/mui-extensions/CustomDialog';

export default function ResetPasswordDialog(props: any) {
    const { t } = useTranslation();
    const history = useHistory();
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        history.replace({
            hash: undefined
        });
    };

    return (
        <CustomDialog maxWidth="xs" onClose={handleClose} open={open} hideButtonClose={true}>
            <DialogTitle>{t('ResetPassword.dialogTitle')}</DialogTitle>
            <DialogContent>
                <ResetPassword />
            </DialogContent>
        </CustomDialog>
    );
}