import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from '@material-ui/core';
import QuestionnaireTitleOkIcon from '../icons/QuestionnaireTitleOkIcon';
import clsx from 'clsx';


// QUESTIONNAIRE RESULT
export interface QuestionnaireResultInterface {
    questionnaireStatus: boolean;
    handleButtonClick: () => void;
};

const useStyles = makeStyles((theme) => ({
    questionnaire: {
        padding: '36px 36px 40px 36px',
        backgroundColor: 'none',
        [theme.breakpoints.down(670)]: {
            padding: '36px 18px 22px 18px',
        },
    },
    questionnaireTrue: {
        backgroundColor: '#EBF9F3',
    },
    title: {
        marginBottom: 0,
    },
    titleWrap: {
        marginBottom: 40,
    },
    titleStatusTrue: {
        color: '#2CB171',
    },
    titleStatusFalse: {
        color: "#E93131",
    },
    titleStatusTrueIcon: {
        fontSize: 23,
        marginTop: 0,
        marginLeft: 8,
        marginBottom: -10
    },
    button: {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.text.primary,
        boxShadow: 'none',
        height: 46,
        "&:hover": {
            backgroundColor: theme.palette.text.primary,
        },
    },
}));


export default function QuestionnaireResult({ questionnaireStatus, handleButtonClick }: QuestionnaireResultInterface) {
    const classes = useStyles();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`Questionnaire.${key}`, options);

    return (
        <Grid container className={clsx(classes.questionnaire, questionnaireStatus === true ? classes.questionnaireTrue : '')}>
            <Grid container className={classes.titleWrap}>
                {/* Title */}
                <Typography component="h2" variant="h2" className={clsx(classes.title, questionnaireStatus === true ? classes.titleStatusTrue : classes.titleStatusFalse)}>
                    {questionnaireStatus ? tt('questionnaireTrueTitle') : tt('questionnaireFalseTitle')}
                    {questionnaireStatus && <QuestionnaireTitleOkIcon className={classes.titleStatusTrueIcon} />}
                </Typography>
            </Grid>

            {/* Button take the questionnaire again */}
            <Button variant="contained" className={classes.button} onClick={() => handleButtonClick()}>{tt('ButtonReturnQuestionnaireText')}</Button>
        </Grid>
    );

}