import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DialogTitle, DialogContent, Typography, Card, CardContent, Button, Grid, CircularProgress, Box, Hidden, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../mui-extensions/CustomDialog';
import InviteFriendDialog from './InviteFriendDialog';
import { useSession } from '../auth/SessionProvider';
import clsx from 'clsx';
import * as subscriptionService from '../../api/subscription-service';
import { SubscriptionPlan } from '../../api/subscription-service.types';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {},
    dialogContent: {
        overflow: 'visible',
    },
    planCard: {
        border: '1px solid #E8EAEF',
        borderRadius: '8px',
        boxShadow: 'none',
        padding: '24px 20px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    planCardContent: {
        padding: 0,
        marginBottom: '20px',
    },
    planCardActions: {
        padding: 0,
        paddingBottom: '0 !important',
    },
    price: {
        width: '100%',
        marginBottom: '20px',
    },
    topText: {
        marginTop: '7px',
        marginBottom: '24px',
        maxWidth: '676px',
    },
    planDesc: {
        '& ul': {
            paddingLeft: 0,
            '& li': {
                // listStyleImage: `url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9' cy='9' r='9' fill='%233766D9' /%3E%3Cpath d='M4 8.5L7.5 12L14 5.5' stroke='white' stroke-width='2' /%3E%3C/svg%3E%0A")`
                listStyleType: 'none',
                background: `url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9' cy='9' r='9' fill='%233766D9' /%3E%3Cpath d='M4 8.5L7.5 12L14 5.5' stroke='white' stroke-width='2' /%3E%3C/svg%3E%0A") no-repeat left top`,
                padding: '0 0 0 30px',
                marginBottom: '16px',
            }
        }
    },
    planContent: {
        marginBottom: '20px',
    },
    inviteBtn: {
        width: '100%',
    },
    buyBtn: {
        width: '130px',
    },
    primaryPlan: {
        backgroundColor: '#F5F7FD',
    },
    currentPlan: {
        backgroundColor: '#EBF9F3',
    },
    currentPlanButton: {
        backgroundColor: '#3AC482',
        '&:hover': {
            backgroundColor: '#3AC482',
        }
    },
}));

export const planContent = {
    free_for_invite: {
        content: (
            <React.Fragment>
                <Typography variant="body1" component="p">
                    Пригласите друга, чтобы продолжить
                </Typography>
                <Hidden smDown>
                    <Typography variant="body1" component="p">&nbsp;</Typography>
                </Hidden>

                <ul>
                    <li>
                        <Typography variant="body1" component="p">
                            Добавляйте сколько угодно перелетов
                        </Typography>

                    </li>
                    <li>
                        <Typography variant="body1" component="p">
                            Изучайте требования стран в удобном формате
                        </Typography>
                    </li>
                </ul>

            </React.Fragment>
        ),
    },
    optimal: {
        content: (
            <React.Fragment>
                <Typography variant="body1" component="p">
                    Экономит время и дарит спокойствие при подготовке к путешествиям
                </Typography>

                <ul>
                    <li>
                        <Typography variant="body1" component="p">
                            Поможем грамотно подготовиться к перелету по удобному чеклисту
                        </Typography>

                    </li>
                    <li>
                        <Typography variant="body1" component="p">
                            Вовремя напомним сдать тест
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" component="p">
                            Подходит для путешествий с семьей или друзьями
                        </Typography>
                    </li>
                </ul>
            </React.Fragment>

        ),
    },
    all_inclusive: {
        content: (
            <React.Fragment>
                <Typography variant="body1" component="p">
                    Консъерж-сервис для нестандартных случаев и занятых людей
                </Typography>

                <ul>
                    <li>
                        <Typography variant="body1" component="p">
                            Разберемся в нюансах вашего маршрута, подготовим документы с минимально возможным участием вас лично.
                        </Typography>

                    </li>
                </ul>

            </React.Fragment>
        ),
    },
} as any;

const goToAuthMessage = (
    <React.Fragment>
        <Typography>Для продолжения работы необходимо <Link href="#signin" color="textPrimary">Войти</Link> или <Link href="#signup" color="textPrimary">Зарегистрироваться</Link></Typography>
    </React.Fragment>
);


export default function PaymentPlanDialog({ open, setOpen }: { open: boolean; setOpen: (isOpen: boolean) => void; }) {
    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const { user } = useSession();

    const [inviteDialogOpen, setInviteDialogOpen] = React.useState(false);

    const [isLoading, setIsLoading] = React.useState(true);
    const [isLoadingOrderPlanId, setIsLoadingOrderPlanId] = React.useState<Number | null>(null);
    const [planList, setPlanList] = React.useState<SubscriptionPlan[]>([] as SubscriptionPlan[]);

    React.useEffect(() => {
        setIsLoading(true);
        subscriptionService.getPlanList()
            .then((res) => {
                setPlanList(res);
                setIsLoading(false);
            });
    }, []);

    const { t } = useTranslation();

    const goToAuth = () => {
        enqueueSnackbar(goToAuthMessage, { variant: 'info', preventDuplicate: true });
        history.push('#signin');
        setOpen(false);
    };

    const handleOpenInviteDialog = () => {
        if (user) {
            setInviteDialogOpen(true);
        } else {
            goToAuth();
        }
    };

    const handleBuy = (planId: number) => {
        if (user) {
            setIsLoadingOrderPlanId(planId);
            subscriptionService.createOrder(planId)
                .then((order) => {
                    setIsLoadingOrderPlanId(null);
                    window.open(order.paymentLink, '_self');
                })
                .catch((err) => {
                    setIsLoadingOrderPlanId(null);
                    enqueueSnackbar(err.message, { variant: 'error', preventDuplicate: true });
                });
        } else {
            goToAuth();
        }
    };

    return (
        <React.Fragment>
            <CustomDialog
                maxWidth="md"
                onClose={() => setOpen(false)}
                open={open && !inviteDialogOpen}
            >
                <DialogTitle>{t('PaymentPlanDialog.title')}</DialogTitle>
                <DialogContent className={classes.dialogContent}>

                    {isLoading && (
                        <Grid
                            container
                            justify="center"
                            alignItems="center">
                            <Box mt={8}>
                                <CircularProgress size={32} />
                            </Box>
                        </Grid>
                    )}

                    {!isLoading && (
                        <Grid container spacing={3} justify="space-between">
                            {planList.map((plan, index) => (
                                <Grid item xs={12} md={4} key={plan.id}>
                                    <Card className={clsx(classes.planCard, user?.subscriptionType === plan.code ? classes.currentPlan : (index === 1 ? classes.primaryPlan : ''))}>
                                        <CardContent className={classes.planCardContent}>
                                            <Typography gutterBottom variant="h2" component="h2">{plan.name}</Typography>

                                            <div className={classes.planDesc}>
                                                {planContent[plan.code].content}
                                            </div>

                                        </CardContent>
                                        <CardContent className={classes.planCardActions}>
                                            <Typography variant="h3" component="div" className={classes.price}>
                                                {plan.amount ?
                                                    index === 1 ? `${Number(plan.amount)} руб за месяц доступа`: `${Number(plan.amount)} руб за 1 путешествие`
                                                    : 'Бесплатно'}
                                            </Typography>

                                            {user && user.subscriptionType === plan.code && (
                                                <Button variant="contained" color="primary" disableRipple={plan.code !== 'free_for_invite'} className={clsx(classes.inviteBtn, classes.currentPlanButton)}
                                                    onClick={plan.code === 'free_for_invite' ? handleOpenInviteDialog : undefined}>
                                                    Ваш тариф
                                                </Button>
                                            )}

                                            {user?.subscriptionType !== plan.code && plan.code === 'free_for_invite' && (
                                                <Button variant="contained" color="primary" className={classes.inviteBtn} onClick={handleOpenInviteDialog}>
                                                    Пригласить друга
                                                </Button>
                                            )}

                                            {user?.subscriptionType !== plan.code && Number(plan.amount) > 0 && (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.buyBtn}
                                                    startIcon={isLoadingOrderPlanId === plan.id && <CircularProgress size={14} />}
                                                    onClick={() => handleBuy(plan.id)}
                                                >
                                                    Купить
                                                </Button>
                                            )}

                                        </CardContent>
                                    </Card>
                                </Grid>

                            ))}
                        </Grid>
                    )}
                </DialogContent>
            </CustomDialog >

            <InviteFriendDialog open={inviteDialogOpen} closeClick={() => { setInviteDialogOpen(false); setOpen(false); }} backClick={() => setInviteDialogOpen(false)} />
        </React.Fragment>
    );
}