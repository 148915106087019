import axios from 'axios';
import { getServerError } from './error-service';

export const getCountryList = (lang: string | undefined = undefined) =>
    axios.get('/api/country', { params: { lang } })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

export const getAirportByCode = (code: string, lang: string = 'RU') =>
    axios.get('/api/airport', { params: { code, lang } })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

export const searchFlightInfo = (number: string, date: string) =>
    axios.get(`/api/flight/info`, { params: { number, date } })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error, 'AddSegmentForm');
        });
