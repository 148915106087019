import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '../icons/HelpIcon';
import PaymentPlanIcon from '../icons/PaymentPlanIcon';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import logo from '../../images/logo.svg';
import logoWhite from '../../images/logo-white.svg';
import headerBg1 from '../../images/HeaderBg1.png';
import headerBg2 from '../../images/HeaderBg2.png';
import headerBg3 from '../../images/HeaderBg3.png';
import headerBg4 from '../../images/HeaderBg4.png';
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Hidden, Container, TextField } from '@material-ui/core';
import { useSession } from '../auth/SessionProvider';
import { getCurrentLanguageCode } from '../../i18n';
import clsx from 'clsx';
import theme from '../../theme/MainTheme';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import * as infoService from '../../api/info-service';
import { getFullName } from '../../api/user-service';
import { ReactComponent as AccountIcon } from '../icons/AccountIcon.svg';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PaymentPlanDialog from '../payment-plan/PaymentPlanDialog';
import { useDialogState } from '../../providers/DialogProvider';
// import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        paddingTop: theme.spacing(4),
    },
    anonUser: {
        height: 411,
    },
    authUser: {
        marginBottom: theme.spacing(2),
    },
    toolbarTopRow: {
        flex: '1 auto',
    },
    lgContainer: {
        height: '100%',
    },
    toolbarLogoRow: {
        marginTop: -6,

    },
    toolbarTitleWrap: {
        marginTop: '-18px'
    },
    toolbarTitle: {
        flex: 1,
        textAlign: 'left',
    },
    toolbarTitleText: {
        marginLeft: 7,
    },
    toolbarSecondary: {
        justifyContent: 'space-between',
        overflowX: 'auto',
    },
    toolbarLink: {
        padding: theme.spacing(1),
        flexShrink: 0,
    },
    languageMenu: {
        zIndex: 1,
        width: 'inher'
    },
    headerMenuBtn: {
        height: 36,
        // minWidth: '152px',
        border: 'none',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
        [theme.breakpoints.down('sm')]: {
            minWidth: 'auto',
        },
    },
    headerMenuBtnLang: {
        paddingLeft: 12,
        paddingRight: 10,
        height: 36,
        border: 'none',
        [theme.breakpoints.down('sm')]: {
            minWidth: 'auto',
        },
        background: 'rgba(255, 255, 255, 0.2)',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
    },
    profileMenu: {
        zIndex: 1,
    },
    buttonTelepathHelp: {
        borderRadius: 3,
        [theme.breakpoints.down('sm')]: {
            borderRadius: "100%",
            height: 46,
            width: 48
        },
    },
    iconBtn: {
        fontSize: '24px',
        marginTop: -5
    },
    paymentPalnIconBtn: {
        fontSize: '28px',
        marginTop: -5
    },
    bellIconBtn: {
        fontSize: '24px',
        marginTop: -2,
    },
    buttonBellWrap: {
        marginLeft: 1,
        marginRight: 12,
    },
    iconText: {
        fontSize: '16px',
        marginLeft: theme.spacing(1.25),
        whiteSpace: 'nowrap',
    },
    headerProfileBtn: {
        height: 36,
        border: 'none',
        marginLeft: -12,
    },
    headerProfileBtnIcon: {
        fontSize: 34,
        color: "#1C2C5E",
        opacity: .3
    },
    headerProfileBtnTitle: {
        margin: '6px 16px 6px 16px',
        paddingBottom: 16,
        borderBottom: '1px solid rgba(28, 44, 94, 0.15)',
        outline: 'none'
    },
    expandMoreIcon: {
        height: 24,
        width: 24
    },
    selectLang: {
        fontSize: 12,
        fontWeight: 700,
        paddingLeft: 3
    },
    bgContainer: {
    },
    bgImg: {
        zIndex: -1,
        backgroundSize: 'cover',
        backgroundColor: '#1C2C5E',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        position: 'absolute',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',

        animationName: '$backgroundchangeFadeInOut',
        animationTimingFunction: 'ease-in-out',
        animationIterationCount: 'infinite',
        animationDuration: '20s',
    },
    bgImg1: {
        backgroundImage: `url(${headerBg1})`,
        animationDelay: '15s'
    },
    bgImg2: {
        backgroundImage: `url(${headerBg2})`,
        animationDelay: '10s'
    },
    bgImg3: {
        backgroundImage: `url(${headerBg3})`,
        animationDelay: '5s'
    },
    bgImg4: {
        backgroundImage: `url(${headerBg4})`,
        animationDelay: '0s'
    },
    '@keyframes backgroundchangeFadeInOut': {
        '0%': {
            opacity: 1,
        },
        '17%': {
            opacity: 1,
        },
        '25%': {
            opacity: 0,
        },
        '92%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        }
    },
    formRow: {
        flex: 1,
    },
    form: {
        marginTop: theme.spacing(3),
    },
    autocomplete: {
        width: '260px',
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
            minWidth: 180,
        },
        '& .MuiAutocomplete-inputRoot': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        }
    },
    autocompleteBtn: {
        height: '46px',
        width: '200px',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
        },
    },
    aboutMessage: {
        marginTop: theme.spacing(3),
    },
    signIn: {
        marginRight: 10,
    },
    langDisabled: {
        color: '#666666',
    },
    whiteBackgroundSelect: {
        backgroundColor: '#FFFFFF',
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
    },
    menuItemsContainer: {
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'space-between',
        },
    },
    menuItems: {
        [theme.breakpoints.down('xs')]: {
            width: 'inherit',
            marginTop: theme.spacing(1)
        }
    },
    tarifName: {
        fontWeight: 700,
    }
}));

function LanguageMenuItem(props: { children: React.ReactNode; languageCode: string; onItemClick: (languageCode: string) => void; disabled?: boolean }) {
    const classes = useStyles();
    const handleClick = () => {
        props.onItemClick(props.languageCode);
    };

    return (
        <MenuItem onClick={handleClick} className={props.disabled ? classes.langDisabled : ''}>{props.children}</MenuItem>
    );
}

function LanguageMenu(props: any) {
    const language = getCurrentLanguageCode();
    const classes = useStyles();
    const { t } = useTranslation();

    const { enqueueSnackbar } = useSnackbar();

    const [openLanguageMenu, setLanguageMenuOpen] = React.useState(false);
    const languageMenuBtnRef = React.useRef<HTMLButtonElement>(null);

    const setLanguage = (languageCode: string) => {
        i18n.changeLanguage(languageCode);
    };

    const handleLanguageMenuToggle = () => {
        setLanguageMenuOpen((prevOpen) => !prevOpen);
    };

    const handleLanguageMenuClose = (event: React.MouseEvent<Document>) => {
        if (languageMenuBtnRef.current && languageMenuBtnRef.current.contains(event.target as Node)) {
            return;
        }

        setLanguageMenuOpen(false);
    };

    const handleSelectLanguageItem = (languageCode: string) => {
        setLanguage(languageCode);
        setLanguageMenuOpen(false);
    };

    const handleSelectEnLanguage = (languageCode: string) => {
        setLanguageMenuOpen(false);
        enqueueSnackbar('English version is coming soon. Please stay tuned!', { variant: 'info', preventDuplicate: true });
    };

    function handleListKeyDown(event: React.KeyboardEvent<HTMLUListElement>) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setLanguageMenuOpen(false);
        }
    }

    return (
        <React.Fragment>
            <Button ref={languageMenuBtnRef} className={classes.headerMenuBtnLang} variant="outlined" endIcon={<ExpandMoreIcon className={classes.expandMoreIcon} htmlColor={props.htmlLinkColor} />} onClick={handleLanguageMenuToggle}>
                <Hidden smDown>
                    <Typography variant="inherit" component="span" color={props.textColor}>{t('language')}:</Typography>
                </Hidden>
                <Typography component="span" className={classes.selectLang} color={props.linkColor}>{language.toUpperCase()}</Typography>
            </Button>
            <Popper open={openLanguageMenu} anchorEl={languageMenuBtnRef.current} role={undefined} transition disablePortal className={classes.languageMenu}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleLanguageMenuClose}>
                                <MenuList autoFocusItem={openLanguageMenu} onKeyDown={handleListKeyDown}>
                                    <LanguageMenuItem onItemClick={handleSelectLanguageItem} languageCode="ru">Русский</LanguageMenuItem>
                                    <LanguageMenuItem onItemClick={handleSelectEnLanguage} languageCode="en" disabled={true}>English</LanguageMenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>


    );
}

export default function Header(props: any) {
    const { t } = useTranslation();
    let { countryId } = useParams<{ countryId: any }>();

    const history = useHistory();
    const { user } = useSession();

    const paymentPlans = {
        'free_for_invite': 'Бесплатный',
        'optimal': 'Оптимальный',
        'all_inclusive': 'Все включено',
    } as any;

    const classes = useStyles();

    const textColor = user ? 'textPrimary' : 'textSecondary';
    const linkColor = user ? 'primary' : 'textSecondary';
    const htmlTextColor = user ? theme.palette.text.primary : theme.palette.text.secondary;
    const htmlLinkColor = user ? theme.palette.primary.main : theme.palette.text.secondary;

    const [countryList, setCountryList] = React.useState([]);
    const [selectedCountry, setSelectedCountry] = React.useState<any>(null);
    const [planDialogOpen, setPlanDialogOpen] = useDialogState('PaymentPlanDialog', false);

    React.useEffect(() => {
        const language = getCurrentLanguageCode();
        infoService.getCountryList(language)
            .then((items) => {
                setCountryList(items);

            })
            .catch(() => { });
    }, [t]);

    React.useEffect(() => {
        setSelectedCountry(countryList.find((c: any) => c.id.toString() === countryId) || null);
    }, [countryList, countryId]);

    const goToCountry = () => {
        if (!selectedCountry)
            return;

        history.push(`/country/${selectedCountry?.id}`);

    };

    const handleOpenPlanDialog = () => {
        setPlanDialogOpen(true);
    };

    React.useEffect(() => {
        if (window.jivo_api) {
            jivo_api.setContactInfo({
                "name": user?.person ? getFullName(user.person) : " ",
                "email": user?.email ? user.email : " ",
                "phone": user?.phone ? user.phone : " "
            });
        }
    }, [user]);

    return (

        <header className={clsx(classes.root, user ? classes.authUser : classes.anonUser)}>
            {!user && (
                <div className={classes.bgContainer}>
                    <div className={clsx(classes.bgImg, classes.bgImg1)}></div>
                    <div className={clsx(classes.bgImg, classes.bgImg2)}></div>
                    <div className={clsx(classes.bgImg, classes.bgImg3)}></div>
                    <div className={clsx(classes.bgImg, classes.bgImg4)}></div>
                </div>
            )}
            <Container maxWidth="lg" className={classes.lgContainer}>
                <Grid container direction="column" style={{ height: '100%' }}>

                    <Grid container justify="space-between" alignItems="center" className={classes.toolbarLogoRow}>
                        <Grid item className={classes.toolbarTitleWrap}>

                            <Link href="/" className={classes.toolbarTitle} underline="none">
                                <Grid container justify='flex-start' alignItems="baseline">
                                    <img src={user ? logo : logoWhite} alt="logo" />
                                </Grid>
                            </Link>

                        </Grid>

                        <Grid item className={classes.menuItems}>
                            <Grid container className={classes.menuItemsContainer} alignItems="center" direction="row" wrap="nowrap" >

                                <Grid item>
                                    <IconButton disableRipple className={classes.buttonTelepathHelp} onClick={handleOpenPlanDialog}>
                                        <PaymentPlanIcon className={classes.paymentPalnIconBtn} htmlColor={htmlLinkColor} />
                                        <Hidden smDown>
                                            <Typography className={classes.iconText} component="span" color={linkColor}>
                                                {user?.subscriptionType ? 'Тариф: ' : t('Header.paymentPlanLink')}
                                                {user?.subscriptionType && (
                                                    <span className={classes.tarifName}>{paymentPlans[user.subscriptionType]}</span>
                                                )}
                                            </Typography>
                                        </Hidden>
                                    </IconButton>
                                </Grid>


                                <Grid item>
                                    <IconButton disableRipple className={classes.buttonTelepathHelp} onClick={() => {
                                        if (jivo_api.open().opened) document.body.classList.add('jivo-chat-active');
                                    }}>
                                        <HelpIcon className={classes.iconBtn} htmlColor={htmlLinkColor} />
                                        <Hidden smDown>
                                            <Typography className={classes.iconText} component="span" color={linkColor}>{t('Header.telepathHelpLink')}</Typography>
                                        </Hidden>
                                    </IconButton>
                                </Grid>

                                {!user && (
                                    <Grid item>
                                        <IconButton disableRipple color="primary" href="#signin" className={classes.signIn}>
                                            <AccountIcon className={classes.iconBtn} color={htmlLinkColor} />
                                            <Hidden smDown>
                                                <Typography className={classes.iconText} component="span" color={linkColor}>{t('Header.account')}</Typography>
                                            </Hidden>
                                        </IconButton>
                                    </Grid>
                                )}

                                <Grid item>
                                    <LanguageMenu textColor={textColor} htmlTextColor={htmlTextColor} linkColor={linkColor} htmlLinkColor={htmlLinkColor} />
                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>

                    {!user && (
                        <Grid
                            item
                            container
                            alignItems="center"
                            justify="center"
                            style={{ flex: '1 auto' }}
                        >

                            <Grid item xs={12} md={6}>
                                <Typography variant="h1" component="p" color="textSecondary" align="center">{t('Header.message')}</Typography>


                                <Grid container className={classes.form} alignItems="center" justify="center" wrap="nowrap">
                                    <Grid item
                                        className={classes.whiteBackgroundSelect}>
                                        <Autocomplete
                                            className={classes.autocomplete}
                                            filterOptions={createFilterOptions({ matchFrom: 'start', trim: true })}
                                            value={selectedCountry}
                                            options={countryList}
                                            onChange={(e: any, value: any) => {
                                                setSelectedCountry(value);
                                            }}
                                            autoHighlight
                                            getOptionLabel={(option: any) => (option.name ? option.name : "")}
                                            getOptionSelected={(option: any, value: any) => option.id === value.id}
                                            renderOption={(option: any) => (
                                                <React.Fragment>
                                                    {option.name}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t('NoFlight.countryPlaceholder')}
                                                    name="countryName"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'off', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            type="button"
                                            onClick={goToCountry}
                                            variant="contained"
                                            color="primary"
                                            className={classes.autocompleteBtn}
                                        >
                                            {t('NoFlight.submitBtnText')}
                                        </Button>
                                    </Grid>
                                </Grid>

                                <Typography className={classes.aboutMessage} variant="body1" component="p" color="textSecondary" align="center">{t('Header.aboutMessage')}</Typography>

                            </Grid>

                        </Grid>


                    )}
                </Grid>
            </Container>

            <PaymentPlanDialog open={planDialogOpen} setOpen={setPlanDialogOpen} />

        </header >
    );
}