import { FlightInfo } from "../api/info-service.types";
import { getCurrentLanguageCode } from "../i18n";

export const formatDataTime = (date: string | number | undefined) => {
    if (!date)
        return null;

    const lang = getCurrentLanguageCode();

    const tempData = new Date(date);

    const result = tempData.getDate()
        + ' ' + tempData.toLocaleString(lang, { month: 'short' }).toUpperCase().substr(0, 3)
        + ' ' + ("0" + (tempData.getHours())).slice(-2)
        + ':' + ("0" + (tempData.getMinutes())).slice(-2);

    return result;
};

//Formatting a birthDate DD.MM.YYYY
export const formatBirthDate = (birthDate: string) => {
    if (birthDate) {
        return birthDate.replace(/(\d{4})-(\d{2})-(\d{2})/, "$3.$2.$1");
    }
    return null;
};

export const formatTo_yyyy_MM_dd = (date: string) => {
    if (!date)
        return null;

    let dateValue = new Date(date);

    let yyyy = dateValue.getFullYear();
    let mm = dateValue.getMonth() + 1; // getMonth() is zero-based
    let dd = dateValue.getDate();

    return [yyyy,
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd
    ].join('-');
};

// Date formatting [dd MMMM tt:tt] in the parent case 
export const formatTo_dd_MMMM_tt_tt = (date: number | undefined, t: any) => {
    if (!date || date === undefined)
        return null;

    const tt = (key: string, options: any = undefined) => t(`MonthsInGenitive.${key}`, options);
    const allMount = [tt('january'), tt('february'), tt('march'), tt('april'), tt('may'), tt('june'), tt('july'), tt('august'), tt('september'), tt('october'), tt('november'), tt('december')];

    const tempData = new Date(date);

    const result = tempData.getDate()
        + ' ' + allMount[tempData.getMonth()]
        + ' ' + ("0" + (tempData.getHours())).slice(-2)
        + ':' + ("0" + (tempData.getMinutes())).slice(-2);

    return result;
};


// Calculating the time of passing the test for departure
export const getPassinTimeForTest = (option: string, passResultCountdownTime: string, segment: FlightInfo, readyTimeHours: number, passResultValidHours: number, spareHoursBeforeDeparture?: number) => {
    let date = new Date();

    if (passResultCountdownTime === 'departure') date = new Date((segment.departure.at).toString());
    else if (passResultCountdownTime === 'arrival') date = new Date((segment.arrival.at).toString());
    else return;

    switch (option.toLowerCase()) {
        case "from":
            return date
                .setHours(date.getHours() - readyTimeHours - passResultValidHours);
        case "to":
            return date
                .setHours(date.getHours() - readyTimeHours - spareHoursBeforeDeparture!);
        default:
            return;
    }
};