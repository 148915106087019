import React from 'react';
import { Typography, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CardCheckDoneIcon from '../../icons/CardCheckDoneIcon';
import clsx from 'clsx';


// TITLE CARD
const useStyles = makeStyles((theme) => ({
    checkIcon: {
        fontSize: 24,
        marginBottom: -10,
        marginLeft: 10,
    },
    title: {
        wordBreak: 'break-word',
        width: '100%',
        marginBottom: 14,
        [theme.breakpoints.down(600)]: {
            fontSize: 18
        },
    },
    titleStatusPassed: {
        color: '#2CB171',
    },
    titleStatusNotPassed: {
        color: '#E93131',
    },
}));


// TITLE CARD
export default function ReqCardTitle(props: any) {
    const classes = useStyles();
    let cardCheckIconStatus;
    let titleStatus;

    switch (props.cardStatus) {
        case "passed":
            cardCheckIconStatus = <CardCheckDoneIcon className={classes.checkIcon} />;
            titleStatus = classes.titleStatusPassed;
            break;
        case "not_passed":
            cardCheckIconStatus = undefined;
            titleStatus = classes.titleStatusNotPassed;
            break;
        default:
            break;
    }

    return (
        <Typography component="h4" variant="h2" className={clsx(classes.title, titleStatus)}>
            {props.requirement.name}
            {cardCheckIconStatus}
        </Typography >
    );
}
