import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Box, Link } from '@material-ui/core';
import { sendRecoveryCodeOnEmail } from '../../api/user-service';
import { ServerError } from '../../api/error-service';
import { Alert, AlertTitle } from '@material-ui/lab';

export const recoveryErrors = [
    'account_marked_for_deletion',
    'recovery_account_not_found',
    'account_not_deleted',
    'account_does_not_have_email',
    'code_already_sent',
    'confirmation_code_expired'
];

interface ButtonRecoveryParams {
    phone: string,
    formik?: any
}

export default function RecoveryButton({ phone, formik }: ButtonRecoveryParams) {
    const [successSendRecoveryCode, setSuccessSendRecoveryCode] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [serverError, setServerError] = React.useState<ServerError>();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`ConfirmRecoveryCode.${key}`, options);

    const sendRecoveryCode = () => {
        formik.validateForm().then((res: any) => {
            if (!res.phone) {
                sendRecoveryCodeOnEmail("+" + phone.replace(/[^\d]/g, ''))
                    .then((data) => {
                        setEmail(data.email);
                        setSuccessSendRecoveryCode(true);
                    })
                    .catch((err: ServerError) => {
                        setServerError(err);
                        setSuccessSendRecoveryCode(false);
                    });
            }
        });
    };

    return (
        <>
            <Button
                type="button"
                onClick={() => sendRecoveryCode()}
                fullWidth
                variant="contained"
                color="primary"
                style={{ marginTop: '16px' }}
            >
                {t('ConfirmRecoveryCode.buttonRecoveryAccount')}
            </Button>
            {serverError && <Box mt={2}>
                <Alert severity="error">
                    <AlertTitle>{t('error')}</AlertTitle>
                    {serverError.displayMessage}
                    {serverError.reason === 'code_already_sent' &&
                        <><br />
                            {tt('recoveryDialogSupportText')}
                            <Link
                                style={{ 'cursor': 'pointer' }}
                                onClick={() => { if (jivo_api.open().opened) document.body.classList.add('jivo-chat-active'); }}>
                                {tt('supportLinkText')}
                            </Link>
                        </>}
                </Alert>
            </Box>}
            {successSendRecoveryCode && <Box mt={2}>
                <Alert severity="success">
                    <AlertTitle>{tt('sendRecoveryCodeOnEmailTitle')}</AlertTitle>
                    {tt('sendRecoveryCodeOnEmailText', { email: email })}
                </Alert>
            </Box>}
        </>
    );
}

