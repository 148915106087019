import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ButtonCardCouponIcon from '../../icons/ButtonCardCouponIcon';
import { useHistory } from 'react-router-dom';
import { useSession } from '../../auth/SessionProvider';


// ACTION BUTTON 
const useStyles = makeStyles(() => ({
    button: {
        height: 36,
        paddingRight: 14,
        paddingLeft: 14,
        marginBottom: 10,
        marginRight: 5,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    },
}));


// ACTION BUTTON 
export default function ReqCardButtonAction(props: any) {
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`Cards.${key}`, options);

    const { user } = useSession();
    const history = useHistory();

    // template
    function ButtonLinkCustomTemplate({ action, textButton, iconButton }: any) {
        const classes = useStyles();

        const handleButtonClick = (action?: any) => {
            switch (props.requirement.type) {
                case "test":
                    if (typeof(action) === "string") history.push(action);
                    else action();
                    break;
                case "info":
                    window.open(action);
                    break;
                case "rule":
                    action();
                    break;
                default:
                    break;
            }
        };

        return (
            <Button color="primary" variant="contained" className={classes.button} onClick={() => handleButtonClick(action)}>
                {iconButton}{textButton}
            </Button>
        );
    }

    let buttonLinkCustom;
    switch (props.cardStatus) {
        case "passed":
            switch (props.requirement.type) {
                case "rule":
                    buttonLinkCustom = <ButtonLinkCustomTemplate
                        action={() => props.setOpenQuestionnaireDialog(true)}
                        textButton={t('Questionnaire.buttonTakeQuestionnaireAgain')}
                    />;
                    break;
                default:
                    buttonLinkCustom = undefined;
                    break;
            }
            break;
        case "not_passed":
            switch (props.requirement.type) {
                case "rule":
                    buttonLinkCustom = <ButtonLinkCustomTemplate
                        action={() => props.setOpenQuestionnaireDialog(true)}
                        textButton={t('Questionnaire.buttonTakeQuestionnaireAgain')}
                    />;
                    break;
                case "test":
                    buttonLinkCustom = <ButtonLinkCustomTemplate textButton={tt('buttonTest')} />;
                    break;
                default:
                    buttonLinkCustom = undefined;
                    break;
            }
            break;
        case "pending": case "not_requested": case "not_paid":
            switch (props.requirement.type) {
                case "test":
                    buttonLinkCustom =
                        <ButtonLinkCustomTemplate
                            action={`/#reception-coupon/${props.requirement.id}`}
                            textButton={tt('buttonCoupon')}
                            iconButton={<ButtonCardCouponIcon />}
                        />;
                    break;
                default:
                    buttonLinkCustom = undefined;
                    break;
            }
            break;
        default:
            switch (props.requirement.type) {
                case "test":
                    buttonLinkCustom = <ButtonLinkCustomTemplate
                        action={user ? props.segmentId ? `/order-test/${props.segmentId}/${props.requirement.id}` : ()=>props.showErrorDialog(tt('dialogErrorTitleTest'), tt('dialogErrorMessageTest')) : '#signin'}
                        textButton={tt('buttonTest')}
                    />;
                    break;
                case "info":
                    buttonLinkCustom = <ButtonLinkCustomTemplate
                        action={props.requirement.extUrl}
                        textButton={props.requirement.extActionName}
                    />;
                    break;
                case "rule":
                    buttonLinkCustom = <ButtonLinkCustomTemplate
                        action={user ? () => props.setOpenQuestionnaireDialog(true) : () => { history.push('#signin'); }}
                        textButton={props.requirement.extActionName}
                    />;
                    break;
                default:
                    buttonLinkCustom = undefined;
                    break;
            }
            break;
    }

    return (<React.Fragment> {buttonLinkCustom} </React.Fragment>);
}
