import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import QuestionCard from './QuestionCard';
import { QuestionInterface } from '../../api/rule-service.types';


// PASSING QUESTIONNAIRE
export interface PassingQuestionnaireInterface {
    question: QuestionInterface | undefined,
    groupId: number,
    setAnswerToQuestion: (groupId: number, stepId: number, answer: boolean) => void;
};

const useStyles = makeStyles((theme) => ({
    questionnaire: {
        padding: '36px 36px 40px 36px',
        [theme.breakpoints.down(670)]: {
            padding: '36px 18px 22px 18px',
        },
    },
    title: {
        marginBottom: 20,
    },
    descriptionText: {
        lineHeight: 1.3,
        marginBottom: 22,
    },
}));


export default function PassingQuestionnaire({ question, groupId, setAnswerToQuestion }: PassingQuestionnaireInterface) {
    const classes = useStyles();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`Questionnaire.${key}`, options);

    return (
        <Grid container className={classes.questionnaire}>
            <Typography component="h2" variant="h2" className={classes.title}>{tt('titleText')}</Typography>
            <Typography component="p" variant="body1" className={classes.descriptionText}>{tt('descriptionText1')}</Typography>

            {/* Question card */}
            <QuestionCard
                question={question}
                groupId={groupId}
                setAnswerToQuestion={setAnswerToQuestion}
            />
        </Grid>
    );

}