import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Button, ClickAwayListener, IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Popper from '@material-ui/core/Popper';
import { useTranslation } from 'react-i18next';
import { useSession } from '../auth/SessionProvider';


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 15,
    },
    fellowOwnerWrap: {
        backgroundColor: "#F5F7FD",
        padding: "0px 16px 0px 8px",
        color: theme.palette.primary.main,
        marginBottom: 6,
        borderRadius: 5,
    },
    fellowOwner: {
        padding: "7px 5px 7px 4px",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontWeight: 700,
    },
    fellowWrap: {
        backgroundColor: "#F5F7FD",
        marginBottom: 6,
        borderRadius: 5,
        padding: "0px 16px 0px 8px",
        transition: '.2s linear',
        "&:hover": {
            color: theme.palette.primary.main,
        },
    },
    fellowTextWrap: {
        padding: "7px 5px 7px 4px",
        overflow: 'hidden',
    },
    fellowText: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    fellowTextPrice: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    closeBtn: {
        padding: '6px 6px',
        marginLeft: 4
    },
    closeBtnI: {
        fontSize: 21,
        color: '#1C2C5E',
        opacity: .5
    },
    popperConfirm: {
        width: '100%',
        maxWidth: 276,
        padding: '16px 18px',
        borderRadius: 10,
        backgroundColor: '#ffffff',
        boxShadow: '0px 2px 6px -2px #D3D3D3'
    },
    popperConfirmText: {
        fontWeight: 700,
        lineHeight: 1.4
    },
    confirmBtn: {
        height: 32,
        width: 80,
        marginTop: 12,
        marginRight: 6
    }
}));


// POPPER confirm component from delete fellow
function DeletePopper({ item, anchorEl, onClickYes, onClickNo }: any) {
    const classes = useStyles();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`FellowItemComponent.${key}`, options);


    if (!item)
        return null;


    return (
        <ClickAwayListener onClickAway={onClickNo}>

            <Popper
                open={true}
                anchorEl={anchorEl}
                placement="top-end"
                disablePortal={false}
                className={classes.popperConfirm}>

                <Typography component="p" className={classes.popperConfirmText}>
                    {tt('popperText1')}
                    <Typography component="span" variant="body2" color="primary">{item.name}</Typography>
                    {tt('popperText2')}
                </Typography>

                <Grid container>
                    {/* Button YES */}
                    <Button
                        onClick={() => { onClickYes(item); }}
                        variant="contained"
                        color="primary"
                        className={classes.confirmBtn}>
                        {tt('btnYes')}
                    </Button>

                    {/* Button NO */}
                    <Button
                        onClick={onClickNo}
                        variant="outlined"
                        color="primary"
                        className={classes.confirmBtn}>
                        {tt('btnNo')}
                    </Button>
                </Grid>

            </Popper>

        </ClickAwayListener>
    );
}


export default function FellowList(props: any) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState();
    const [deletedItem, setDeletedItem] = React.useState();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`OrderTestLab.${key}`, options);

    const { user } = useSession();

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;


    // Button delete in item
    const handleIconButtonDeleteClick = (item: any, event: any) => {
        setAnchorEl(event.currentTarget);
        setDeletedItem(item);
    };


    // Popper component hendler from delete fellows
    const handlePopperButtonDeleteClick = (item: any) => {
        props.removeFellowFromList(item);
        setDeletedItem(undefined);
    };


    return (
        <Grid item md={12}
            container
            justify="flex-start"
            className={classes.root}>

            {/* Name owner and price */}
            {props.orderFellowList.map((item: any, key: any) => (
                <React.Fragment key={key}>
                    {item.id === user?.person?.id &&
                        <Grid item container xs={12} className={classes.fellowOwnerWrap}>
                            <Grid item container xs={7}>
                                <Typography component="p" className={classes.fellowOwner}>
                                    {/* Last name */}
                                    {`${item.lastName} `}

                                    {/* First name */}
                                    {`${item.firstName.charAt(0)}. `}

                                    {/* Middle name */}
                                    {item.middleName ? `${item.middleName.charAt(0)}.` : ''}
                                </Typography>
                            </Grid>

                            <Grid item container xs={4} justify="flex-end">
                                <Typography component="p" className={classes.fellowOwner}>
                                    {`${props.price ? parseInt(props.price, 10) : ""} `}
                                    {props.price ? tt('totalCostCurrency') : ""}
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                </React.Fragment>
            ))}

            {/* Fellows */}
            {props.orderFellowList.map((item: any, key: any) => (
                <React.Fragment key={key} >
                    {item.id !== user?.person?.id &&
                        <Grid
                            item
                            container
                            justify="flex-start"
                            alignItems="flex-start"
                            className={classes.fellowWrap}
                            key={key} >

                            {/* Fellow name */}
                            <Grid item container xs={7} className={classes.fellowTextWrap}>
                                <Typography component="p" className={classes.fellowText}>
                                    {/* Last name */}
                                    {`${item.lastName} `}

                                    {/* First name */}
                                    {`${item.firstName.charAt(0)}. `}

                                    {/* Middle name */}
                                    {item.middleName ? `${item.middleName.charAt(0)}.` : ''}
                                </Typography>
                            </Grid>

                            {/* Fellow price */}
                            <Grid item container xs={4} className={classes.fellowTextWrap} justify="flex-end">
                                <Typography component="p" className={classes.fellowTextPrice}>
                                    {`${props.price ? parseInt(props.price, 10) : ""} `}
                                    {props.price ? tt('totalCostCurrency') : ""}
                                </Typography>
                            </Grid>

                            {/* Button delete fellow */}
                            <Grid item container xs={1}>
                                <IconButton
                                    color="primary"
                                    aria-label="close icon"
                                    component="span"
                                    className={classes.closeBtn}
                                    aria-describedby={id}
                                    onClick={(event: any) => { handleIconButtonDeleteClick(item, event); }}>
                                    <CloseIcon className={classes.closeBtnI} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    }
                </React.Fragment>
            ))}


            {/* Popper component. Confirm dialog delete fellow */}
            < DeletePopper item={deletedItem} anchorEl={anchorEl} onClickYes={handlePopperButtonDeleteClick} onClickNo={() => setDeletedItem(undefined)} />


        </Grid >
    );
}