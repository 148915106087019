import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Accordion, AccordionSummary, AccordionDetails, Grid, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as userService from '../../api/user-service';
import { Person } from '../../api/user-service.types';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@material-ui/icons/Check';
import * as flightService from '../../api/flight-service';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: theme.spacing(1),
        borderRadius: '6px',
        border: '1px solid rgba(28, 44, 94, 0.3)',
        boxShadow: 'none',
        '&:first-child': {
            borderRadius: '6px',
        },
        '&:last-child': {
            borderRadius: '6px',
        },
        '&$expanded': {
            marginBottom: theme.spacing(1),
        },
        '&::before': {
            background: 'none',
        },
    },
    stepStatus: {
        fontSize: '13px',
        color: theme.palette.text.secondary,
        marginLeft: 'auto',
    },
    greenCheckIcon: {
        fontSize: '20px',
        color: '#3AC582',
    },
    completedText: {
        fontSize: '15px',
        fontWeight: 400,
    },
    grayCheckIcon: {
        fontSize: '20px',
        color: '#1C2C5E',
        opacity: 0.3
    },
    pendingText: {
        fontSize: '15px',
        fontWeight: 400,
        color: '#1C2C5E',
        opacity: 0.3
    },
    deleteBtn: {
        color: theme.palette.error.main,
    },
    inviteBtn: {
        color: theme.palette.success.main,
    },
    contactsGrid: {
        marginBottom: theme.spacing(1),
    }
}));

export interface FellowInfoProps {
    flightId: number;
    fellow: Person;
    expanded: boolean;
    setExpanded: (fellowId: number) => void;
    editFellow: (fellow: Person) => void;
    onDeleteFellow: (fellow: Person) => void;
};

const checkList = [
    { done: false, text: 'Тест на COVID-19' },
    { done: true, text: 'Анкета о здоровье' },
    { done: true, text: 'Кому разрешен въезд' },
    { done: true, text: 'Страховка от невылета' },
];

export default function FellowInfo({ flightId, fellow, editFellow, ...props }: FellowInfoProps) {
    const classes = useStyles();

    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`FellowInfo.${key}`, options);
    const { enqueueSnackbar } = useSnackbar();

    const handleChangeExpanded = (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        props.setExpanded(isExpanded ? fellow.id : 0);
    };

    const totalStepCount = 4;
    const completedStepCount = 3;

    const handleDeleteClick = () => {
        flightService.deleteFellow(flightId, fellow.id)
            .then(() => {
                enqueueSnackbar(tt('deleteSuccessMessage'), { variant: 'success' });
                props.onDeleteFellow(fellow);
            });
    };

    const handleEditClick = () => {
        editFellow(fellow);
    };

    return (
        <Accordion expanded={props.expanded} onChange={handleChangeExpanded} className={classes.root} >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography variant="body2">{userService.getPersonName(fellow)}</Typography>
                <Typography variant="body1" className={classes.stepStatus}>{t('stepProgressLabel', { completedStepCount, totalStepCount })}</Typography>
            </AccordionSummary>
            <AccordionDetails>

                {(fellow.email || fellow.phone) && (
                    <Grid container spacing={1} className={classes.contactsGrid}>
                        <Grid item>
                            <Typography variant="body1">{fellow.email}</Typography>

                        </Grid>
                        <Grid item>
                            <Typography variant="body1">{userService.formatPhone(fellow.phone)}</Typography>
                        </Grid>
                    </Grid>
                )}

                {checkList.map((checkItem, key) => (
                    <Grid container spacing={1} key={key}>
                        <Grid item>
                            <CheckIcon className={checkItem.done ? classes.greenCheckIcon : classes.grayCheckIcon} />
                        </Grid>
                        <Grid item>
                            <Typography className={checkItem.done ? classes.completedText : classes.pendingText} variant="body1">{checkItem.text}</Typography>
                        </Grid>
                    </Grid>


                ))}


                <Grid container spacing={2}>
                    <Grid item>
                        <Button onClick={handleEditClick}>{tt('buttonEdit')}</Button>
                    </Grid>
                    <Grid item>
                        <Button className={classes.deleteBtn} onClick={handleDeleteClick}>{tt('buttonDelete')}</Button>
                    </Grid>
                    <Grid item>
                        <Button className={classes.inviteBtn}>{tt('buttonInvite')}</Button>
                    </Grid>
                </Grid>



            </AccordionDetails>
        </ Accordion>
    );
}