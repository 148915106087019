import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography, withStyles, Tooltip } from '@material-ui/core';
import * as flightService from '../../api/flight-service';
import { useParams } from 'react-router-dom';
import SegmentCards from '../flight/requirements/SegmentCards';
import BackButton from '../layout/BackButton';
import { useTranslation } from 'react-i18next';
import { getCurrentLanguageCode } from '../../i18n';
import * as infoService from '../../api/info-service';
import TransitSegmentCards from '../flight/requirements/TransitSegmentCards';
import TransitSegmentCardIcon from '../icons/TransitSegmentCardIcon';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
    },
    title: {
        marginTop: theme.spacing(1),
    },
    transitSegmentRequirementWrap: {
        marginTop: theme.spacing(1),
        paddingLeft: 40,
        position: 'relative',
    },
    transitSegmentCardIconWrap: {
        position: 'absolute',
        left: 0,
        width: 40,
        height: 40,
    },
    transitSegmentCardIcon: {
        width: 42,
        height: 42,
    },
    transitSegmentCardWrap: {
        marginTop: 7,
    },
    tooltip: {
        boxShadow: '0px 10px 60px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    }
}));

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.primary,
        boxShadow: '0px 10px 60px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
        padding: '8px 13px',
        fontSize: 12,
        borderRadius: 56,
    },
}))(Tooltip);

export default function CountryRequirements(props: any) {
    const classes = useStyles();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`SegmentDetails.${key}`, options);

    let { countryId } = useParams<{ countryId: any }>();

    const [countryList, setCountryList] = React.useState<any[]>([]);
    const [listRequirement, setListRequirement] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        const language = getCurrentLanguageCode();
        infoService.getCountryList(language)
            .then((items) => {
                setCountryList(items);
            })
            .catch(() => { });
    }, [t]);

    useEffect(() => {
        setIsLoading(true);
        flightService.getListRequirementByCountry(countryId)
            .then((res) => {
                setListRequirement(res);
                setIsLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setIsLoading(false);
            });
    }, [countryId]);

    return (
        <Container className={classes.root} disableGutters>

            <BackButton />

            <React.Fragment>
                <Typography component="h2" variant="h1" className={classes.title}>
                    {(!countryList.length || isLoading) ? <Skeleton variant='text' /> : `${t('Landing.requirementsTitle')}: ${countryList.find((c: any) => c.id.toString() === countryId).name}`}
                </Typography>

                <Grid container className={classes.transitSegmentRequirementWrap}>
                    <Grid item className={classes.transitSegmentCardIconWrap} >
                        <LightTooltip title={tt('tooltipTitle')} placement="top">
                            <div>
                                {isLoading ? <Skeleton variant="circle" width={30} height={30} /> : <TransitSegmentCardIcon className={classes.transitSegmentCardIcon} />}
                            </div>
                        </LightTooltip >
                    </Grid>

                    <Grid item className={classes.transitSegmentCardWrap}>
                        {isLoading ? <Skeleton variant='text' width={200} /> : <TransitSegmentCards
                            requirementList={listRequirement.filter((item: any) => item.transitRule)}
                        />}
                    </Grid>
                </Grid>

                <SegmentCards segmentRequirement={listRequirement.filter((item: any) => !item.transitRule)} segmentId={null} segmentRequirementIsLoad={isLoading} />

            </React.Fragment>
        </Container>
    );
}

