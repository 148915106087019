import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Grid, Typography, } from '@material-ui/core';
import { QuestionInterface } from '../../api/rule-service.types';
import clsx from 'clsx';
import QuestionnaireButtonOkIcon from '../icons/QuestionnaireButtonOkIcon';

// QUESTION CARD
export interface QuestionCardInterface {
    question: QuestionInterface | undefined,
    groupId: number,
    setAnswerToQuestion: (groupId: number, stepId: number, answer: boolean) => void;
};


const useStyles = makeStyles((theme) => ({
    questionCard: {
        border: '1px solid #E8EAEF',
        borderRadius: 8,
        padding: '26px 22px 30px 24px',
        [theme.breakpoints.down(670)]: {
            padding: '36px 18px 8px 18px',
        },
    },
    bodyCard: {
        position: 'relative',
        paddingRight: 220,
        minHeight: 38,
        [theme.breakpoints.down(670)]: {
            paddingRight: 0,
            minHeight: 0,
        },
    },
    numberQuestionsText: {
        width: '100%',
        color: theme.palette.secondary.main,
        marginBottom: 11,
    },
    questionText: {
        fontWeight: 400,
        lineHeight: 1.3
    },
    blockButtons: {
        display: 'block',
        position: 'absolute',
        right: 0,
        top: 0,
        textAlign: 'right',
        [theme.breakpoints.down(670)]: {
            position: 'relative',
            width: '100%',
            marginTop: 26,
            textAlign: 'center',
        },
    },
    button: {
        width: 94,
        height: 46,
        boxShadow: 'none',
        color: theme.palette.text.secondary,
        [theme.breakpoints.down(670)]: {
            width: '49%',
        },
        [theme.breakpoints.down(400)]: {
            width: '100%',
        },
    },
    buttonNo: {
        backgroundColor: theme.palette.text.primary,
        "&:hover": {
            backgroundColor: theme.palette.text.primary,
        },
        [theme.breakpoints.down(670)]: {
            marginBottom: 16,
            marginRight: '1%',
        },
        [theme.breakpoints.down(400)]: {
            marginRight: 0,
        },
    },
    buttonYes: {
        marginLeft: 12,
        backgroundColor: '#3AC582',
        "&:hover": {
            backgroundColor: '#3AC582',
        },
        [theme.breakpoints.down(670)]: {
            marginBottom: 16,
            marginLeft: '1%',
        },
        [theme.breakpoints.down(400)]: {
            marginLeft: 0,
        },
    },
    buttonYesIcon: {
        fontSize: 22,
        marginTop: 8
    },
}));


export default function QuestionCard({ question, groupId, setAnswerToQuestion }: QuestionCardInterface) {
    const classes = useStyles();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`Questionnaire.${key}`, options);

    return (
        <Grid container className={classes.questionCard}>

            <Grid container className={classes.bodyCard}>

                {/* If you have a question - show */}
                {question &&
                    <Typography component="p" variant="h4" className={classes.questionText}>
                        {question.name}
                    </Typography>
                }

                {/* If there is no question - show the loader */}
                {!question &&
                    <Grid
                        container
                        justify="center"
                        alignItems="center">
                        <Box mt={1} >
                            <CircularProgress size={24} />
                        </Box>
                    </Grid>
                }

                <Grid container item className={classes.blockButtons}>
                    {/* Button NO */}
                    <Button
                        variant="contained"
                        className={clsx(classes.button, classes.buttonNo)}
                        onClick={() => setAnswerToQuestion(groupId, question!.id, false)}
                        disabled={!question}
                    >
                        {tt('ButtonNoText')}
                    </Button>

                    {/* Button YES */}
                    <Button
                        variant="contained"
                        className={clsx(classes.button, classes.buttonYes)}
                        onClick={() => setAnswerToQuestion(groupId, question!.id, true)}
                        disabled={!question}
                    >
                        <QuestionnaireButtonOkIcon className={classes.buttonYesIcon} />{tt('ButtonYesText')}
                    </Button>
                </Grid>

            </Grid>
        </Grid>
    );
}
