import React from 'react';
import { useHistory } from 'react-router-dom';
import { DialogTitle, DialogContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../../components/mui-extensions/CustomDialog';
import ReceptionCoupon from '../../components/reception-coupon/ReceptionCoupon';

export default function ReceptionCouponDialog(props: any) {
    const { t } = useTranslation();
    const history = useHistory();
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        history.replace({
            hash: undefined
        });
    };

    return (
        <CustomDialog maxWidth="sm" onClose={handleClose} open={open}>
            <DialogTitle>{t('ReceptionCoupon.dialogTitle')}</DialogTitle>
            <DialogContent>
                <ReceptionCoupon />
            </DialogContent>
        </CustomDialog>
    );
}