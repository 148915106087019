import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ButtonBackIcon from '../../components/icons/ButtonBackIcon';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        fontWeight: 'normal',
        padding: '0px 4px 0px 0px',
        fontSize: 14,
        textTransform: 'none',
        opacity: .5,
    },
    btnBackIcon: {
        marginTop: 16,
        marginLeft: 3,
        marginRight: -9,
        '&.MuiSvgIcon-root': {
            fontSize: '24px',
        }
    },
}));

export default function BackButton(props: { onClick?: () => void }) {
    const history = useHistory();

    const classes = useStyles();
    const { t } = useTranslation();

    const handleButtonBack = () => {
        if (props.onClick) {
            props.onClick();
        } else {
            history.push('/');
        }
    };

    return (
        <Button
            className={classes.root}
            startIcon={<ButtonBackIcon className={classes.btnBackIcon} />}
            onClick={handleButtonBack}>
            {t('common.backBtnLabel')}
        </Button>
    );
}