import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { YMaps, Map, Placemark, Clusterer } from 'react-yandex-maps';
import PlacemarkItem from './PlacemarkItem';


const useStyles = makeStyles(() => ({
    root: {},
    mapWrap: {
        backgroundColor: "#f6f6f6",
        width: '100%',
        height: 520,
        marginBottom: 35,
        overflow: 'hidden',
        borderRadius: 8,
    },
    map: {
        width: '100%',
        height: 520,
    },
}));


export default React.memo(function LabOnMap(props: any) {

    const classes = useStyles();
    let myMap = React.useRef<any>();

    const mapDefaultOptions = {
        center: props.currentPosition,
        zoom: 9,
    };

    // Sets a new center on the map (centers the map)
    useEffect(() => {
        if (props.newCenterOnMap.length === 0 || props.newCenterOnMap[0] === "")
            return;

        myMap.current.setCenter(props.newCenterOnMap, 9, {
            checkZoomRange: true,
            duration: 1000
        });
    }, [props.newCenterOnMap]);


    return (
        <YMaps className={classes.mapWrap} version={"2.1"}>

            <Map
                defaultState={mapDefaultOptions}
                instanceRef={(ref: any) => { myMap.current = ref; }}
                className={classes.map}
                modules={["templateLayoutFactory", "layout.ImageWithContent"]}
            >

                <Clusterer options={{ preset: 'islands#darkBlueClusterIcons', groupByCoordinates: false }}>

                    <Placemark
                        geometry={mapDefaultOptions.center}
                        className={'test'}
                        properties={{ iconContent: 'Я' }}
                        options={{
                            preset: "islands#redStretchyIcon",
                        }} />

                    {props.listLaboratoriesAtOrganization && (
                        props.listLaboratoriesAtOrganization.units.map((lab: any) => {
                            return (
                                <PlacemarkItem lab={lab} key={lab.id} organizationId={props.listLaboratoriesAtOrganization.id} />
                            );
                        })
                    )}

                </Clusterer>

            </Map>

        </YMaps >
    );


});