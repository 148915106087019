import { Backdrop, CircularProgress } from '@material-ui/core';
import React from 'react';
import { useHistory } from "react-router-dom";
import { ServerError } from '../../api/error-service';
import * as userService from '../../api/user-service';

const deleteFromCookie = (key: string) => {
    document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
};

interface Person {
    firstName: string;
    lastName: string;
    middleName?: string;
    birthDate?: string;
    id: number;
}

interface User {
    id: number;
    email?: string;
    isEmailConfirmed?: boolean;
    phone?: string;
    person?: Person;
    referralCode: string;
    subscriptionType?: string;
}

export interface AuthSession {
    user?: User,
    error?: Error;
    isLoading: boolean;
    isReady: boolean;
    documentsIsUpdate?: number;
    tripListIsUpdate?: number;
    invitedByReferralCode: string | null | undefined;
}

export const initialSession: AuthSession = {
    invitedByReferralCode: undefined,
    isLoading: true,
    isReady: false,
    documentsIsUpdate: 0,
    tripListIsUpdate: 0,
};

export interface SessionContextInterface extends AuthSession {
    refreshSession: () => Promise<User>;
    signOut: () => void;
    setDocumentsIsUpdateHandler: () => void;
    setTripListIsUpdateHandler: () => void;
}

const stub = (): never => {
    throw new Error('You forgot to wrap your component in <SessionProvider>.');
};

const initialContext = {
    ...initialSession,
    refreshSession: stub,
    signOut: stub,
    setDocumentsIsUpdateHandler: stub,
    setTripListIsUpdateHandler: stub,
};

export const SessionContext = React.createContext<SessionContextInterface>(initialContext);

export interface SessionProviderOptions {
    children?: React.ReactNode;
    authTokenKeyInCookie?: string;
    invitedByReferralCode?: string | null | undefined;
}

export const SessionProvider = ({ children, authTokenKeyInCookie = 'token', invitedByReferralCode }: SessionProviderOptions): JSX.Element => {
    const [user, setUser] = React.useState<User>();
    const [error, setError] = React.useState<ServerError>();
    const [isLoading, setIsLoading] = React.useState(false);
    const [isReady, setIsReady] = React.useState(false);
    const [documentsIsUpdate, setDocumentsLength] = React.useState(0);
    const [tripListIsUpdate, setTripListIsUpdate] = React.useState(0);

    const history = useHistory();

    const refreshSession = React.useCallback(async (): Promise<User> => {
        setIsLoading(true);
        return userService.getSession()
            .then((user) => {
                setUser(user);
                return user;
            })
            .catch((error: ServerError) => {
                setError(error);
            })
            .then((finallyResult) => {
                setIsLoading(false);
                setIsReady(true);
                return finallyResult;
            });
    }, []);

    // Add new document
    const setDocumentsIsUpdateHandler = (): void => {
        const temp = documentsIsUpdate + 1;
        setDocumentsLength(temp);
    };

    // Add new trip
    const setTripListIsUpdateHandler = (): void => {
        const temp = tripListIsUpdate + 1;
        setTripListIsUpdate(temp);
    };

    const signOut = React.useCallback((): void => {
        deleteFromCookie(authTokenKeyInCookie);
        setUser(undefined);
        history.push('/');
    }, [authTokenKeyInCookie, history]);

    React.useEffect(() => {
        refreshSession();
    }, [refreshSession]);

    return (
        <SessionContext.Provider
            value={{
                user,
                error,
                isLoading,
                isReady,
                documentsIsUpdate,
                tripListIsUpdate,
                setDocumentsIsUpdateHandler,
                setTripListIsUpdateHandler,
                refreshSession,
                signOut,
                invitedByReferralCode,
            }}
        >
            {!isReady && (
                <Backdrop open={true} invisible={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            {isReady && children}
        </SessionContext.Provider>
    );
};

export const useSession = (): SessionContextInterface => React.useContext(SessionContext);
