import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Button, ClickAwayListener, Popper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(() => ({
    root: {
        zIndex: 9999,
        width: '100%',
        maxWidth: 240,
        padding: '16px 18px',
        borderRadius: 10,
        backgroundColor: '#ffffff',
        boxShadow: '0px 2px 6px -2px #D3D3D3'
    },
    deletePopperConfirmBtn: {
        height: 32,
        width: 80,
        marginTop: 12,
        marginRight: 6
    },
}));


// Delete confirmation popper
export default function DeletePopper({ deletedItem, anchorEl, onClickYes, onClickNo }: any) {
    const classes = useStyles();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`DeletePopper.${key}`, options);

    if (!deletedItem)
        return null;

    return (

        <ClickAwayListener onClickAway={onClickNo}>
            <Popper
                className={classes.root}
                open={true}
                anchorEl={anchorEl}
                placement="bottom-end"
                disablePortal={false}
            >

                {/* Confirmation text */}
                <Typography component="p" variant="body2">
                    {tt('popperText1')}
                    <Typography component="span" variant="body2" color="primary">{deletedItem.flightNumber}</Typography>
                    <Typography component="span" variant="body2">?</Typography>
                </Typography>


                <Grid container>

                    {/* Button YES */}
                    <Button
                        onClick={() => { onClickYes(deletedItem); }}
                        variant="contained"
                        className={classes.deletePopperConfirmBtn}
                        color="primary">
                        {tt('buttonYES')}
                    </Button>


                    {/* Button NO */}
                    <Button
                        onClick={onClickNo}
                        variant="outlined"
                        className={classes.deletePopperConfirmBtn}
                        color="primary">
                        {tt('buttonNO')}
                    </Button>

                </Grid>

            </Popper>

        </ClickAwayListener>
    );
}