//import { createMuiTheme } from '@material-ui/core/styles';
//TODO: createMuiTheme использует устаревший findDOMNode. И <React.StrictMode> Кидает предупреждения. Это временно решение. Должно быть исправлено в Material UI v 5.0.0
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';

const breakpoints = {
    values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1224,
        xl: 1920,
    },
};

const palette = {
    background: {
        default: '#FFFFFF'
    },
    primary: {
        main: '#3766D9',
    },
    secondary: {
        main: '#8D95AE',
        dark: '#4F4F4F',
        light: '#F1F1F1',
    },
    action: {
        disabledBackground: 'rgb(55, 102, 217, 40%)',
        disabled: '#FFFFFF',
    },
    text: {
        primary: '#1C2C5E',
        secondary: '#FFFFFF',
    }
};

const defaultTheme = createMuiTheme({ breakpoints, palette });

const theme = createMuiTheme({
    breakpoints,
    palette,
    props: {
        MuiDialog: {
            scroll: 'body',
        },
        MuiInputBase: {
            inputProps: {
                ...defaultTheme.props?.MuiInputBase?.inputProps,
                autoComplete: 'off',
            },
        },
        MuiTextField: {
            variant: 'filled',
        },
        MuiFilledInput: {
            disableUnderline: true
        },
    },

    overrides: {
        MuiBackdrop: {
            root: {
                backgroundColor: 'rgba(66, 66, 66, 0.7)'
            }
        },
        MuiDialog: {
            paper: {
                boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
                borderRadius: 8,
                margin: defaultTheme.spacing(2),
                padding: '36px 36px 40px 36px',
                [defaultTheme.breakpoints.down(670)]: {
                    maxWidth: '92% !important',
                    margin: '1%',
                    padding: '36px 18px 22px 18px',
                },
            },
            paperWidthXs: {
                width: "100%",
            },
        },
        MuiDialogTitle: {
            root: {
                padding: 0,
                marginTop: -6,
                marginBottom: 17,
            }
        },
        MuiDialogContent: {
            root: {
                marginTop: -4,
                padding: 0,
                '&:first-child': {
                    paddingTop: 0,
                }
            }
        },
        MuiFilledInput: {
            root: {
                backgroundColor: '#ffffff',
                '&:hover': {
                    backgroundColor: '#ffffff',
                },
                '&$disabled': {
                    backgroundColor: 'rgba(241, 241, 241, 0.95);',
                },
                '&$focused': {
                    backgroundColor: '#ffffff',
                    borderColor: defaultTheme.palette.primary.main,
                },
                '&$error': {
                    borderColor: defaultTheme.palette.error.main,
                },
                '&[class*="MuiAutocomplete-inputRoot"] .MuiInputBase-input.MuiAutocomplete-input': {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            },
            input: {
                padding: "22px 5px 4px 11px",
                height: 22,
            },

        },
        MuiInputBase: {
            root: {
                height: 46,
                fontSize: 16,
                color: "#1C2C5E",
                border: '1px solid #BABECD',
                overflow: 'hidden',
                borderRadius: 4,
                transition: defaultTheme.transitions.create(['border-color', 'box-shadow']),
                margin: 0,
                [defaultTheme.breakpoints.down('xs')]: {
                    fontSize: 12,
                },
            },
        },
        MuiFormControl: {
            marginNormal: {
                marginTop: defaultTheme.spacing(1.25),
                marginBottom: 0,
            }

        },
        MuiFormLabel: {
            root: {
                color: defaultTheme.palette.text.primary,
                '&$focused': {
                    color: defaultTheme.palette.text.primary,
                },
                fontSize: 14,
                [defaultTheme.breakpoints.down('xs')]: {
                    fontSize: 12,
                },
            }
        },
        MuiButton: {
            root: {
                height: '44px',
                [defaultTheme.breakpoints.down('xs')]: {
                    padding: defaultTheme.spacing(1.5),
                },
                borderRadius: 3,
                fontSize: 12,
                textTransform: 'uppercase',
            },
            text: {
                textTransform: 'none',
            },
            sizeSmall: {
                height: '32px',
                textTransform: 'none',
            },
            containedSecondary: {
                fontWeight: 'normal',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
            },
            containedPrimary: {
                fontWeight: 'bold',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
            },
            outlinedPrimary: {},
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 4,
            },
            input: {
                [defaultTheme.breakpoints.down('xs')]: {
                    padding: defaultTheme.spacing(1.25),
                },
            },
            notchedOutline: {
                border: 'none',
            },
        },
        MuiInputLabel: {
            root: {
                marginTop: -1,
                color: "#79809E",
                '&$focused': {
                    color: "#79809E",
                },
            },
            outlined: {
                [defaultTheme.breakpoints.down('xs')]: {
                    transform: 'translate(10px, 12px) scale(1)',
                },
            },
            filled: {
                transform: "translate(12px, 17px) scale(1)"
            }
        },
        MuiFormHelperText: {
            contained: {
                [defaultTheme.breakpoints.down('xs')]: {
                    marginLeft: defaultTheme.spacing(1.25),
                    marginRight: defaultTheme.spacing(1.25),
                },
            }
        },
        MuiChip: {
            root: {
                borderRadius: 2,
                marginRight: '6px',
                padding: '0px 10px',
                fontWeight: 400,
                color: defaultTheme.palette.text.primary,
                height: 'auto',
                lineHeight: '1.5',
                backgroundColor: '#E8EAEF'
            },
            label: {
                fontSize: 14,
                fontWeight: 400,
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0
            },
            outlined: {
                border: 'none',
                padding: '0px 6px 0px 0px',
                marginRight: '6px',
                '&  .MuiChip-icon': {
                    marginLeft: 0
                },
            },
            icon: {
                marginLeft: 0,
                marginRight: defaultTheme.spacing(0.5),
            }
        },
        MuiBadge: {
            badge: {
                fontSize: '14px',
                fontWeight: 700,
            },
            anchorOriginTopRightRectangle: {
                right: defaultTheme.spacing(0.5),
            }
        },
        MuiAccordion: {
            root: {
                '&$expanded': {
                    margin: undefined,
                    '&:last-child': {
                        marginBottom: undefined,
                    },
                },
            }
        },
        MuiAccordionDetails: {
            root: {
                flexDirection: 'column',
            }
        },
        MuiAccordionSummary: {
            root: {
                minHeight: 0,
                marginTop: '15px',
                marginBottom: '15px',
                '&$expanded': {
                    minHeight: 0,
                    marginTop: '15px',
                    marginBottom: 0,
                }
            },
            content: {
                margin: 0,
                '&$expanded': {
                    margin: 0,
                }
            },
            expandIcon: {
                paddingTop: 0,
                paddingBottom: 0,
                '&$expanded': {
                    paddingTop: 0,
                    paddingBottom: 0,
                }
            }
        },
    },
    typography: {
        fontFamily: [
            '"Arial"',
            'sans-serif',
        ].join(','),
        fontSize: 10,
        h1: {
            fontSize: 26,
            fontWeight: 700,
            [defaultTheme.breakpoints.down('xs')]: {
                fontSize: 22,
            },
        },
        h2: {
            fontSize: 20,
            fontWeight: 700,
            marginBottom: 24,
        },
        h3: {
            fontSize: 16,
            fontWeight: 700,
        },
        h4: {
            fontSize: 16,
            fontWeight: 700,
        },
        h6: {
            fontSize: 20,
            fontWeight: 700,
            [defaultTheme.breakpoints.down('xs')]: {
                fontSize: 18,
            },
        },
        body1: {
            fontSize: 14,
            fontWeight: 400,
        },
        body2: {
            fontSize: 14,
            fontWeight: 700,
        },
        button: {
            fontSize: 15,
            [defaultTheme.breakpoints.down('xs')]: {
                fontSize: 12,
            },
            fontWeight: 700,
            textTransform: 'none',
            lineHeight: 1,
        },
        subtitle1: {
            fontSize: 14,
            fontWeight: 400,
        },
        subtitle2: {
            color: '#1C2C5E',
            fontSize: 14,
            fontWeight: 400,
            opacity: 0.6,
        },
        caption: {
            fontSize: 12,
            fontWeight: 500,
        }
    },
});

export default theme;