import React from 'react';
import { DialogTitle, DialogContent, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../../components/mui-extensions/CustomDialog';

export default function ConfirmEmailInfoDialog(props: any) {
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`Profile.${key}`, options);

    const setMessageDialog = (errorMessage: string) => {
        switch (errorMessage) {
            case 'code_already_sent':
                return 'confirmEmailCodeAlreadySent';
            default:
                return 'confirmEmailCodeUnknownError';
        }
    };

    return (
        <CustomDialog maxWidth="xs" onClose={props.handleClose} open={props.open}>
            <DialogTitle>{tt('confirmEmailInfoDialogTitle')}</DialogTitle>
            <DialogContent>
                {props.errorMsg?.length === 0 &&
                    <React.Fragment>
                        <Typography component='span' variant='body1'>{tt('confirmEmailInfoDialogText1')}</Typography>
                        <b>{props.email}</b>
                        <Typography component='span' variant='body1'>{tt('confirmEmailInfoDialogText2')}</Typography>
                        <br></br><br></br>
                        <Typography component='p' variant='body1'>{tt('confirmEmailInfoDialogText3')}</Typography>
                    </React.Fragment>
                }
                {props.errorMsg?.length > 0 &&
                    <Typography>{tt(setMessageDialog(props.errorMsg))}</Typography>
                }
            </DialogContent>
        </CustomDialog>
    );
}