import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LinkInNewWindowIcon from '../../icons/LinkInNewWindowIcon';
import LinkInNewWindowDarkIcon from '../../icons/LinkInNewWindowDarkIcon';
import { useTranslation } from 'react-i18next';


// EXTEND ACTION BUTTON 
const useStyles = makeStyles(() => ({
    button: {
        height: 36,
        textTransform: 'uppercase',
        marginTop: -10,
        paddingLeft: 0,
        paddingRight: 0,
        marginRight: -4,
        "&:hover": {
            backgroundColor: 'inherit',
        },
    },
    buttonIcon: {
        height: 36,
        width: 36,
        marginTop: 8,
    },
}));


// EXTEND ACTION BUTTON 
export default function ReqCardButtonActionExt(props: any) {
    const classes = useStyles();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`Cards.${key}`, options);

    let iconStatus;
    switch (props.cardStatus) {
        case "pending": case "not_requested": case "passed": case "not_paid":
            iconStatus = <LinkInNewWindowDarkIcon className={classes.buttonIcon} />;
            break;
        default:
            iconStatus = <LinkInNewWindowIcon className={classes.buttonIcon} />;
    }

    return (
        <Button color="primary" key="anketa" endIcon={iconStatus} className={classes.button} onClick={props.handleDialogOpen}>
            {tt('buttonMore')}
        </Button>
    );
}
