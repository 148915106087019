import React from 'react';
import { useHistory } from 'react-router-dom';
import { DialogTitle, DialogContent } from '@material-ui/core';
import SignUp from '../../components/auth/SignUp';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../../components/mui-extensions/CustomDialog';
import { useSession } from '../../components/auth/SessionProvider';

export default function SignInDialog(props: any) {
    const { t } = useTranslation();
    const history = useHistory();
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        history.replace({
            hash: undefined
        });
    };

    const { user } = useSession();

    if (user)
        return null;

    return (
        <CustomDialog maxWidth="xs" onClose={handleClose} open={open}>
            <DialogTitle>{t('signUpTitle')}</DialogTitle>
            <DialogContent>
                <SignUp />
            </DialogContent>
        </CustomDialog>
    );
}