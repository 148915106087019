import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Button, Paper } from '@material-ui/core';
import { FlightShortInfo } from '../../../api/flight-service.types';
import * as datetimeHelper from '../../../helpers/datetimeHelper';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import { ReactComponent as FlightPanelIcon } from '../../icons/FlightPanelIcon.svg';

export interface FlightCardProps {
    flight: FlightShortInfo;
    setSelectedFlight: (flight: FlightShortInfo) => void;
    openSegments: (id: number, anchorEl: HTMLElement) => void;
    isActive: boolean;
    lengthItem: number;
}


const useStyles = makeStyles((theme) => ({
    paper: {
        margin: 'auto',
        background: 'none',
        color: '#FFFFFF',
        overflow: 'hidden',
        justifyContent: "center",
        border: 'none',
        marginBottom: 0,
        cursor: 'pointer',
        [theme.breakpoints.down(1040)]: {
            width: '100%',
        },
        [theme.breakpoints.down(750)]: {
            maxWidth: 'none',
            height: 'auto'
        },
        [theme.breakpoints.down(400)]: {
            width: '100%',
        },
    },
    rootGrid: {
        width: '100%',
        justifyContent: "center",
    },
    rootGridTwoItems: {
        width: '100%',
        justifyContent: "flex-end",
        marginTop: 5,
        paddingRight: 1,
        [theme.breakpoints.down(960)]: {
            justifyContent: "center",
            paddingRight: 0,
        },
    },
    rootGridBorder: {
        width: '100%',
        maxWidth: 310,
        borderRadius: 4,
        border: '1px solid #4A557C',
        padding: '20px 14px 20px 18px',
        display: 'inline-flex',
        [theme.breakpoints.down(750)]: {
            justifyContent: "space-between",
        },
        [theme.breakpoints.down(470)]: {
            padding: '15px 12px 15px  12px',
        },
    },
    rootGridNoBorder: {
        opacity: .7,
        width: '100%',
        maxWidth: 310,
        borderRadius: 4,
        border: '1px solid #1C2C5E',
        padding: '20px 14px 20px 18px',
        display: 'inline-flex',
        [theme.breakpoints.down(750)]: {
            justifyContent: "space-between",
        },
        [theme.breakpoints.down(470)]: {
            padding: '15px 12px 15px  12px',
        },
    },
    shortName: {
        fontSize: 25,
        fontWeight: 700,
        textTransform: 'uppercase',
        lineHeight: 1.2,
        color: '#FFFFFF',
        textAlign: 'center',
        [theme.breakpoints.down(670)]: {
            marginBottom: 2,
        },
    },
    fullName: {
        fontSize: 12,
        fontWeight: 400,
        marginBottom: 5,
        marginLeft: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        color: '#FFFFFF',
        textAlign: 'center',
        [theme.breakpoints.down(670)]: {
            marginBottom: 0,
        },
    },
    textAlignLeft: {
        [theme.breakpoints.down(670)]: {
            textAlign: 'left !important'
        },
    },
    textAlignRight: {
        [theme.breakpoints.down(670)]: {
            textAlign: 'right !important'
        },
    },
    dataTime: {
        minWidth: 83,
        fontSize: 12,
        fontWeight: 700,
        textTransform: 'uppercase',
        color: '#BBC0CF',
        textAlign: 'center'
    },
    rootGridItem: {
        boxSizing: 'border-box',
        maxWidth: 82,
    },
    rootGridItemCenter: {
        paddingTop: 1,
        [theme.breakpoints.down(670)]: {
            paddingTop: 4,
        },
        [theme.breakpoints.down(330)]: {
            display: 'none',
            minWidth: "100%",
        },
    },
    flightPanelIcon: {
        [theme.breakpoints.down(1110)]: {
            width: 70,
        },
        [theme.breakpoints.down(1070)]: {
            width: 60,
        },
        [theme.breakpoints.down(1020)]: {
            width: 50,
        },
        [theme.breakpoints.down(960)]: {
            width: "auto",
        },
        [theme.breakpoints.down(440)]: {
            width: 70,
        },
        [theme.breakpoints.down(410)]: {
            width: 60,
        },
        [theme.breakpoints.down(390)]: {
            width: 50,
        },
    },
    flightIconRaceSegmentWrap: {
        color: '#ffffff',
        height: 'auto',
        minWidth: 46,
        marginTop: -21,
        backgroundColor: '#455078',
        borderRadius: 10,
        overflow: 'hidden',
        padding: 0,
        [theme.breakpoints.down(670)]: {
            marginTop: -13,
        },
    },
    flightNumber: {
        marginTop: -18,
        [theme.breakpoints.down(670)]: {
            marginTop: -10,
        },
    },
    threeDotsBtn: {
        opacity: 0.5,
        marginLeft: 0,
    },
    moreIcon: {
        fontSize: '16px !important',
        margin: '2px 0px',
        marginLeft: 2,
    },
    AirplaneIcon1: {
        transform: "rotate(90deg)",
        fontSize: 25,
        paddingRight: 0,
        opacity: 0.5
    },
    ArrowRight2: {
        fontSize: 25,
        opacity: 0.5
    }
}));


export default function FlightCard({ flight, setSelectedFlight, lengthItem, ...props }: FlightCardProps) {
    const classes = useStyles();

    const history = useHistory();

    // Button show segments
    const handleShowSegments = (event: any) => {
        props.openSegments(flight.id, event.currentTarget);
        event.stopPropagation();
    };


    return (
        <Paper className={classes.paper} elevation={0} variant="outlined" onClick={() => { setSelectedFlight(flight); history.push('/'); }}>
            {/* Adds a border if it is a selected flight */}
            <Grid
                className={clsx(`${lengthItem === 2 ? classes.rootGridTwoItems : classes.rootGrid}`)}
                container
                alignItems="flex-start"
                justify="space-between"
            >

                <Grid className={clsx(`${props.isActive ? classes.rootGridBorder : classes.rootGridNoBorder}`)}>

                    {/* Departure information textAlignLeft */}
                    <Grid item xs={4} className={classes.rootGridItem}>
                        <Typography component="p" className={clsx(classes.shortName, classes.textAlignLeft)}>{flight.departure.airport.code}</Typography>
                        <Typography component="p" className={clsx(classes.fullName, classes.textAlignLeft)}>{flight.departure.airport.city.name}</Typography>
                        <Typography component="p" className={clsx(classes.dataTime, classes.textAlignLeft)}>
                            {datetimeHelper.formatDataTime(flight.departure.at)}
                        </Typography>
                    </Grid>


                    {/* Icon and number of segments */}
                    <Grid item xs={5} container justify="center" className={classes.rootGridItemCenter}>
                        <Grid item xs={12} container justify="center">
                            <FlightPanelIcon className={classes.flightPanelIcon} />
                        </Grid>

                        {flight.segmentsCount === 1 && (
                            <Typography component="p" variant="body2" className={classes.flightNumber}>
                                {flight.flightNumber}
                            </Typography>
                        )}

                        {flight.segmentsCount > 1 && (
                            <Grid item>
                                <Button
                                    classes={{
                                        endIcon: classes.threeDotsBtn,
                                    }}
                                    onClick={(event: any) => { handleShowSegments(event); }}
                                    className={classes.flightIconRaceSegmentWrap}
                                    endIcon={<MoreHorizIcon className={classes.moreIcon} />}
                                >
                                    {flight.segmentsCount - 1}
                                </Button>
                            </Grid>
                        )}
                    </Grid>


                    {/* Arrival information */}
                    <Grid item xs={3} className={classes.rootGridItem}>
                        <Typography component="p" className={clsx(classes.shortName, classes.textAlignRight)}>{flight.arrival.airport.code}</Typography>
                        <Typography component="p" className={clsx(classes.fullName, classes.textAlignRight)}>{flight.arrival.airport.city.name}</Typography>
                        <Typography component="p" className={clsx(classes.dataTime, classes.textAlignRight)}>
                            {datetimeHelper.formatDataTime(flight.arrival.at)}
                        </Typography>
                    </Grid>

                </Grid>


            </Grid>
        </Paper>
    );
}