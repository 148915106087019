import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DialogTitle, DialogContent } from '@material-ui/core';
import SignIn from '../../components/auth/SignIn';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../../components/mui-extensions/CustomDialog';


export default function SignInDialog({ disableClose = false, ...props }: { disableClose: boolean }) {

    const { t } = useTranslation();
    const history = useHistory();
    const [open, setOpen] = useState(true);

    const handleClose = (isCompleted: boolean) => {
        if (disableClose && !isCompleted)
            return;

        setOpen(false);
        history.replace({
            hash: undefined
        });
    };

    const handleCloseByUser = () => handleClose(false);
    const handleCloseCompleted = () => handleClose(true);

    return (
        <CustomDialog
            maxWidth="xs"
            onClose={handleCloseByUser}
            open={open}
        >
            <DialogTitle>{t('signInTitle')}</DialogTitle>
            <DialogContent>
                <SignIn onCompleted={handleCloseCompleted} />
            </DialogContent>
        </CustomDialog>
    );
}