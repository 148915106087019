import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    skeletonCards: {
        borderRadius: 8
    },
}));

export default function SkeletonCards({ count }: any) {
    const classes = useStyles();
    const items = [];
    for (let x = 0; x < count; x++) {
        items.push(
            <Grid item xs={12} sm={6} md={4} key={x}>
                <Skeleton
                    className={classes.skeletonCards}
                    variant="rect"
                    height={312}
                />
            </Grid>);
    }
    return <>{items}</>;
};