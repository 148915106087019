import React from 'react';
import { Placemark } from 'react-yandex-maps';
import * as orderService from '../../api/order-service';
import DefaultPlacemarIcon from '../icons/DefaultPlacemarIcon.svg';
import ActivePlacemarkIcon from '../icons/ActivePlacemarkIcon.svg';
import { useTranslation } from 'react-i18next';


export default function PlacemarkItem(props: any) {
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`OrderTestLab.${key}`, options);

    const [isActivePlacemark, setIsActivePlacemark] = React.useState<boolean>(false);
    const [labFullInfo, setLabFullInfo] = React.useState({
        name: '',
        address: '',
        url: '',
        schedule: {
            normal: [],
            specialTimes: []
        },
        phone: ''
    });


    const handleCloseBalloon = () => {
        setIsActivePlacemark(false);
    };

    const handleGetLabFullInfo = (labId: number) => {
        setIsActivePlacemark(true);

        setLabFullInfo({
            name: '',
            address: '',
            url: '',
            schedule: {
                normal: [],
                specialTimes: []
            },
            phone: ''
        });


        // Get detailed information about the selected laboratory
        return orderService.getFullInformationAboutLaboratory(props.organizationId, labId)
            .then((res) => {
                setLabFullInfo({
                    name: res.name || undefined,
                    address: res.address || undefined,
                    url: res.url || undefined,
                    schedule: res.schedule || undefined,
                    phone: res.phone || undefined
                });

            })
            .catch((e) => { console.log(e); });
    };


    return (
        <React.Fragment >

            <Placemark
                modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                onClick={() => handleGetLabFullInfo(props.lab.id)}
                onBalloonClose={() => handleCloseBalloon()}
                properties={{
                    hintContent: props.lab.name,
                    balloonContent:
                        `   
                        <style type="text/css"> 
                            h4.balloonContent_h4 { font-size: 16px; color: #1C2C5E; margin: 0px 0px 12px 0px;}
                            h5.balloonContent_h5 { font-size: 14px; color: #1C2C5E; margin: 0px 0px 12px 0px;}
                            p.balloonContent_p { font-size: 14px; color: #1C2C5E; margin: 0px 0px 12px 0px;}
                            a.balloonContent_a { font-size: 14px; color: #3766D9; margin: 0px 10px 12px 0px; display: block;}
                            span.balloonContent_span { font-size: 14px; color: #1C2C5E; margin: 0px 0px 6px 0px;}
                            div.balloonContent_div { padding: 10px} 
                            [class*="balloon_layout_normal"] {
                                border-radius: 8px !important;  
                                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04) !important;
                            }
                            [class*="balloon__layout"] {
                                border-radius: 8px !important;   
                                padding-right: 5px;
                            }
                            [class*="balloon__content"] {
                                border-radius: 8px !important;  
                                overflow: hidden;
                            }
                        </style>

                        <div class="balloonContent_div">
                            <h4 class="balloonContent_h4">${props.lab.name}</h4> 
                            <p class="balloonContent_p">${props.lab.address}</p> 
                            <p class="balloonContent_p">${labFullInfo.phone !== undefined ? labFullInfo.phone : ''}</p> 
                            <a href="${labFullInfo.url !== undefined ? labFullInfo.url : ''}" class="balloonContent_a" target="_blank" rel="noreferrer" >${labFullInfo.url !== undefined ? labFullInfo.url : ''}</a> 
                            <h5 class="balloonContent_h5">${tt('laboratoryWorkingHours')}</h5>   
                            <span class="balloonContent_span">пн: ${labFullInfo.schedule.specialTimes.length > 0 ? labFullInfo.schedule.specialTimes[0] : '...'}</span><br/>
                            <span class="balloonContent_span">вт: ${labFullInfo.schedule.specialTimes.length > 0 ? labFullInfo.schedule.specialTimes[1] : '...'}</span><br/>
                            <span class="balloonContent_span">ср: ${labFullInfo.schedule.specialTimes.length > 0 ? labFullInfo.schedule.specialTimes[2] : '...'}</span><br/>
                            <span class="balloonContent_span">чт: ${labFullInfo.schedule.specialTimes.length > 0 ? labFullInfo.schedule.specialTimes[3] : '...'}</span><br/>
                            <span class="balloonContent_span">пт: ${labFullInfo.schedule.specialTimes.length > 0 ? labFullInfo.schedule.specialTimes[4] : '...'}</span><br/>
                            <span class="balloonContent_span">сб: ${labFullInfo.schedule.specialTimes.length > 0 ? labFullInfo.schedule.specialTimes[5] : '...'}</span><br/>
                            <span class="balloonContent_span">вс: ${labFullInfo.schedule.specialTimes.length > 0 ? labFullInfo.schedule.specialTimes[6] : '...'}</span><br/>
                        </div>
                        `,
                }}
                geometry={[props.lab.lat, props.lab.lon]}
                options={{
                    balloonOffset: [6, -26],
                    hideIconOnBalloonOpen: false,
                    iconLayout: 'default#image',
                    iconImageHref: isActivePlacemark ? ActivePlacemarkIcon : DefaultPlacemarIcon,
                    iconImageSize: [36, 36],
                }} />

        </React.Fragment>
    );


}