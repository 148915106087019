import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function SuccessfulRegTestIcon(props: any) {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 68 68" fill="none">
                <circle cx="34" cy="34" r="34" fill="#E7EDFA" />
                <path d="M19 34.6948L29.5342 45L51 24" stroke="#3766D9" strokeWidth="4" />
            </svg>
        </SvgIcon>
    );
}