import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, } from '@material-ui/core';
import clsx from 'clsx';


// CARD
const useStyles = makeStyles((theme) => ({
    '@keyframes flicker': {
        '0%': {
            backgroundColor: '#ffffff'
        },
        '50%': {
            backgroundColor: '#FFF3E0',
            borderColor: '#FFF3E0'
        },
        '100%': {
            backgroundColor: '#FFFDE7',
            borderColor: '#FFF3E0'
        },
    },
    flicker: {
        animationName: '$flicker',
        animationDuration: '1.5s',
        animationDelay: '500ms',
        animationFillMode: 'forwards',
        animationIterationCount: '1',
        animationDirection: 'normal',
        animationTimingFunction: 'ease-in-out',
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: "26px 24px 14px 24px",
        borderRadius: 8,
        justifyContent: 'space-between',
        [theme.breakpoints.down(600)]: {
            padding: "15px 16px 11px 16px",
        },
    },
    cardStatusPassed: {
        border: '1px solid #ECF9F3',
        backgroundColor: '#ECF9F3',
    },
    cardStatusPendingORNotRequested: {
        border: '1px solid #F5F7FD',
        backgroundColor: '#F5F7FD',
    },
    cardStatusDefault: {
        border: '1px solid #E8EAEF',
        backgroundColor: '#FFFFFF',
    },
}));


// CARD 
export default function ReqCard(props: any) {
    const classes = useStyles();
    let cardStatus;

    switch (props.cardStatus) {
        case "passed":
            cardStatus = classes.cardStatusPassed;
            break;
        case "pending": case "not_requested": case "not_paid":
            cardStatus = classes.cardStatusPendingORNotRequested;
            break;
        default:
            cardStatus = classes.cardStatusDefault;
    }

    return (<Grid className={props.isChanged ? clsx(classes.card, cardStatus, classes.flicker) : clsx(classes.card, cardStatus)}>{props.children}</Grid>);
}
