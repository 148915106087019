import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Button, CircularProgress, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FlightCard from './FlightCard';
import ProfileMenuPopper from './ProfileMenuPopper';
import { FlightShortInfo } from '../../../api/flight-service.types';
import EditFlightDialog from '../edit/EditFlightDialog';
import { useTranslation } from 'react-i18next';
import { useSession } from '../../auth/SessionProvider';
import Slider from "react-slick";
import clsx from 'clsx';
import { useSnackbar } from 'notistack';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ShowSegments from './SegmentsPopper';
import { useDialogState } from '../../../providers/DialogProvider';


export interface FlightPanelProps {
    flights: FlightShortInfo[];
    selectedFlight: FlightShortInfo | undefined;
    setSelectedFlight: (flight: FlightShortInfo) => void;
    isLoading: boolean,
}


const useStyles = makeStyles((theme) => ({
    flightPanelWrapIn: {
        backgroundColor: theme.palette.text.primary,
        height: 'auto',
        // maxHeight: 187,
        minHeight: 118,
        borderRadius: '8px',
        padding: '32px 32px 15px 32px',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down(960)]: {
            maxHeight: '100%',
        },
        [theme.breakpoints.down(670)]: {
            paddingBottom: 100,
            position: 'relative',
        },
    },

    // ---- FIO and Button START ----
    ownerNameWrap: {
        flexWrap: 'wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    ownerNameFIOWrap: {
        flexWrap: 'wrap',
        position: 'relative',
        flexBasis: 'auto',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    ownerName: {
        fontWeight: 700,
        color: '#ffffff',
        lineHeight: 1.10,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        paddingRight: 43,
        display: 'inline-block',
        maxWidth: '100%',
        [theme.breakpoints.down(670)]: {
            fontSize: 20,
        },
    },
    ownerNamePaddingBottomNoFlight: {
        paddingBottom: 3,
    },
    ownerNamePaddingBottomYesFlight: {
        paddingBottom: 12,
    },
    buttonEditFlightWrap: {
        [theme.breakpoints.down(670)]: {
            bottom: 12,
            left: 32,
            right: 32,
            position: 'absolute',
        },
    },
    buttonEditFlight: {
        marginBottom: 21,
        minWidth: 190,
        transition: '250ms all',
        padding: 0,
        "&:hover": {
            background: "#F5F7FD",
            color: "#3766D9"
        },
        [theme.breakpoints.down(670)]: {
            width: "100%",
        },
    },
    buttonEditFlightIcon: {
        height: 26,
        width: 26,
    },
    buttonProfileMenuWrap: {
        top: -6,
        right: 0,
        position: 'absolute',
        [theme.breakpoints.down(600)]: {
            top: -8,
        },
        display: 'inline-block'
    },
    buttonProfileMenuIcon: {
        color: theme.palette.text.secondary,
        marginTop: -5,
    },
    // ---- FIO and Button END ----

    flightButtonsWrap: {
        paddingLeft: "3%",
        paddingRight: 0,
        display: 'block',
        marginTop: 0,
        paddingBottom: 22,
        boxSizing: 'border-box',
        [theme.breakpoints.down(1160)]: {
            paddingLeft: "0%",
        },
    },
    flightButtonsSingleWrap: {
        width: 320,
        float: 'right',
        marginRight: -2,
        marginTop: 5,
        [theme.breakpoints.down(800)]: {
            width: '100%',
            float: 'none',
            marginRight: 0,
        },
    },

    // ---- Slider style START ----
    sliderWrap: {
        marginLeft: 40,
        marginRight: 15,
        [theme.breakpoints.down(960)]: {
            marginLeft: 25,
            marginRight: 25,
        },
        [theme.breakpoints.down(600)]: {
            marginLeft: 2,
            marginRight: 2,
        },
        '& > button.slick-arrow': {
            marginTop: 0
        }
    },
    sliderWrapTwoItems: {
        marginLeft: 40,
        marginRight: 0,
        [theme.breakpoints.down(960)]: {
            marginLeft: 25,
            marginRight: 25,
        },
        [theme.breakpoints.down(600)]: {
            marginLeft: 2,
            marginRight: 2,
        },
    },
    sliderDots: {
        position: 'absolute',
        bottom: -31,
        display: 'block',
        width: '100%',
        padding: 0,
        margin: 0,
        listStyle: 'none',
        textAlign: 'center',
        [theme.breakpoints.down(960)]: {
            bottom: -27,
        },
        '& > li': {
            position: 'relative',
            display: 'inline-block',
            width: 20,
            height: 20,
            margin: '0px 2.5px',
            padding: 0,
            cursor: 'pointer',
            "& > button": {
                fontSize: 0,
                lineHeight: 0,
                display: 'block',
                width: 20,
                height: 20,
                padding: 5,
                cursor: 'pointer',
                color: 'transparent',
                border: 0,
                outline: 'none',
                background: 'transparent',
                "&::before": {
                    fontFamily: 'slick',
                    fontSize: 6,
                    lineHeight: 3.5,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: 20,
                    height: 20,
                    content: `'•'`,
                    textAlign: 'center',
                    opacity: 0.3,
                    color: '#ffffff',
                    '-webkit-font-smoothing': 'antialiased',
                },
            },
        },
        '& > li.slick-active button:before': {
            width: 20,
            height: 20,
            opacity: 1,
        }
    },
    // ---- Slider style END ----
}));


const FlightPanel: React.FC<FlightPanelProps> = props => {
    const { user } = useSession();
    const { enqueueSnackbar } = useSnackbar();
    const [, setPlanDialogOpen] = useDialogState('PaymentPlanDialog', false);

    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`FlightPanel.${key}`, options);

    const [openEditFlightDialog, setOpenEditFlightDialog] = React.useState<boolean>(false);
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
    const [selectedFlightId, setSelectedFlightId] = React.useState<number>();

    // Open modal window EditFlightDialog
    const handleOpenEditFlightDialog = () => (event: any) => {
        if (user?.subscriptionType) {
            setOpenEditFlightDialog(true);
        } else {
            enqueueSnackbar(t('common.serverErrors.subscription_plan_undefined'), { variant: 'info', preventDuplicate: true });
            setPlanDialogOpen(true);
        }
    };

    // Close modal window EditFlightDialog
    const handleCloseEditFlightDialog = () => {
        setOpenEditFlightDialog(false);
    };


    // Open dropDown a window with segments of one flight
    const handleOpenSegmentsPopper = (flightId: number, anchorEl: HTMLElement) => {
        setSelectedFlightId(flightId);
        setAnchorEl(anchorEl);
    };

    // Close dropDown a window with segments of one flight
    const handleCloseSegments = (event: any) => {
        setSelectedFlightId(0);
        setAnchorEl(undefined);
        event.stopPropagation();
    };


    // Slider flights settings
    const settings = {
        dotsClass: classes.sliderDots,
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };

    /* 
    * WRAP FIO and Button MD BLOCK
    * Changes the grid markings depending on the number of flights 
    * (positions: no flights, one flight, more than one flight)
    */
    function getColsMDOwnerNameWrap(flightsLength: number) {
        if (flightsLength === 0) {
            return 12;
        }
        if (flightsLength === 1) {
            return 4;
        }
        else {
            return 4;
        }
    }

    /* 
    * WRAP FIO and Button SM BLOCK
    * Changes the grid markings depending on the number of flights 
    * (positions: no flights, one flight, more than one flight)
    */
    function getColsSMOwnerNameWrap(flightsLength: number) {
        if (flightsLength === 0) {
            return 12;
        }
        if (flightsLength === 1) {
            return 5;
        }
        else {
            return 12;
        }
    }

    /* 
    * FIO SM BLOCK
    * Changes the grid markings depending on the number of flights 
    * (positions: no flights, one flight, more than one flight)
    */
    function getColsSMOwnerNameFIOWrap(flightsLength: number) {
        if (flightsLength === 0) {
            return 'auto';
        }
        else {
            return 12;
        }
    }

    // Parameters for marking the grids of the transverse panel
    const colsMDOwnerNameWrap = getColsMDOwnerNameWrap(props.flights.length);
    const colsSMOwnerNameWrap = getColsSMOwnerNameWrap(props.flights.length);
    const colsSMOwnerNameFIOWrap = getColsSMOwnerNameFIOWrap(props.flights.length);


    // For profile dropdown menu
    const [openProfileMenu, setProfileMenuOpen] = React.useState(false);
    const profileMenuBtnRef = React.useRef<HTMLButtonElement>(null);

    const handleProfileMenuToggle = () => {
        setProfileMenuOpen(!openProfileMenu);
    };

    const handleProfileMenuClose = () => {
        setProfileMenuOpen(false);
    };


    return (
        <Grid container justify="space-between" alignItems="center" className={classes.flightPanelWrapIn}>


            {/* Block with a FIO and a Button for opening a modal window for editing a flight */}
            <Grid
                item
                md={colsMDOwnerNameWrap}
                sm={colsSMOwnerNameWrap}
                container
                className={classes.ownerNameWrap}
                alignItems="center"
                justify="space-between"
            >

                {/* FIO */}
                <Grid item sm={colsSMOwnerNameFIOWrap} className={classes.ownerNameFIOWrap}>
                    <Typography
                        component="h1"
                        variant="h1"
                        className={clsx(classes.ownerName, `${props.flights.length === 0 ? classes.ownerNamePaddingBottomNoFlight : classes.ownerNamePaddingBottomYesFlight}`)}
                        gutterBottom
                    >

                        {/* Last name */}
                        {`${user?.person?.lastName} `}

                        {user?.person?.middleName ? <br></br> : ''}

                        {/* First name */}
                        {`${user?.person?.firstName} `}

                        {/* Middle name */}
                        {user?.person?.middleName ? `${user?.person?.middleName}` : ''}

                    </Typography>

                    {/* Button open DropDown menu */}
                    <IconButton aria-label="profile" ref={profileMenuBtnRef} onClick={handleProfileMenuToggle} className={classes.buttonProfileMenuWrap}>
                        <MoreVertIcon fontSize="large" className={classes.buttonProfileMenuIcon} />
                    </IconButton>

                </Grid>

                {/* Button open EditFlightDialog*/}
                <Grid className={classes.buttonEditFlightWrap}>
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<AddIcon className={classes.buttonEditFlightIcon} />}
                        onClick={handleOpenEditFlightDialog()}
                        className={classes.buttonEditFlight}
                    >
                        {tt('addTripBtn')}
                    </Button>
                    <EditFlightDialog openEditFlightDialog={openEditFlightDialog} handleCloseEditFlightDialog={handleCloseEditFlightDialog} setSelectedFlight={props.setSelectedFlight} />
                </Grid>

            </Grid>

            {/* DropDown menu */}
            <ProfileMenuPopper
                openProfileMenu={openProfileMenu}
                profileMenuBtnRef={profileMenuBtnRef}
                handleProfileMenuClose={handleProfileMenuClose}
            />


            {/* Loader for the list of flights */}
            {props.isLoading && (
                <Grid
                    item
                    md={8}
                    sm={12}
                    container
                    justify="space-between"
                    alignItems="flex-start"
                    className={classes.flightButtonsWrap}>
                    <CircularProgress size={32} />
                </Grid>
            )}


            {/* Loads a slider if the number of flights is > 1 */}
            {!props.isLoading && props.flights.length > 1 && (
                <Grid
                    item
                    md={8}
                    sm={12}
                    container
                    justify="space-between"
                    alignItems="flex-start"
                    className={classes.flightButtonsWrap}>
                    <Slider
                        {...settings}
                        className={clsx(`${props.flights.length === 2 ? classes.sliderWrapTwoItems : classes.sliderWrap}`)}
                    >
                        {props.flights.map((flight, key) => (
                            <FlightCard
                                key={flight.id}
                                flight={flight}
                                setSelectedFlight={props.setSelectedFlight}
                                openSegments={handleOpenSegmentsPopper}
                                isActive={props.selectedFlight?.id === flight.id}
                                lengthItem={props.flights.length}
                            />
                        ))}
                    </Slider>
                    <ShowSegments
                        open={!!selectedFlightId}
                        anchorEl={anchorEl}
                        onClickClose={(event: any) => handleCloseSegments(event)}
                        idFlight={selectedFlightId}
                    />
                </Grid>
            )}

            {/* Loaded if the number of flights = 1 */}
            {!props.isLoading && props.flights.length === 1 && (
                <Grid
                    item
                    md={7}
                    sm={7}
                    container
                    justify="flex-end"
                    alignItems="center"
                    className={classes.flightButtonsWrap}>
                    <Grid
                        item
                        className={classes.flightButtonsSingleWrap} >
                        <FlightCard
                            flight={props.flights[0]}
                            setSelectedFlight={props.setSelectedFlight}
                            openSegments={handleOpenSegmentsPopper}
                            isActive={true}
                            lengthItem={props.flights.length}
                        />
                        <ShowSegments
                            open={!!selectedFlightId}
                            anchorEl={anchorEl}
                            onClickClose={(event: any) => handleCloseSegments(event)}
                            idFlight={selectedFlightId}
                        />
                    </Grid>
                </Grid>
            )}


        </Grid>
    );
};

export default FlightPanel;