import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../../components/layout/Layout';
import { Dialog, DialogTitle, DialogContent, Backdrop, CircularProgress } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import SetPassword, { SetPasswordResult } from '../../components/auth/SetPassword';
import * as userService from '../../api/user-service';
import { useHistory, useParams } from "react-router-dom";
import { ServerError } from '../../api/error-service';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    root: {},
    backDrop: {
        backdropFilter: "blur(5px)",
        zIndex: theme.zIndex.modal,
    },
    dialogBackDrop: {
        backdropFilter: "blur(5px)",
    },
}));

export default function ChangePassword() {
    const { code } = useParams<{ code: string }>();
    const history = useHistory();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`ChangePassword.${key}`, options);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [isReady, setIsReady] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(true);
    const [serverError, setServerError] = React.useState<ServerError>();

    useEffect(() => {
        userService.verifyWelcomeCode(code)
            .then(({ email }) => {
                setEmail(email);
                setIsReady(true);
            })
            .catch((err: ServerError) => {
                setServerError(err);
            })
            .then(() => {
                setIsLoading(false);
            });
    }, [code]);

    const onSetPasswordCompleted = (result: SetPasswordResult) => {
        enqueueSnackbar(t('ChangePassword.successfullMessage'), { variant: 'success' });
        history.push('/#signin');
    };

    return (
        <Layout>
            <Backdrop className={classes.backDrop} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Dialog
                maxWidth="xs"
                open={isReady}
                BackdropProps={{
                    classes: {
                        root: classes.dialogBackDrop,
                    },
                }}
            >
                <DialogTitle>
                    {t('ChangePassword.dialogTitle')}
                </DialogTitle>
                <DialogContent>
                    <SetPassword mode="reset" code={code} email={email} onCompleted={onSetPasswordCompleted} />
                </DialogContent>
            </Dialog>

            <Dialog
                maxWidth="xs"
                open={!!serverError && serverError.reason === 'confirmation_code_expired'}
                BackdropProps={{
                    classes: {
                        root: classes.dialogBackDrop,
                    },
                }}
            >
                <DialogContent>
                    <Alert severity="warning">
                        <AlertTitle>{tt('codeIsExpiredTitle')}</AlertTitle>
                        {tt('codeIsExpiredMessage')}
                    </Alert>
                </DialogContent>
            </Dialog>

            <Dialog
                maxWidth="xs"
                open={!!serverError && (serverError.reason === 'confirmation_code_not_found' || serverError.reason === 'validation_error')}
                BackdropProps={{
                    classes: {
                        root: classes.dialogBackDrop,
                    },
                }}
            >
                <DialogContent>
                    <Alert severity="error">
                        <AlertTitle>{t('error')}</AlertTitle>
                        {tt('invalidCodeMessage')}
                    </Alert>
                </DialogContent>
            </Dialog>

        </Layout>
    );
}