import Markdown from 'markdown-to-jsx';
import { Typography, Button, Link } from '@material-ui/core';
import React from 'react';
import { mergeDeep } from '../../helpers/commonHelper';

type MdProps = {
    children: any,
    overrides?: any
}

export function getComponent(Component: React.ComponentType<any>) {
    return ({ children, ...props }: MdProps) => (<Component {...props}> {children} </Component>);
}

export default function CustomMarkdown({ children, overrides }: MdProps) {

    const options = {
        overrides: mergeDeep({
            button: {
                component: getComponent(Button),
                props: {
                    variant: 'contained',
                    color: 'primary',
                }
            },
            middle: {
                component: getComponent(Typography),
                props: {
                    style: {
                        'textAlign': 'center',
                        'margin': '20px 0px',
                    }
                }
            },
            typography: {
                component: getComponent(Typography),
            },
            p: {
                component: getComponent(Typography),
            },
            a: {
                getComponent: getComponent(Link),
            },
            td: {
                props: {
                    style: {
                        'padding': '10px'
                    }
                }
            },
            ul: {
                props: {
                    style: {
                        'fontWeight': '400'
                    }
                }
            },
            ol: {
                props: {
                    style: {
                        'fontWeight': '400'
                    }
                }
            }
        }, overrides)
    };

    return (
        <Markdown
            children={children}
            options={options}
        />
    );
}