import axios from 'axios';
import { getServerError } from './error-service';
import { SubscriptionPlan, SubscriptionOrder } from './subscription-service.types';

export const getPlanList = () =>
    axios.get<SubscriptionPlan[]>('/api/subscription')
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

export const createOrder = (planId: number) =>
    axios.post<SubscriptionOrder>(`/api/subscription/${planId}`, {})
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });
