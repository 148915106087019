import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import landingImg4 from '../../images/Landing4.jpg';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '54px',
        backgroundColor: '#F5F7FD',
        borderRadius: '8px',
        overflow: 'hidden',
    },
    textColumn: {
        padding: theme.spacing(3, 6),
        [theme.breakpoints.down('sm')]: {
            order: 2,
            padding: theme.spacing(2, 2),
        },
    },
    message: {
        lineHeight: '100%',
    },
    btn: {
        marginTop: '40px'
    },
    img: {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    },
}));


export default function TwoColBanner(props: any) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container alignItems="center" className={classes.root}>
            <Grid item xs={12} md={6} className={classes.textColumn}>
                <Typography variant="h1" component="p" className={classes.message}>{t('Landing.bannerMessage')}</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    href="#signup"
                    className={classes.btn}
                    startIcon={<AddIcon style={{ height: 26, width: 26 }} />}>
                    {t('Landing.bannerBtnText')}
                </Button>

            </Grid>
            <Grid item xs={12} md={6}>
                <img src={landingImg4} alt="" className={classes.img} />
            </Grid>
        </Grid>
    );
}

