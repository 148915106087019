import React from 'react';
import { useHistory } from 'react-router-dom';
import { DialogTitle, DialogContent } from '@material-ui/core';
import ConfirmPhoneNumber from '../../components/profile/ConfirmPhoneNumber';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import CustomDialog from '../../components/mui-extensions/CustomDialog';


export default function ChangePhoneDialog(props: any) {
    const { t } = useTranslation();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        history.replace({
            hash: undefined
        });
    };

    const onChangedSuccessfully = () => {
        enqueueSnackbar(t('ChangePhone.successfullMessage'), { variant: 'success' });
        handleClose();
        if (document.activeElement && document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
    };

    return (
        <CustomDialog
            maxWidth="xs"
            onClose={handleClose}
            open={open}
        >
            <DialogTitle>
                {t('ChangePhone.dialogTitle')}
            </DialogTitle>
            <DialogContent>
                <ConfirmPhoneNumber onCompleted={onChangedSuccessfully} />
            </DialogContent>
        </CustomDialog>
    );
}