import React from 'react';
import { DialogTitle, DialogContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditFellow, { EditFellowProps } from './EditFellow';
import { Person } from '../../api/user-service.types';
import CustomDialog from '../mui-extensions/CustomDialog';

export interface EditFellowDialogProps extends EditFellowProps {
    close: () => void;
    onCompleted: (fellow: Person, isNew: boolean) => void;
};

export default function EditFellowDialog({ close, onCompleted, ...props }: EditFellowDialogProps) {

    const { t } = useTranslation();

    return (
        <CustomDialog
            maxWidth="xs"
            onClose={close}
            open={true}
        >
            <DialogTitle>{props.editedFellow ? t('EditFellow.dialogTitleToEdit') : t('EditFellow.dialogTitleToAdd')}</DialogTitle>
            <DialogContent>
                <EditFellow onCompleted={onCompleted} {...props} />
            </DialogContent>
        </CustomDialog>
    );
}