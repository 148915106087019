import React from 'react';

interface IDialogContext {
    state: any;
    setIsOpen: (dialogKey: string, isOpen: boolean) => void;
    open: (dialogKey: string) => void;
    close: (dialogKey: string) => void;
}

const stub = (): never => {
    throw new Error('You forgot to wrap your component in <DialogProvider>.');
};

const initialContext = {
    state: {},
    setIsOpen: stub,
    open: stub,
    close: stub,
};

const DialogContext = React.createContext<IDialogContext>(initialContext);

interface DialogProviderOptions {
    children?: React.ReactNode;
}

export const DialogProvider = ({ children }: DialogProviderOptions): JSX.Element => {
    const [state, setState] = React.useState({});

    const setIsOpen = React.useCallback((dialogKey: string, isOpen: boolean) => {
        setState((prev) => ({ ...prev, [dialogKey]: isOpen }));
    }, []);

    const open = React.useCallback((dialogKey: string) => {
        setState((prev) => ({ ...prev, [dialogKey]: true }));
    }, []);

    const close = React.useCallback((dialogKey: string) => {
        setState((prev) => ({ ...prev, [dialogKey]: false }));
    }, []);


    return (
        <DialogContext.Provider
            value={{
                state,
                setIsOpen,
                open,
                close,
            }}
        >
            {children}
        </DialogContext.Provider>
    );
};

const useDialogContext = (): IDialogContext => React.useContext(DialogContext);
export const useDialogState = (dialogKey: string, isOpen = false): [isOpen: boolean, setIsOpen: (isOpen: boolean) => void, open: () => void, close: () => void] => {
    const { state, setIsOpen, open, close } = useDialogContext();

    const isOpenDialog = state[dialogKey] || false;
    const setIsOpenDialog = (isOpen: boolean) => setIsOpen(dialogKey, isOpen);
    const openDialog = () => open(dialogKey);
    const closeDialog = () => close(dialogKey);

    return [isOpenDialog, setIsOpenDialog, openDialog, closeDialog];
};
