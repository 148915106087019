import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../../components/layout/Layout';
import FlightPanel from '../../components/flight/panel/FlightPanel';
import { useSession } from '../../components/auth/SessionProvider';
import * as flightService from '../../api/flight-service';
import FlightDetails from '../../components/flight/requirements/FlightDetails';
import { FlightShortInfo } from '../../api/flight-service.types';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import OrderTest from '../order-test/OrderTest';
import SignInDialog from '../modal/SignInDialog';
import PrivateRoute from '../../components/route/PrivateRoute';
import NoFlight from '../../components/flight/requirements/NoFlight';
import Landing from '../../components/landing/Landing';
import PublicRoute from '../../components/route/PublicRoute';
import CountryRequirements from '../../components/landing/CountryRequirements';
import ChangedCountryRequirements from '../../components/landing/ChangedCountryRequirements';
import MdArticle from '../../components/landing/MdArticle';


const useStyles = makeStyles((theme) => ({
    root: {},
}));

export default function Home() {
    const classes = useStyles();

    const [flights, setFlights] = React.useState<FlightShortInfo[]>([] as FlightShortInfo[]);
    const [selectedFlight, setSelectedFlight] = React.useState<FlightShortInfo>();
    const [isLoading, setIsLoading] = React.useState(true);
    const { t } = useTranslation();

    const { user, tripListIsUpdate } = useSession();


    useEffect(() => {
        if (!user) {
            setFlights([]);
            setSelectedFlight(undefined);
            return;
        };

        setIsLoading(true);
        flightService.getList()
            .then((flightList) => {
                setFlights(flightList);
                setSelectedFlight((currentValue) => flightList.find(f => f.id === currentValue?.id) || flightList[0]);
            })
            .catch(() => { })
            .then(() => {
                setIsLoading(false);
            });
    }, [user, tripListIsUpdate, t]);

    const onDeleteSegment = (flightId: number, segmentId: number) => {
        const origFlightsCount = flights.length;
        const list = flights
            .map(f => ({ ...f, segmentsCount: f.id === flightId ? f.segmentsCount - 1 : f.segmentsCount }))
            .filter((f) => f.segmentsCount > 0);

        setFlights(list);
        if (origFlightsCount !== list.length) {
            setSelectedFlight(list[0]);
        }
    };


    return (
        <Layout>
            <div className={classes.root}>
                {!!user && <FlightPanel flights={flights} setSelectedFlight={setSelectedFlight} selectedFlight={selectedFlight} isLoading={isLoading} />}

                <Switch>

                    <PrivateRoute component={OrderTest} anonimComponent={SignInDialog} disableClose={true} path="/order-test/:segmentId/:groupId" exact />

                    <PublicRoute restricted={false} component={CountryRequirements} path="/country/:countryId" exact />
                    <PublicRoute restricted={false} component={ChangedCountryRequirements} path="/uc/:ucId" exact />
                    <PublicRoute restricted={false} component={MdArticle} path="/blog/:articleId" exact />

                    <Route exact path="/">
                        {!user && <Landing />}
                        {!!selectedFlight && user && <FlightDetails key={selectedFlight.id} flight={selectedFlight} onDeleteSegment={onDeleteSegment} />}
                        {!isLoading && !selectedFlight && user && <NoFlight />}
                    </Route>

                </Switch>

            </div>
        </Layout>
    );
}