import React from 'react';
import { Dialog, DialogProps, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '../icons/CloseIcon';

export interface CustomDialogProps extends DialogProps {
    hideButtonClose?: boolean;
}

const useStyles = makeStyles(() => ({
    root: {},
    iconClose: {
        zIndex: 99999,
        position: 'absolute',
        right: 7,
        top: 7,
        padding: '9px 2px 3px 9px'
    },
}));

const hasParentWithClass = <T extends HTMLElement>(el: T, classname: string) => {
    let next: T | null = el.parentElement as T;

    while (next) {
        if (next.classList && next.classList.contains(classname)) {
            return true;
        }
        next = next.parentElement as T | null;
    }

    return false;
};

const handlePickersModalClick = <T extends HTMLElement>(e: React.MouseEvent<T, MouseEvent>) => {
    if (!hasParentWithClass(e.target as T, 'MuiPickersPopper-paper')) {
        if (
            document.activeElement &&
            document.activeElement.classList.contains('MuiPickersPopper-paper')
        ) {
            (document.activeElement as T).blur();
        }
    }
};


export default function CustomDialog({ hideButtonClose, ...props }: CustomDialogProps) {
    const classes = useStyles();

    const handleIconClick = (event: any) => {
        props.onClose && props.onClose(event, 'backdropClick');
    };

    return (
        <Dialog
            {...props}
            disableEnforceFocus
            onClick={handlePickersModalClick}
        >

            {!hideButtonClose && (
                <IconButton
                    aria-label="delete"
                    onClick={handleIconClick}
                    className={classes.iconClose}
                >
                    <CloseIcon fontSize="large" />
                </IconButton>
            )}

            {props.children}

        </Dialog>
    );
}