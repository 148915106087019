import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function WarningIcon(props: any) {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 32 32" fill="none">
                <circle cx="16" cy="16" r="16" fill="#E93131" />
                <path d="M14.5751 8.21672L7.51681 20C7.37128 20.2521 7.29428 20.5378 7.29346 20.8288C7.29265 21.1198 7.36805 21.406 7.51216 21.6588C7.65627 21.9117 7.86408 22.1223 8.1149 22.2699C8.36571 22.4175 8.65081 22.4969 8.94181 22.5001H23.0585C23.3495 22.4969 23.6346 22.4175 23.8854 22.2699C24.1362 22.1223 24.344 21.9117 24.4881 21.6588C24.6322 21.406 24.7076 21.1198 24.7068 20.8288C24.706 20.5378 24.629 20.2521 24.4835 20L17.4251 8.21672C17.2766 7.9718 17.0674 7.76931 16.8178 7.62879C16.5682 7.48826 16.2866 7.41443 16.0001 7.41443C15.7137 7.41443 15.4321 7.48826 15.1825 7.62879C14.9329 7.76931 14.7237 7.9718 14.5751 8.21672V8.21672Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
                <path d="M16 12.5V15.8333" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
                <path d="M16 19.1666H16.01" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
            </svg>
        </SvgIcon>
    );
}