import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        textDecoration: 'none',
    },
    paper: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    iconColumn: {
        width: '76px',
        height: '76px',
        borderRadius: '8px',
        backgroundColor: '#F5F7FD',
        marginRight: theme.spacing(2),
    },
    title: {
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '100%',
        marginBottom: '10px',
    },
    message: {
        color: '#6E7898',
    },

}));


export default function CityCard(props: any) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Link to={`/country/${props.countryId}`} className={classes.root}>
            <Paper className={classes.paper} elevation={0}>
                <div className={classes.iconColumn} style={{ backgroundImage: `url('${props.image}')` }}></div>
                <div style={{ flex: 1 }}>
                    <Typography className={classes.title}>{t(props.name)}</Typography>
                    <Typography variant="body1" className={classes.message}>{t(props.message)}</Typography>
                </div>

            </Paper>
        </Link >
    );
}