import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import SegmentDetails from './SegmentDetails';
import * as flightService from '../../../api/flight-service';
import { FlightShortInfo, FlightInfo } from '../../../api/flight-service.types';
import { Person } from '../../../api/user-service.types';
import { useTranslation } from 'react-i18next';
import { useSession } from '../../auth/SessionProvider';

export interface FlightDetailsProps {
    flight: FlightShortInfo,
    onDeleteSegment: (flightId: number, segmentId: number) => void;
}

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(2),
    },
}));

export default function FlightDetails({ flight, ...props }: FlightDetailsProps) {
    const classes = useStyles();
    const { t } = useTranslation();

    const [fullFlight, setFullFlight] = React.useState<FlightInfo>();
    const [fellowList, setFellowList] = React.useState<Person[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [groupedSegments, setGroupedSegments] = React.useState([] as any);

    const { user } = useSession();

    const refreshFellowList = React.useCallback(async (firstSegmentId: number) => {
        setIsLoading(true);
        return flightService.getFellowList(firstSegmentId)
            .then((fellows) => {
                setFellowList(fellows.filter(f => f.id !== user?.person?.id));
            })
            .catch((e) => {
                console.log(e);
            })
            .then(() => {
                setIsLoading(false);
            });
    }, [user]);

    useEffect(() => {
        setIsLoading(true);
        flightService.getById(flight.id)
            .then((flightRes) => {
                setFullFlight(flightRes);
                const firstSegmentId = flightRes.segments[0].id;
                if (!firstSegmentId)
                    return;

                return refreshFellowList(firstSegmentId);

            })
            .catch((e) => {
                console.log(e);
            })
            .then(() => {
                setIsLoading(false);
            });
    }, [flight, refreshFellowList, t]);

    // Group segments into transit and non-transit, and combine them if necessary
    useEffect(() => {
        if ((fullFlight?.segments.length === 0) || (fullFlight?.segments === undefined))
            return;

        let tempTransitSegmentArray = [] as any;
        let tempGroupedSegmentsArray = [] as any;
        let comparedSegment = fullFlight.segments[0];
        let newEl = {} as any;

        for (let i = 0; i < fullFlight.segments.length; i++) {
            if (comparedSegment.isTransit === true && (comparedSegment.arrival.airport.code === fullFlight.segments[i + 1]?.departure.airport.code)) {
                tempTransitSegmentArray.push(comparedSegment);
                comparedSegment = fullFlight.segments[i + 1];
            }
            else {
                newEl = { segment: comparedSegment, transitSegmentList: tempTransitSegmentArray };
                tempGroupedSegmentsArray.push(newEl);
                comparedSegment = fullFlight.segments[i + 1];
                tempTransitSegmentArray = [];
            }
        }
        setGroupedSegments(tempGroupedSegmentsArray);
    }, [fullFlight?.segments]);


    const onEditFellowCompleted = (fellow: Person, isNew: boolean) => {
        if (isNew) {
            setFellowList([...fellowList, fellow]);

        } else {
            const fellows = fellowList.map(f => {
                if (f.id === fellow.id)
                    return fellow;
                return f;
            });
            setFellowList(fellows);
        }
    };


    if (!fullFlight)
        return null;

    const handleDeleteSegment = (segmentId: number) => {
        const segments = fullFlight?.segments.filter((s) => s.id !== segmentId);
        setFullFlight({
            ...fullFlight,
            segments,
        });

        props.onDeleteSegment(fullFlight.id, segmentId);
    };

    const onDeleteFellow = (fellow: Person) => {
        setFellowList(fellowList.filter((f: Person) => f.id !== fellow.id));
    };

    return (
        <Container className={classes.root} disableGutters>
            {groupedSegments.length > 0 && fullFlight.segments.length > 0 && (
                groupedSegments.map((group: any) => (
                    <React.Fragment key={group.segment.id}>
                        <SegmentDetails
                            key={group.segment.id}
                            flight={flight}
                            segment={group.segment}
                            transitSegmentList={group.transitSegmentList}
                            fellowList={fellowList}
                            onEditFellowCompleted={onEditFellowCompleted}
                            onDeleteSegment={handleDeleteSegment}
                            onDeleteFellow={onDeleteFellow}
                            segments={fullFlight.segments}
                            isLoading={isLoading}
                        />
                    </React.Fragment>
                ))
            )}
        </Container>
    );
}