import React from 'react';
import { TextField, StandardTextFieldProps } from '@material-ui/core';
import AdapterDateFns from '@material-ui/pickers/adapter/date-fns';
import { LocalizationProvider, DatePicker } from '@material-ui/pickers';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import { getCurrentLanguageCode } from '../../i18n';
import { format } from "date-fns";

//import { isSafari, isMobile } from "react-device-detect";

interface CustomDatePickerProps extends StandardTextFieldProps {
}

export default function CustomDatePicker(props: CustomDatePickerProps) {

    const localeMap: { [key: string]: { locale: Locale, mask: string, format: string } } = {
        en: { locale: enLocale, mask: '__/__/____', format: 'mm/dd/yyyy' },
        ru: { locale: ruLocale, mask: '__.__.____', format: 'дд.мм.уууу' },
    };

    const isDatePickerSupported = () => {
        var i = document.createElement("input");
        i.setAttribute("type", "date");
        return i.type !== "text";
    };

    const localeCode: string = getCurrentLanguageCode();
    const locale = localeMap[localeCode];

    const [value, setValue] = React.useState(props.value);

    const handleChangePickerValue = (newDate: unknown) => {
        setValue(newDate);

        const dateValue = newDate as Date;
        const stringValue = dateValue && !isNaN(dateValue.getTime())
            ? format(dateValue, 'yyyy-MM-dd')
            : '';

        const evt = { currentTarget: { name: props.name, value: stringValue } } as React.ChangeEvent<HTMLInputElement>;
        props.onChange && props.onChange(evt);
    };

    if (!isDatePickerSupported()) {
        return (
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale.locale}>
                <DatePicker
                    mask={locale.mask}
                    value={value}
                    disabled={props.disabled}
                    onChange={handleChangePickerValue}
                    renderInput={(params) => <TextField {...params} {...props} inputProps={{ ...params.inputProps, ...props.inputProps, placeholder: locale.format }} onChange={undefined} />}
                />
            </LocalizationProvider>

        );
    }

    return (
        <TextField
            {...props}
            type="date"
        />
    );
}