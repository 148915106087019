import React from 'react';
import { DialogContent } from '@material-ui/core';
import Questionnaire from '../../components/questionnaire/Questionnaire';
import CustomDialog from '../../components/mui-extensions/CustomDialog';


// QUESTIONNAIRE DIALOG
export interface QuestionnaireDialogInterface {
    groupId: number,
    openQuestionnaireDialog: boolean;
    handleClose: () => void;
    setCardStatus: (status: string) => void;
};


export default function QuestionnaireDialog({ groupId, openQuestionnaireDialog, handleClose, setCardStatus }: QuestionnaireDialogInterface) {

    return (
        <CustomDialog onClose={handleClose} open={openQuestionnaireDialog} PaperProps={{ style: { maxWidth: "776px", padding: 0 } }} >
            <DialogContent >
                <Questionnaire groupId={groupId} setCardStatus={setCardStatus} />
            </DialogContent>
        </CustomDialog>
    );
}