import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DialogContent, Typography, Button, Grid, TextField, IconButton, Hidden } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../mui-extensions/CustomDialog';
import InviteFriendImg from '../../images/invite-friend.png';
import { ReactComponent as TelegramIcon } from '../icons/TelegramIcon.svg';
import { ReactComponent as WatsAppIcon } from '../icons/WatsAppIcon.svg';
import { useFormik } from 'formik';
import Form from '../form/Form';
import { useSession } from '../auth/SessionProvider';
import CopyIcon from '../icons/CopyIcon';
import BackButton from '../layout/BackButton';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    dialogContent: {
        maxWidth: '700px',
        overflow: 'visible',
    },
    dialogTitle: {
        margin: '16px 0',
    },
    topText: {
        marginTop: '7px',
        marginBottom: '12px',
    },
    copyIcon: {
        fill: 'none',
        width: '24px',
        height: '24px',
    },
    rightCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
    },
    rightImg: {
        width: '100%'
    },
    actions: {
        alignItems: 'center',
        marginTop: '16px',
    },
    submit: {
        marginRight: '12px',
        width: '216px',
    },
    form: {
        overflow: 'visible',
    },
    emailInput: {
        width: '295px',
    },
    refInput: {
        width: '295px',
    }
}));

export default function InviteFriendDialog({ open, closeClick, backClick }: { open: boolean; closeClick: () => void; backClick: () => void; }) {
    const classes = useStyles();

    const { user } = useSession();

    const referralLink = user ? `${window.location.protocol.replace(':', '')}://${window.location.host}/?invite=${user?.referralCode}#signUp` : '';
    const shareMessage = 'Приглашаю пройти регистрацию на сайте telepath.ru. Telepath – ваш персональный ассистент для путешествий в эпоху COVID.';
    const telegramLink = `https://t.me/share/url?url=${referralLink}&text=${shareMessage}`;
    const watsAppLink = `https://wa.me/?text=${encodeURIComponent(referralLink)}`;

    const CopyButton = () => (
        <IconButton
            onClick={() => {
                navigator.clipboard.writeText(referralLink);
            }}
            onMouseDown={() => {
                navigator.clipboard.writeText(referralLink);
            }}
            onTouchStart={() => {
                navigator.clipboard.writeText(referralLink);
            }}
            title="Копировать">
            <CopyIcon className={classes.copyIcon} />
        </IconButton>
    );

    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`InviteFriendDialog.${key}`, options);

    const { values, touched, errors, ...formik } = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: () => { },
    });

    const emailSubject = 'Приглашение в telepath.ru';
    const emailBody = `${shareMessage} ${referralLink}`;
    const emailLink = `mailto:${values.email}?subject=${emailSubject}&body=${emailBody}`;

    return (
        <CustomDialog
            maxWidth="md"
            onClose={() => closeClick()}
            open={open}
            className={classes.root}
        >
            <DialogContent className={classes.dialogContent}>
                <Grid container spacing={3} justify="space-between">
                    <Grid item xs={12} sm={8}>

                        <BackButton onClick={() => backClick()} />
                        <Typography variant="h6" component="h2" className={classes.dialogTitle}>
                            {tt('title')}
                        </Typography>


                        <Typography variant="body1" className={classes.topText}>
                            Пригласите одного или нескольких друзей для ознакомления с сервисом Telepath и получите Бесплатный доступ к сервису навсегда.
                        </Typography>

                        <Typography variant="body1" className={classes.topText}>
                            Отправьте ссылку или скопируйте ее.
                        </Typography>


                        <Form className={classes.form}>
                            <Grid container spacing={3} justify="space-between">
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        margin="normal"
                                        name="link"
                                        label={tt('referralLinkLabel')}
                                        type="text"
                                        value={referralLink}
                                        InputProps={{ endAdornment: <CopyButton /> }}
                                        className={classes.refInput}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        margin="normal"
                                        required
                                        label={t('emailLabel')}
                                        name="email"
                                        autoFocus
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={values.email}
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={touched.email && errors.email}
                                        className={classes.emailInput}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container className={classes.actions} wrap="nowrap">

                                <Button
                                    type="button"
                                    href={emailLink}
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    {tt('sendEmailBtn')}
                                </Button>

                                <IconButton href={telegramLink} title="Отправить в telagram">
                                    <TelegramIcon />
                                </IconButton>

                                <IconButton href={watsAppLink} title="Отправить в watsapp">
                                    <WatsAppIcon />
                                </IconButton>

                            </Grid>

                        </Form>

                    </Grid>

                    <Grid item xs={12} sm={4} className={classes.rightCell}>
                        <Hidden xsDown>
                            <img src={InviteFriendImg} alt='' className={classes.rightImg} />
                        </Hidden>
                    </Grid>
                </Grid>



            </DialogContent>
        </CustomDialog >
    );
}