import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';
import Footer from './Footer';
import Header from './Header';

export interface LayoutProps {
    children?: React.ReactNode;
    showAuthControls?: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
}));

const Layout = ({ children, showAuthControls = true }: LayoutProps) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Header />
            <Container maxWidth="lg">
                <Grid container direction="column" wrap="nowrap">
                    <main>
                        {children}
                    </main>
                </Grid>
            </Container>

            <Footer />
        </div>
    );
};

export default Layout;