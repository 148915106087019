import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en/translation.json';
import translationRU from './locales/ru/translation.json';

const resources = {
    en: {
        translation: translationEN
    },
    ru: {
        translation: translationRU
    }
};


i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    //.use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: 'ru',
        ns: ['translation'],
        defaultNS: 'translation',
        supportedLngs: ['ru'],
        resources,
        nonExplicitSupportedLngs: true,
        lowerCaseLng: true,
        load: 'languageOnly',
        fallbackLng: 'ru',
        //preload: ['ru', 'en'],
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });

i18n.on('languageChanged', (newLocale) => {

});

// load additional namespaces after initialization
// i18n.loadNamespaces('nn', (err, t) => { /* ... */ });



export default i18n;

export const getCurrentLanguageCode = () => (i18n.language || 'ru').split('-')[0];