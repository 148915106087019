import React, { useEffect } from 'react';
import { QuestionInterface } from '../../api/rule-service.types';
import * as ruleService from '../../api/rule-service';
import PassingQuestionnaire from './PassingQuestionnaire';
import QuestionnaireResult from './QuestionnaireResult';


// QUESTIONNAIRE     
export interface QuestionnaireInterface {
    groupId: number,
    setCardStatus: (status: string) => void;
};


export default function Questionnaire({ groupId, setCardStatus }: QuestionnaireInterface) {
    const [question, setQuestion] = React.useState<QuestionInterface>();
    const [questionnaireStatus, setQuestionnaireStatus] = React.useState<boolean | undefined>(undefined);


    // Get first questions
    useEffect(() => {
        setQuestion(undefined);
        ruleService.startQuestionnaire(groupId)
            .then((res) => setQuestion(res))
            .catch((e) => console.log(e));
    }, [groupId]);

    // Send an answer to a question, get a new question or a questionnaire status
    const setAnswerToQuestion = (groupId: number, stepId: number, answer: boolean) => {
        setQuestion(undefined);
        ruleService.setAnswerToQuestion(groupId, stepId, answer)
            .then((res) => {
                if (res.passed === true) { setQuestionnaireStatus(true); setCardStatus('passed'); };
                if (res.passed === false) { setQuestionnaireStatus(false); setCardStatus('not_passed'); };
                if (res.name) setQuestion(res);
            })
            .catch((e) => console.log(e));
    };

    // Button - go through the questionnaire again
    const handleTakeQuestionnaireAgain = () => {
        setQuestionnaireStatus(undefined);
        ruleService.startQuestionnaire(groupId)
            .then((res) => setQuestion(res))
            .catch((e) => console.log(e));
    };


    return (
        <React.Fragment>
            {questionnaireStatus === undefined &&
                <PassingQuestionnaire
                    question={question}
                    groupId={groupId}
                    setAnswerToQuestion={setAnswerToQuestion}
                />
            }
            {(questionnaireStatus === true || questionnaireStatus === false) &&
                <QuestionnaireResult questionnaireStatus={questionnaireStatus} handleButtonClick={handleTakeQuestionnaireAgain} />
            }
        </React.Fragment>
    );

}