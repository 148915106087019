import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { useTranslation } from 'react-i18next';

function Copyright() {
    return (
        <Typography variant="body1" align="left">
            {'© '}
            <Link color="inherit" href="/">
                Telepath
            </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    footer: {
        marginTop: 'auto',
        marginBottom: 0,
        overflow: 'hidden',
        width: '100%',

    },
    footerContainer: {
        paddingLeft: 25,
        paddingRight: 25,
        overflow: 'hidden',
        maxWidth: theme.breakpoints.values.lg,
        margin: '0 auto',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 16,
            paddingRight: 16,
        },
    },
    wrapBlock1: {
        justifyContent: "flex-start",
        marginBottom: 20,
        [theme.breakpoints.down('xs')]: {
            justifyContent: "center",
        },
    },
    wrapBlock2: {
        justifyContent: "flex-end",
        marginBottom: 20,
        [theme.breakpoints.down('xs')]: {
            justifyContent: "center",
        },
    },
    toolbarLink: {
        flexShrink: 0,
        color: theme.palette.primary.main,
        fontWeight: 400,
        marginLeft: 16,
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            marginLeft: 12,
        },
    },
    banner: {
        marginLeft: 24,
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            marginLeft: 9,
            marginRight: 9,
            marginBottom: 9,
        },
    }
}));

export default function Footer(props: any) {
    const classes = useStyles();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`Footer.${key}`, options);

    return (
        <footer className={classes.footer}>
            <Grid container className={classes.footerContainer}>
                <Grid sm={5} xs={12} container item alignItems="center" className={classes.wrapBlock1}>
                    <Copyright />
                    <Link noWrap href='/blog/confidentiality' className={classes.toolbarLink}>{tt('linkConfText')}</Link>
                </Grid>
                <Grid sm={7} xs={12} container item alignItems="center" className={classes.wrapBlock2}>
                </Grid>
            </Grid>
        </footer>
    );
}

Footer.propTypes = {
};