import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ReqCardDialogButtonDoneIcon from '../../icons/ReqCardDialogButtonDoneIcon';
import { useHistory } from 'react-router-dom';
import * as orderService from '../../../api/order-service';
import * as flightService from '../../../api/flight-service';


// DIALOG BUTTON   
export interface ReqCardDialogButtonInterface {
    buttonText: string,
    buttonTextAlt?: string,
    buttonType: 'orderTest' | 'setStatus',
    cardStatus?: string,
    segmentId: number,
    flightId: number,
    requirementId: number,
    setCardStatus: (status: string | undefined) => void,
    showErrorDialog?: (title: string, message: string)=>{},
    errorDialogContent?: {title: string, message: string}
};

const useStyles = makeStyles((theme) => ({
    button: {
        height: 46,
        marginRight: 26,
        marginBottom: 14,
        [theme.breakpoints.down(670)]: {
            marginRight: 0,
            width: '100%',
        },
    },
    buttonOrderTest: {
        backgroundColor: theme.palette.primary.main,
        padding: '16px 30px',
    },
    buttonDoneIcon: {
        fontSize: 22,
        marginTop: 8
    },
    buttonSetStatusPassed: {
        backgroundColor: theme.palette.text.primary,
        padding: '16px 40px',
        "&:hover": {
            backgroundColor: theme.palette.text.primary,
            boxShadow: 'none',
        },
    },
    buttonSetStatusNotPassed: {
        backgroundColor: '#3AC582',
        padding: '16px 25px',
        "&:hover": {
            backgroundColor: '#3AB082',
            boxShadow: 'none',
        },

    }
}));


// DIALOG BUTTON 
export default function ReqCardDialogButton({ buttonText, buttonTextAlt, buttonType, cardStatus, segmentId, flightId, requirementId, setCardStatus, showErrorDialog, errorDialogContent }: ReqCardDialogButtonInterface) {
    const classes = useStyles();
    let buttonStyle;
    let buttonIcon;
    let currentTextOnButton;
    const history = useHistory();
    const [isDisableButton, setIsDisableButton] = React.useState(false);

    switch (buttonType) {
        case "orderTest":
            buttonStyle = classes.buttonOrderTest;
            currentTextOnButton = buttonText;
            break;
        default:
            switch (cardStatus) {
                case "passed":
                    buttonStyle = classes.buttonSetStatusPassed;
                    buttonIcon = undefined;
                    currentTextOnButton = buttonTextAlt;
                    break;
                default:
                    buttonStyle = classes.buttonSetStatusNotPassed;
                    buttonIcon = <ReqCardDialogButtonDoneIcon className={classes.buttonDoneIcon} />;
                    currentTextOnButton = buttonText;
                    break;
            }
            break;
    }

    const handleButtonClick = () => {
        switch (buttonType) {
            case "orderTest":
                if (segmentId) history.push(`/order-test/${segmentId}/${requirementId}`);
                else if (showErrorDialog && errorDialogContent) showErrorDialog(errorDialogContent.title, errorDialogContent.message);
                break;
            default:
                switch (cardStatus) {
                    case "passed":
                        setIsDisableButton(true);
                        orderService.setTestStatus(requirementId, false)
                            .then(() => {
                                flightService.getListRequirement(flightId, segmentId)
                                    .then((cards) => {
                                        setCardStatus(cards.find((c: any) => c.id === requirementId).status);
                                        setIsDisableButton(false);
                                    })
                                    .catch((e) => console.log(e));
                            })
                            .catch((e) => console.log(e));
                        break;
                    default:
                        setIsDisableButton(true);
                        orderService.setTestStatus(requirementId, true)
                            .then(() => { setCardStatus('passed'); setIsDisableButton(false); })
                            .catch((e) => console.log(e));
                        break;
                }
                break;
        }
    };

    return (
        <Button color="primary" variant="contained" className={clsx(classes.button, buttonStyle)} onClick={() => handleButtonClick()} disabled={isDisableButton}>
            {buttonIcon} {currentTextOnButton}
        </Button>
    );
}
