import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, Container, Grid, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import BackButton from '../layout/BackButton';
import matter from 'gray-matter';
import CustomMarkdown from '../mui-extensions/CustomMarkdown';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
    contentParagraph: {
        textAlign: 'justify',
    },
    contentLink: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
    },
    mdContent: {
        marginBottom: 70
    }
}));

export type MarkdownData = {
    content: string,
    data: {
        [key: string]: any
    },
    [key: string]: any
}

export async function getMarkdownFileData(articleId: string) {
    let file;
    let data: MarkdownData;
    try {
        file = await import(`../../content/ru/blog/${articleId}.md`);
        data = matter(file.default);
    }
    catch (err) {
        console.error(err);
        return {} as MarkdownData;
    }

    return data;
}

export default function MdArcticle() {
    const classes = useStyles();

    let { articleId } = useParams<{ articleId: any }>();
    const [isLoading, setIsLoading] = React.useState(true);
    const [article, setArticle] = React.useState({} as MarkdownData);

    useEffect(() => {
        setIsLoading(true);
        getMarkdownFileData(articleId).then(data => {
            setArticle(data);
            setIsLoading(false);
        }).catch((err) => {
            console.error(err);
            setIsLoading(false);
        });

    }, [articleId]);

    const markdownOptions = {
        p: {
            props: {
                className: classes.contentParagraph,
            }
        },
        a: {
            props: {
                className: classes.contentLink,
            }
        }
    };

    return (
        <Container className={classes.root} disableGutters>

            <BackButton />

            {isLoading && (
                <Grid
                    container
                    justify="center"
                    alignItems="center">
                    <Box mt={8}>
                        <CircularProgress size={32} />
                    </Box>
                </Grid>
            )}

            {!isLoading && (
                <React.Fragment>
                    <Typography component="h2" variant="h1" className={classes.title}>{article.data.title}</Typography>
                    <Box className={classes.mdContent}>
                        <CustomMarkdown children={article.content} overrides={markdownOptions} />
                    </Box>
                </React.Fragment>
            )}
        </Container>
    );
}

