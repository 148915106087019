import axios from 'axios';
import { getServerError } from './error-service';
import { VerifyWelcomeCodeResponse, SetPasswordParams, PersonParams, DocumentParams, Person } from './user-service.types';

export const signIn = (login: string, password: string) =>
    axios.post<{ accessToken: string }>('/api/login', { login, password })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error, 'SignIn');
        });

export const getSession = () =>
    axios.get('/api/session')
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

export const verifyWelcomeCode = (code: string) =>
    axios.post<VerifyWelcomeCodeResponse>('/api/verification/code', { code })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });


export const confirmPhoneNumber = (phone: string, code: string) =>
    axios.post<VerifyWelcomeCodeResponse>('/api/verification/code', { phone, code })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error, 'ConfirmPhoneNumber');
        });

export const signUp = (data: SetPasswordParams) =>
    axios.post<{ accessToken: string }>('/api/account', data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error, 'SetPassword');
        });


export const changePassword = (data: SetPasswordParams) =>
    axios.put<{ accessToken: string }>('/api/account/password', data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error, 'ChangePassword');
        });

export const sendVerificationCodeForPhone = (phone: string) =>
    axios.post('/api/verification/phone', { phone })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error, 'ConfirmPhoneNumber');
        });

export const sendVerificationCodeForEmail = (email: string) =>
    axios.post('/api/verification/email', { email })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error, 'SignUp');
        });

export const deleteAccount = () =>
    axios.delete('/api/account')
        .then(() => {
            return true;
        })
        .catch((error) => {
            throw getServerError(error);
        });

export const setMailingSetting = (mailingSettings: any) =>
    axios.put('/api/account/mailing-settings', mailingSettings)
        .then(() => {
            return true;
        })
        .catch((error) => {
            throw getServerError(error);
        });

export const getMailingSetting = () =>
    axios.get('/api/account/mailing-settings')
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

export const verificationRecoveryCode = (code: string) =>
    axios.post('/api/verification/code', { code })
        .then(() => {
            return true;
        })
        .catch((error) => {
            throw getServerError(error, 'ConfirmRecoveryCode');
        });

export const resetPassword = (email: string) =>
    axios.post('/api/account/password/reset', { email })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error, 'ResetPassword');
        });

export const sendRecoveryCodeOnEmail = (phone: string) =>
    axios.post('/api/account/recovery', { phone })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error, 'ConfirmRecoveryCode');
        });

export const createPerson = (person: PersonParams) =>
    axios.post('/api/account/person', person)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

export const editPerson = (person: PersonParams, id: any) =>
    axios.put('/api/person/' + id, person)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

export const getFellowList = () =>
    axios.get<Person[]>('/api/person')
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

export const getInfoPerson = (id: any = undefined) =>
    axios.get('/api/person/' + id)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

export const createPersonDocument = (personId: number, document: DocumentParams) =>
    axios.post(`/api/person/${personId}/document`, document)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

export const setDefaultDocument = (personId: number, documentId: number) =>
    axios.put(`/api/person/${personId}/document/${documentId}`, { isDefault: true })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });

export const getPersonDocumentList = (personId: number) =>
    axios.get(`/api/person/${personId}/document`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });


export const getPersonName = (person: Person) => {
    let result = '';
    if (person.lastName) {
        result = `${result}${person.lastName}`;
    }

    if (person.firstName) {
        result = `${result} ${person.firstName[0]}.`;
    }

    if (person.middleName) {
        result = `${result} ${person.middleName[0]}.`;
    }

    return result;
};

export const getFullName = (person: Person) => {
    let parts = [];
    if (person.lastName) {
        parts.push(person.lastName);
    }

    if (person.firstName) {
        parts.push(person.firstName);
    }

    if (person.middleName) {
        parts.push(person.middleName);
    }

    return parts.join(' ');
};

//Formatting a phone number +X (XXX) XXX-XX-XX
export const formatPhone = (numberPhone: string | undefined) => {
    if (!numberPhone)
        return numberPhone;

    return numberPhone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "$1 ($2) $3-$4-$5");
};

export const compareByFullNameAsc = (personA: Person, personB: Person) => {
    const fioA = getFullName(personA).toLowerCase();
    const fioB = getFullName(personB).toLowerCase();

    if (fioA < fioB) { return -1; }
    if (fioA > fioB) { return 1; }
    return 0;
};

export const sendReferralLink = (email: string) =>
    axios.post('/api/todo', { email })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw getServerError(error);
        });