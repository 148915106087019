import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import * as orderService from '../../api/order-service';
import * as flightService from '../../api/flight-service';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@material-ui/core';
import Form from '../form/Form';
import LabOnMap from './LabOnMap';
import TotalCost from './TotalCost';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { useFormik } from 'formik';
import { getCurrentLanguageCode } from '../../i18n';
import * as Yup from 'yup';
import { ServerError } from '../../api/error-service';
import { useSession } from '../auth/SessionProvider';
import { formatTo_dd_MMMM_tt_tt, getPassinTimeForTest } from '../../helpers/datetimeHelper';
import clsx from 'clsx';


export interface ListCitiesToTakeTestInterface {
    id: number,
    lat: number,
    lon: number,
    name: string,
}

export interface ListLaboratoriesAtOrganizationInterface {
    code: string,
    id: number,
    name: string,
    units: [{
        address: string
        id: number
        lat: number
        lon: number
        name: string
    }]
}

export interface TestListInterface {
    options: []
    tests: [{
        id: number,
        name: string,
        description: string,
        price: string
        readyTimeHours: number
    }]
}

export interface FellowListInterface {
    birthDate?: string,
    email?: string,
    firstName: string,
    id: number,
    isOwner?: true,
    lastName: string,
    middleName?: string,
    phone?: string
}


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 10,
        marginBottom: 72
    },
    wrapLeftBlock: {
        maxWidth: 300,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
    wrapRightBlock: {
        paddingLeft: 10,
        overflow: 'hidden',
        borderRadius: 8,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
        },
    },

    // MAP
    wrapMap: {
        overflow: 'hidden',
        borderRadius: 8,
    },

    // LEFT COLUMN
    textTitle: {
        lineHeight: 1,
        marginBottom: 15,
        width: "100%",
    },
    textDescription: {
        marginTop: 0,
        lineHeight: 1.1,
        marginBottom: 11,
        width: "100%",
    },
    autocompleteCity: {
        width: "100%",
        marginBottom: 12,
    },
    selectOrganization: {
        width: "100%",
        marginBottom: 20,
    },
    formHelperText: {
        color: '#f44336',
    },
    noOptions: {
        color: theme.palette.secondary.dark,
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    enterYourCityText: {
        marginTop: 0,
        marginBottom: 23,
        lineHeight: 1.3,
        width: "100%",
    },
    formLabelRadioButton: {
        fontWeight: 700,
        fontSize: 16,
        marginBottom: 7,
    },
    formControlRadioButton: {
        marginBottom: 25,
    },
    formControlLabel: {
        marginTop: 12,
        marginBottom: 2,
        paddingLeft: 42,
        position: 'relative',
    },
    radioButton: {
        position: 'absolute',
        color: theme.palette.primary.main,
        height: 22,
        width: 22,
        top: 0,
        left: 11,
        '& svg': {
            fontSize: 24,
        }
    },
    radioButtonLabelCustomTitle: {
        fontWeight: 600,
    },
    radioButtonLabelCustomTitleActive: {
        color: theme.palette.primary.main
    },
    radioButtonLabelCustomDescription: {
        marginTop: 1,
    },
    enoughTimeForTest: {
        backgroundColor: '#FEF5DD',
        fontWeight: 700,
        marginTop: 0,
        display: 'inline',
        padding: '3px 1px'
    },
    notEnoughTimeForTest: {
        color: '#E12D29',
        fontWeight: 700,
    },
    totalCostBlockPC: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    totalCostBlockMobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        },
    },
}));


// Calculate the nearest city and check the distance
function getNearestCityToTakeTest(listCities: ListCitiesToTakeTestInterface[], currentPosition: Array<number>) {
    const cityWithMinimumDistance = listCities.reduce((prevCity: ListCitiesToTakeTestInterface, currCity: ListCitiesToTakeTestInterface) => {
        let prevDistant = Math.abs(prevCity.lat - currentPosition[0]) + Math.abs(prevCity.lon - currentPosition[1]);
        let currDistant = Math.abs(currCity.lat - currentPosition[0]) + Math.abs(currCity.lon - currentPosition[1]);
        return prevDistant < currDistant ? prevCity : currCity;
    });

    let distanceToCity = Math.abs(cityWithMinimumDistance.lat - currentPosition[0]) + Math.abs(cityWithMinimumDistance.lon - currentPosition[1]);
    return distanceToCity < 0.42 ? cityWithMinimumDistance : false;
}


// Radio button label Component
function RadioButtonLabelCustom({ selectTestType, currentTestType, titleText, descriptionText,
    priceText, readyTimeHours, passResultValidHours, passResultCountdownTime, segment, setReadyTimeHoursForTitle }: any) {
    const classes = useStyles();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`OrderTestLab.${key}`, options);
    const spareHoursBeforeDeparture = 4;

    // Calculate whether there is enough time to pass the test
    let currentTime = new Date();
    let passinTimeFrom = new Date(segment.departure.at);
    passinTimeFrom.setHours(passinTimeFrom.getHours() - readyTimeHours - spareHoursBeforeDeparture);
    let isEnoughTimeForTest = currentTime.getTime() < passinTimeFrom.getTime();

    // Get information about the selected segment
    useEffect(() => {
        if (selectTestType === currentTestType) {
            setReadyTimeHoursForTitle(readyTimeHours);
        }
    }, [readyTimeHours, setReadyTimeHoursForTitle, selectTestType, currentTestType]);

    return (
        <Grid>
            <Typography component="p" className={clsx(classes.radioButtonLabelCustomTitle, `${selectTestType === currentTestType ? classes.radioButtonLabelCustomTitleActive : ''}`)}>
                {`${titleText} — ${priceText ? parseInt(priceText, 10) : ""} ${tt('totalCostCurrency')}`}
            </Typography>
            <Typography component="p" className={classes.radioButtonLabelCustomDescription}>
                {descriptionText}
                {/* {tt('priceTestTextPrice')} {`${priceText ? parseInt(priceText, 10) : ""} `}{tt('totalCostCurrency')} */}
            </Typography>

            {segment.id && readyTimeHours && passResultValidHours && passResultCountdownTime &&
                <Typography component="p" className={`${isEnoughTimeForTest ? classes.enoughTimeForTest : classes.notEnoughTimeForTest}`}>
                    {tt('handOverFrom')}
                    {formatTo_dd_MMMM_tt_tt(
                        getPassinTimeForTest('from', passResultCountdownTime, segment, readyTimeHours, passResultValidHours),
                        t)
                    }
                    {tt('handOverTo')}
                    {formatTo_dd_MMMM_tt_tt(
                        getPassinTimeForTest('to', passResultCountdownTime, segment, readyTimeHours, passResultValidHours, spareHoursBeforeDeparture),
                        t)
                    }
                </Typography>
            }
        </Grid>
    );

}


export default function OrderTestLab(props: any) {

    const classes = useStyles();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`OrderTestLab.${key}`, options);

    const [currentPosition, setCurrentPosition] = React.useState([] as any);
    const [fellowList, setFellowList] = useState<FellowListInterface[]>();
    const [fellowIdArrayToRegisterForRest, setFellowIdArrayToRegisterForRest] = useState([] as any);

    const [validationEnabled, setValidationEnabled] = React.useState(false);
    const [serverError, setServerError] = React.useState<ServerError>();
    const [isAcceptGeo, setIsAcceptGeo] = useState(false);
    const [isBookFellow, setIsBookFellow] = useState(false);
    const [testType, setTestType] = useState('0');
    const [newCenterOnMap, setNewCenterOnMap] = useState([] as any);
    const lang = getCurrentLanguageCode();

    const [listCitiesToTakeTest, setListCitiesToTakeTest] = useState<ListCitiesToTakeTestInterface[]>([]);
    const [selectedCity, setSelectedCity] = useState<ListCitiesToTakeTestInterface | null>(null);
    const [listOrganizationToTakeTest, setListOrganizationToTakeTest] = useState<ListLaboratoriesAtOrganizationInterface[]>([]);
    const [listLaboratoriesAtOrganization, setListLaboratoriesAtOrganization] = useState<ListLaboratoriesAtOrganizationInterface>();
    const [testList, setTestList] = useState<TestListInterface>();
    const [price, setPrice] = useState('');
    const [texeWhichTypeSelected, setTexeWhichTypeSelected] = useState('');
    const { user } = useSession();

    const { values, touched, errors, setFieldValue, ...formik } = useFormik({
        validateOnChange: validationEnabled,
        validateOnBlur: validationEnabled,
        initialValues: {
            cityId: '',
            cityName: '',
            organizationId: '',
            testId: 0,
            optionId: '',
            persons: '',
        },

        validationSchema: Yup.object({
            cityId: Yup.string()
                .required(tt('errorSelectCity')),
            organizationId: Yup.string()
                .required(tt('errorChooseOrganization')),
        }),

        onSubmit: async (values) => {
            setServerError(undefined);
            let body = {
                cityId: parseInt(values.cityId, 10),
                optionId: values.optionId ? parseInt(values.optionId, 10) : undefined,
                persons: isBookFellow ? fellowIdArrayToRegisterForRest : [user?.person?.id]
            };

            return orderService.signUpForTest(values.testId, body)
                .then((res) => {
                    window.open(res.passTest.paymentLink, '_self');
                })
                .catch((err: ServerError) => {
                    setServerError(err);
                });
        },
    });


    // Gets a list of cities where the test can be taken
    useEffect(() => {
        orderService.getListCitiesToTakeTest(props.passTypeIdArray, lang)
            .then((res: ListCitiesToTakeTestInterface[]) => {
                setListCitiesToTakeTest(res.sort((a, b) => (a.name > b.name) ? 1 : -1));
            })
            .catch();
    }, [props.passTypeIdArray, lang]);

    // Get current position
    useEffect(() => {
        const success = ({ coords }: any) => {
            setCurrentPosition([coords.latitude, coords.longitude]);
            setIsAcceptGeo(true);
        };
        const error = () => {
            setCurrentPosition([55.755819, 37.617644]);
            setIsAcceptGeo(true);
        };
        navigator.geolocation.getCurrentPosition(success, error, { timeout: 10000 });
    }, []);

    // Calculates if there is a city nearby where you can take the test 
    useEffect(() => {
        if (listCitiesToTakeTest.length === 0)
            return;

        const foundCity = getNearestCityToTakeTest(listCitiesToTakeTest, currentPosition);
        if (foundCity) {
            setSelectedCity(foundCity);
            setFieldValue('cityId', foundCity?.id);
            setFieldValue('cityName', foundCity?.name);
            orderService.getListOrganizationToTakeTest(foundCity?.id, props.passTypeIdArray)
                .then((res) => {
                    setListOrganizationToTakeTest(res);

                    if (res.length === 1) {
                        setFieldValue('organizationId', 0);
                        setListLaboratoriesAtOrganization(res[0]);
                        orderService.getTestVariantsAndPriceAndOptions(foundCity?.id.toString(), res[0].id, props.passTypeIdArray)
                            .then((res) => {
                                setTestList(res);
                                setPrice(res?.tests['0'].price || '');
                                setTexeWhichTypeSelected(res?.tests['0'].name || '');
                                setFieldValue('testId', res?.tests['0'].id);
                            })
                            .catch();
                    }

                })
                .catch();
        };
    }, [currentPosition, listCitiesToTakeTest, setFieldValue, props.passTypeIdArray]);

    // Getting a list of fellow travelers
    useEffect(() => {
        flightService.getFellowList(props.segmentId)
            .then((res) => {
                setFellowList(res);
            })
            .catch((e) => {
                console.log(e);
            });
    }, [props.segmentId]);

    // Get the ID of all fellow travelers
    useEffect(() => {
        if (fellowList) {
            setFellowIdArrayToRegisterForRest(fellowList.map((fellow: any) => fellow.id));
        }
    }, [fellowList, isBookFellow]);


    // Selects the type of test, and loads the pricing information
    const handleTestTypeSelection = (event: any) => {
        setTestType(event.target.value);
        setPrice(testList?.tests[event.target.value].price || '');
        setTexeWhichTypeSelected(testList?.tests[event.target.value].name || '');
        setFieldValue('testId', testList?.tests[event.target.value].id);
    };

    // Changes the selected city, loads the list of organizations in this city
    const handlerChangeCityPosition = (idCity: number, lat: number, lon: number) => {
        setNewCenterOnMap([lat, lon]);
        if (idCity) {
            orderService.getListOrganizationToTakeTest(idCity, props.passTypeIdArray)
                .then((res) => {
                    setListOrganizationToTakeTest(res);

                    if (res.length === 1) {
                        setFieldValue('organizationId', 0);
                        setListLaboratoriesAtOrganization(res[0]);
                        orderService.getTestVariantsAndPriceAndOptions(idCity.toString(), res[0].id, props.passTypeIdArray)
                            .then((res) => {
                                setTestList(res);
                                setPrice(res?.tests['0'].price || '');
                                setTexeWhichTypeSelected(res?.tests['0'].name || '');
                                setFieldValue('testId', res?.tests['0'].id);
                            })
                            .catch();
                    }

                })
                .catch();
        }
        else {
            setListOrganizationToTakeTest([]);
        }
    };

    // Select an organization in the city in which we want to take the test, load test options and prices
    const handlerChangeOrganizationToTakeTest = (item: any) => {
        setFieldValue('organizationId', item.target.value);
        setListLaboratoriesAtOrganization(listOrganizationToTakeTest[item.target.value]);
        orderService.getTestVariantsAndPriceAndOptions(values.cityId, listOrganizationToTakeTest[item.target.value].id, props.passTypeIdArray)
            .then((res) => {
                setTestList(res);
                setPrice(res?.tests['0'].price || '');
                setTexeWhichTypeSelected(res?.tests['0'].name || '');
                setFieldValue('testId', res?.tests['0'].id);
            })
            .catch();
    };

    // Resetting the field with organizations in the city when changing the city (or other changes in the city field)
    const handlerResetCityOrganizationsTestPrices = () => {
        setListOrganizationToTakeTest([]);
        setFieldValue('organizationId', '');
        setFieldValue('cityId', '');
        setListLaboratoriesAtOrganization(undefined);
        setTestList(undefined);
        setPrice("");
        setTexeWhichTypeSelected("");
        setValidationEnabled(false);
        setTestType('0');
        formik.setTouched({});
    };

    // Test recording button
    const handleCheckoutButton = () => {
        setValidationEnabled(true);
        formik.submitForm();
    };


    return (
        <Form>
            {isAcceptGeo &&
                <Grid container justify="space-between" alignItems="flex-start" className={classes.root}>

                    <Grid item container sm={12} md={4} className={classes.wrapLeftBlock}>
                        <Typography variant="h2" component="h3" className={classes.textTitle}>{tt('title1')}</Typography>

                        <Typography component="p" className={classes.textDescription}>{tt('description')}</Typography>

                        {/* Autocomplete component with input of cities */}
                        <Autocomplete
                            filterOptions={createFilterOptions({ matchFrom: 'start', trim: true })}
                            autoSelect={values.cityName.length >= 3}
                            noOptionsText={tt('inputCityNoFound')}
                            disableClearable={!values.cityName}
                            value={selectedCity}
                            inputValue={values.cityName}
                            options={listCitiesToTakeTest}
                            onChange={(e: any, value: any) => {
                                handlerResetCityOrganizationsTestPrices();
                                setSelectedCity(value);
                                setFieldValue('cityId', value?.id || '');
                                setFieldValue('cityName', value?.name || '');
                                handlerChangeCityPosition(value?.id, value?.lat || '', value?.lon || '');
                            }}
                            className={classes.autocompleteCity}
                            classes={{
                                option: classes.option,
                                noOptions: classes.noOptions
                            }}
                            autoHighlight
                            getOptionLabel={(option) => (option.name ? option.name : "")}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderOption={(option: any) => (
                                <React.Fragment>
                                    {option.name}
                                </React.Fragment>
                            )}
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    margin="normal"
                                    label={tt('inputCity')}
                                    value={values.cityName}
                                    name="cityName"
                                    onChange={(e: any) => {
                                        formik.handleChange(e);
                                        handlerResetCityOrganizationsTestPrices();
                                    }}
                                    onBlur={formik.handleBlur}
                                    fullWidth
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off',
                                    }}
                                    required
                                    error={touched.cityId && Boolean(errors.cityId)}
                                    helperText={touched.cityId && errors.cityId}
                                />
                            )}
                        />


                        {/* Select component with choice of organizations */}
                        <FormControl variant="filled" fullWidth className={classes.selectOrganization}>
                            <InputLabel id="list-organization-to-take-test">{tt('inputOrganization')}</InputLabel>
                            <Select
                                labelId="list-organization-to-take-test"
                                id="list-organization-to-take-test"
                                value={values.organizationId}
                                name="organizationId"
                                onChange={(item) => handlerChangeOrganizationToTakeTest(item)}
                                label={tt('inputOrganization')}
                                disabled={listOrganizationToTakeTest.length === 0 || !values.cityId}
                                error={touched.organizationId && Boolean(errors.organizationId)}
                            >
                                {listOrganizationToTakeTest.map((organization: any, index: any) => {
                                    return (
                                        <MenuItem key={organization.id} id={organization.id} value={index}>{organization.name}</MenuItem>
                                    );
                                })}
                            </Select>
                            <FormHelperText className={classes.formHelperText}>
                                {touched.organizationId && errors.organizationId}
                            </FormHelperText>
                        </FormControl>


                        <Typography component="p" className={classes.enterYourCityText}>{tt('enterYourCityText')}</Typography>


                        {/* RadioButton component with choice test type */}
                        {testList &&
                            <FormControl component="fieldset" className={classes.formControlRadioButton}>
                                <FormLabel component="span" className={classes.formLabelRadioButton} >{tt('testTypeSelectionTitle')}</FormLabel>
                                <RadioGroup aria-label="selectTestType" name="selectTestType" value={testType} onChange={handleTestTypeSelection}>
                                    {testList?.tests.map((test: any, index: number) => {
                                        return (
                                            <FormControlLabel
                                                key={test.id}
                                                value={index.toString()}
                                                className={classes.formControlLabel}
                                                control={
                                                    <Radio
                                                        color="primary"
                                                        className={classes.radioButton}
                                                    />
                                                }
                                                label={
                                                    <RadioButtonLabelCustom
                                                        selectTestType={testType}
                                                        currentTestType={index.toString()}
                                                        titleText={test.name}
                                                        descriptionText={test.description}
                                                        priceText={test.price}
                                                        readyTimeHours={test.readyTimeHours}
                                                        passResultValidHours={props.countryPassSteps[0].passResultValidHours}
                                                        passResultCountdownTime={props.countryPassSteps[0].passResultCountdownTime}
                                                        segment={props.segment}
                                                        setReadyTimeHoursForTitle={props.setReadyTimeHoursForTitle}
                                                    />
                                                }
                                            />
                                        );
                                    })}
                                </RadioGroup>
                            </FormControl>
                        }

                        {/* SHOW ONLY PC Block Total Cost */}
                        <Grid container className={classes.totalCostBlockPC}>
                            <TotalCost
                                isBookFellow={isBookFellow}
                                setIsBookFellow={() => setIsBookFellow(!isBookFellow)}
                                fellowList={fellowList}
                                setFellowIdArrayToRegisterForRest={setFellowIdArrayToRegisterForRest}
                                handleCheckoutButton={handleCheckoutButton}
                                price={price}
                                serverError={serverError}
                                texeWhichTypeSelected={texeWhichTypeSelected}
                                formik={formik}
                            />
                        </Grid>

                    </Grid>


                    {/* Map */}
                    <Grid item container sm={12} md={8} className={classes.wrapRightBlock}>
                        <Grid container className={classes.wrapMap}>

                            {/* Component with map */}
                            <LabOnMap currentPosition={currentPosition} newCenterOnMap={newCenterOnMap} isAcceptGeo={isAcceptGeo} listLaboratoriesAtOrganization={listLaboratoriesAtOrganization} />

                        </Grid>
                    </Grid>


                    {/* SHOW ONLY MOBILE Block Total Cost */}
                    <Grid container className={classes.totalCostBlockMobile}>
                        <TotalCost
                            isBookFellow={isBookFellow}
                            setIsBookFellow={() => setIsBookFellow(!isBookFellow)}
                            fellowList={fellowList}
                            setFellowIdArrayToRegisterForRest={setFellowIdArrayToRegisterForRest}
                            handleCheckoutButton={handleCheckoutButton}
                            price={price}
                            serverError={serverError}
                            setServerError={setServerError}
                            texeWhichTypeSelected={texeWhichTypeSelected}
                            formik={formik}
                        />
                    </Grid>

                </Grid >
            }
        </Form>

    );

}

