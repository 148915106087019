import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../../components/layout/Layout';
import { Typography, MenuList, MenuItem, ClickAwayListener, Paper, Popper, Grow, IconButton, DialogTitle, DialogContent, Button } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AccountInfo from '../../components/profile/AccountInfo';
import { useTranslation } from 'react-i18next';
import BackButton from '../../components/layout/BackButton';
import CustomDialog from '../../components/mui-extensions/CustomDialog';
import * as userService from '../../api/user-service';
import { useSession } from '../../components/auth/SessionProvider';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: 50
    },
    pageTitle: {
        marginTop: 22,
        marginBottom: 46,
        width: "max-content"
    },
    profileMenu: {
        zIndex: 11,
    },
    deleteButton: {
        marginLeft: theme.spacing(2),
        backgroundColor: '#3AC582',
        color: theme.palette.text.secondary,
        padding: '16px 25px',
        "&:hover": {
            backgroundColor: '#3AB082',
            boxShadow: 'none',
        },
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: theme.spacing(2),
    },
}));

export default function Profile() {
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`Profile.${key}`, options);
    const [openProfileMenu, setProfileMenuOpen] = React.useState(false);
    const [openDeleteProfileDialog, setOpenDeleteProfileDialog] = React.useState(false);
    const menuBtnRef = React.useRef<HTMLButtonElement>(null);

    const { signOut } = useSession();

    const handleProfileMenuClose = () => {
        setProfileMenuOpen(false);
    };

    const handleProfileMenuToggle = () => {
        setProfileMenuOpen(!openProfileMenu);
    };

    const deleteProfile = () => {
        userService.deleteAccount()
            .then(() => signOut());
    };

    const classes = useStyles();

    return (
        <Layout>
            <div className={classes.root}>
                <BackButton />

                <Typography variant="h1" className={classes.pageTitle}>
                    {tt('titlePage')}
                    {/* Button open DropDown menu */}
                    <IconButton aria-label="delete" ref={menuBtnRef} onClick={handleProfileMenuToggle}>
                        <MoreVertIcon fontSize="large" />
                    </IconButton>
                </Typography>

                <Popper open={openProfileMenu} anchorEl={menuBtnRef.current} role={undefined} transition disablePortal className={classes.profileMenu}>

                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleProfileMenuClose}>

                                    <MenuList>
                                        <MenuItem
                                            onClick={() => setOpenDeleteProfileDialog(true)}
                                        >
                                            {tt('buttonDeleteProfile')}
                                        </MenuItem>
                                    </MenuList>

                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}

                </Popper>

                <CustomDialog
                    open={openDeleteProfileDialog}
                    onClose={() => setOpenDeleteProfileDialog(false)}
                >
                    <DialogTitle>{tt('deleteProfileDialogTitle')}</DialogTitle>
                    <DialogContent>
                        {tt('deleteProfileDialogText')}
                        <div className={classes.buttonContainer}>
                            <Button
                                variant="contained"
                                color={"primary"}
                                onClick={() => setOpenDeleteProfileDialog(false)}>
                                {tt('deleteNegativeButton')}
                            </Button>
                            <Button
                                variant="contained"
                                className={classes.deleteButton}
                                onClick={() => deleteProfile()}>
                                {tt('deletePositiveButton')}
                            </Button>
                        </div>
                    </DialogContent>
                </CustomDialog>

                <AccountInfo />
            </div>
        </Layout>
    );
}