import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../../components/layout/Layout';
import { Dialog, Box, DialogContent, Link, Backdrop, CircularProgress } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import * as userService from '../../api/user-service';
import { useHistory, useParams } from "react-router-dom";
import { ServerError } from '../../api/error-service';
import { useSnackbar } from 'notistack';
import { useSession } from '../../components/auth/SessionProvider';

const useStyles = makeStyles((theme) => ({
    root: {},
    backDrop: {
        backdropFilter: "blur(5px)",
        zIndex: theme.zIndex.modal,
    },
    dialogBackDrop: {
        backdropFilter: "blur(5px)",
    },
}));

export default function ConfirmEmail() {
    const { code } = useParams<{ code: string }>();
    const history = useHistory();
    const { t } = useTranslation();
    const tt = (key: string, options: any = undefined) => t(`ConfirmEmail.${key}`, options);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { user, refreshSession } = useSession();

    const [isLoading, setIsLoading] = React.useState(true);
    const [serverError, setServerError] = React.useState<ServerError>();

    useEffect(() => {
        userService.verifyWelcomeCode(code)
            .then(() => {
                if (!!user)
                    return refreshSession();

                return undefined;
            })
            .then(() => {
                enqueueSnackbar(t('ConfirmEmail.successfullMessage'), { variant: 'success' });
                history.push("/profile");
            })
            .catch((err: ServerError) => {
                setServerError(err);
            })
            .then(() => {
                setIsLoading(false);
            });
    }, [code]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Layout>
            <Backdrop className={classes.backDrop} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Dialog
                maxWidth="xs"
                open={!!serverError && serverError.reason === 'confirmation_code_expired'}
                BackdropProps={{
                    classes: {
                        root: classes.dialogBackDrop,
                    },
                }}
            >
                <DialogContent>
                    <Alert severity="warning">
                        <AlertTitle>{tt('codeIsExpiredTitle')}</AlertTitle>
                        {tt('codeIsExpiredMessage')}
                        <Box mt={2}>
                            <Link href="/profile" variant="body1">
                                {t('profile')}
                            </Link>
                        </Box>
                    </Alert>
                </DialogContent>
            </Dialog>

            <Dialog
                maxWidth="xs"
                open={!!serverError && (serverError.reason === 'confirmation_code_not_found' || serverError.reason === 'validation_error')}
                BackdropProps={{
                    classes: {
                        root: classes.dialogBackDrop,
                    },
                }}
            >
                <DialogContent>
                    <Alert severity="error">
                        <AlertTitle>{t('error')}</AlertTitle>
                        {tt('invalidCodeMessage')}
                    </Alert>
                </DialogContent>
            </Dialog>

        </Layout>
    );
}